import React, { useState, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import LargeButton from "../../components/LargeButton";
import DataTable from "../../components/DataTable";
import "./ProductsPage.scss";
import { Breakpoint } from "react-socks";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Product } from "../../types/Product";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { CircleLoader } from "react-spinners";
import { notify } from "common/utils/notify/notifyFunction";
import axios from "axios";
import { ContactsOutlined } from "@material-ui/icons";
import { useStoreActions, useStoreState } from "store/store";

const ProductsPage: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const userDetails = useStoreState((state) => state.account.user);

  const SetLoading = useStoreActions(
    (actions) => actions.status.setAccountPageLoading
  );

  useEffect(() => {
    SetLoading(true);
    fetchProducts();
  }, []);

  async function fetchProducts() {
    try {
      const request = await axios.get("products");

      if (request.status === 200) {
        console.log(request.data.products);
        const products = request.data.products.filter(
          (product: any) => product.isDefault == false
        );
        setProducts(products);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av produkter", true, "error");
    } finally {
      SetLoading(false);
    }
  }

  const columns = [
    {
      Header: "Produkt",
      width: 300,
      accessor: "name",
    },
    {
      Header: "Artikkelnr.",
      accessor: "articleNumber",
    },
    // {
    //   Header: "Antall",
    //   accessor: "totalSold", // String-based value accessors!
    //   width: 80,
    // },
    {
      accessor: "edit",
      width: 40,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => clickedOnChange(row.original.id)}
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
    {
      accessor: "delete",
      width: 40,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => clickedOnDelete(row.original)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  const columnsMobile = [
    {
      Header: "Produkt",
      accessor: "name",
      width: 120,
    },
    {
      Header: "Artikkelnr.",
      accessor: "articleNumber",
      width: 100,
    },
    // {
    //   Header: "Antall",
    //   accessor: "totalSold", // String-based value accessors!
    //   width: 80,
    // },
    {
      accessor: "edit",
      width: 40,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => clickedOnChange(row.original.id)}
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
    {
      accessor: "delete",
      width: 40,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => clickedOnDelete(row.original)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  function clickedOnDelete(product: Product) {
    setSelectedProduct(product);
    setOpen(true);
  }

  const updateOptions = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };

  async function updateProduct(product: any, id: string) {
    try {
      const request = await axios.patch(
        `products/${id}`,
        product,
        updateOptions
      );

      if (request.status === 200) {
        notify("Productet ble oppdatert", true, "success");
        if (request.data) {
          const changedIndex = products.findIndex((p) => p.id == id);
          products[changedIndex] = request.data;
          if (product.isDeleted === true) {
            products.splice(changedIndex, 1);
          }
          setProducts([...products]);
        }
      }
    } catch (error) {
      notify("Det oppsto en feil ved oppdatering av produkt", true, "error");
    } finally {
    }
  }

  function deleteProduct() {
    setOpen(false);
    if (selectedProduct !== undefined) {
      updateProduct({ isDeleted: true }, selectedProduct.id);
    }
  }

  function clickedOnChange(orderId: string) {
    history.push("/edit-product/" + orderId);
  }

  return (
    <div>
      <div className="product-header">
        <Route
          render={({ history }) => (
            <LargeButton
              buttonText="Nytt produkt"
              onClick={() => history.push("/new-product")}
            />
          )}
        />
        <Route
          render={({ history }) => (
            <LargeButton
              buttonText="Ny kategori"
              onClick={() => history.push("/new-product-category")}
            />
          )}
        />
      </div>
      <div
        className="d-flex grow-1 flex-wrap flex-column m-auto "
        style={{ width: 800 }}
      >
        <div className="product-table-header">
          <h1 className="product-table-header-text">PRODUKTOVERSIKT</h1>
        </div>
        <div className="product-table-container">
          <Breakpoint medium up>
            <DataTable columns={columns} data={products} />
          </Breakpoint>
          <Breakpoint medium down>
            <DataTable columns={columnsMobile} data={products} />
          </Breakpoint>
        </div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Advarsel sletter produkt"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Er du sikker på at du vil slette{" "}
              {selectedProduct ? selectedProduct.productName : ""}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Nei
            </Button>
            <Button onClick={() => deleteProduct()} color="primary" autoFocus>
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ProductsPage;
