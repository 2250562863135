import "./MobileAppMenu.scss";
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useStoreState } from "store/store";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "common/authentication/authConfig";
import HouseIcon from "@mui/icons-material/House";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ProfileSubMenu from "common/layout/MobileAppMenu/ProfileSubMenu/ProfileSubMenu";

const MobileAppMenu: React.FC = () => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [activeMenuTab, setActiveMenuTab] = useState(0);

  const userAccount = useStoreState((state) => state.account.user);
  const location = useLocation();

  function login() {
    const authProvider = new PublicClientApplication(msalConfig);
    authProvider.loginRedirect(loginRequest);
  }

  const route = location.pathname.split("/")[1];

  useEffect(() => {
    if (profileMenuOpen) {
      setActiveMenuTab(5);
      return;
    }
    switch (route) {
      case "my-page":
        setActiveMenuTab(1);
        return;
      case "new-order":
        setActiveMenuTab(2);
        return;
      case "orders":
        setActiveMenuTab(3);
        return;
      case "feedback":
        setActiveMenuTab(4);
        return;
      case "customers":
        setActiveMenuTab(5);
        return;
      case "edit-customer":
        setActiveMenuTab(5);
        return;
      case "details-customer":
        setActiveMenuTab(5);
        return;
      default:
        setActiveMenuTab(1);
        return;
    }
  }, [route, profileMenuOpen]);

  if (!userAccount?.isOnboarded) {
    return null;
  }

  return (
    <div className="mobile-nav-container ">
      <div className="navigation">
        <ul>
          <li className={activeMenuTab == 1 ? "active" : "not-active"}>
            <Link to="/my-page" onClick={() => setProfileMenuOpen(false)}>
              <span className="icon">
                <HouseIcon fontSize="inherit" />
              </span>
              <span className="text">Min side</span>
            </Link>
          </li>
          <li className={activeMenuTab == 2 ? "active" : "not-active"}>
            <Link to="/new-order" onClick={() => setProfileMenuOpen(false)}>
              <span className="icon">
                <PlaylistAddIcon fontSize="inherit" />
              </span>

              <span className="text">Nytt salg</span>
            </Link>
          </li>
          <li className={activeMenuTab == 3 ? "active" : "not-active"}>
            <Link to="/orders" onClick={() => setProfileMenuOpen(false)}>
              <span className="icon">
                <EqualizerIcon fontSize="inherit" />
              </span>
              <span className="text">Salg</span>
            </Link>
          </li>
          <li className={activeMenuTab == 4 ? "active" : "not-active"}>
            <Link to="/feedback" onClick={() => setProfileMenuOpen(false)}>
              <span className="icon">
                <ChatBubbleOutlineIcon fontSize="inherit" />
              </span>
              <span className="text">Feedback</span>
            </Link>
          </li>
          <li
            className={
              profileMenuOpen || activeMenuTab == 5 ? "active" : "not-active"
            }
            onClick={() => {
              setProfileMenuOpen(() => !profileMenuOpen);
            }}
          >
            <div className="profile-item-container">
              <span className="icon">
                <PermIdentityOutlinedIcon fontSize="inherit" />
              </span>
              <span className="text">Profil</span>
            </div>
          </li>

          <div className="indicator"></div>
        </ul>
      </div>
      <ProfileSubMenu open={profileMenuOpen} setOpen={setProfileMenuOpen} />
    </div>
  );
};
export default MobileAppMenu;

// <Button
//               text="Logg inn"
//               onClick={() => {
//                 login();
//               }}
//             ></Button>
