import axios from "axios";
import { Action, action, Thunk, thunk } from "easy-peasy";

export const account: AccountModel = {
  user: null,
  profile: null,
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setUserProfileImage: action((state, payload) => {
    if (state.user) {
      state.user.profileImage = payload;
    }
  }),
  setProfile: action((state, payload) => {
    state.profile = payload;
  }),
  getAccountThunk: thunk(async (actions, payload, { getStoreActions }) => {
    try {
      const request = await axios.get("users/me");
      if (request.status === 200) {
        //fix later
        let userData = request.data;
        if (userData.firstName) {
          userData.isOnboarded = true;
        }
        actions.setUser(userData);

        // if (payload?.withAdditionalDataLoading) {
        //   const storeActions = getStoreActions() as Actions<StoreModel>;
        //   storeActions.common.getDropdownsThunk();
        // }

        if (request.data.profileImageFilePath) {
          actions.setUserProfileImage(
            process.env.REACT_APP_BLOB_PUBLIC_URL +
              request.data.profileImageFilePath
          );
        }
      }
    } catch (e) {
      // TODO: process errors
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
};

export interface AccountModel {
  user: User | null;
  profile: Profile | null;
  setUser: Action<this, User>;
  setUserProfileImage: Action<this, string>;
  setProfile: Action<this, Profile>;
  getAccountThunk: Thunk<this, UserAccountPayload | undefined>;
}

export type User = {
  id: number;
  systemId: number;
  email: string;
  lastName: string;
  firstName: string;
  isOnboarded: boolean;
  profileImage: string;
  profileImageFilePath: string | null;
};

export type Profile = {
  dateOfBirth: Date;
  sex: string;
};

type UserAccountPayload = {
  withAdditionalDataLoading?: boolean;
};
