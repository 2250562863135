import React, { useState, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MsalProvider } from "@azure/msal-react";
import { Breakpoint, BreakpointProvider } from "react-socks";
import { BrowserRouter as Router } from "react-router-dom";
import authProvider from "common/authentication/authProvider";
import "./App.scss";

import {
  AuthenticationResult,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { useStoreActions, useStoreState } from "./store/store";
import Routes from "./Routes";
import { Spinner } from "reactstrap";
import MobileAppMenu from "common/layout/MobileAppMenu/MobileMenu";
import LoadingMain from "common/components/LoadingMain/LoadingMain";

const App: React.FC = () => {
  const isAuthenticated = useStoreState(
    (state) => state.authentication.isAuthenticated
  );
  const pageLoading = useStoreState((state) => state.status.pageLoading);
  const getAccount = useStoreActions((state) => state.account.getAccountThunk);
  const user = useStoreState((state) => state.account.user);
  const SetCacheChecked = useStoreActions(
    (actions) => actions.authentication.setCacheChecked
  );
  const SetActiveAccount = useStoreActions(
    (actions) => actions.authentication.setActiveAccount
  );

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE || "Mitt kundesystem";
    authProvider
      .handleRedirectPromise()
      .then(() => {
        const account = authProvider.getActiveAccount();
        if (!account) {
          SetCacheChecked(true);
        } else {
          SetActiveAccount(account);
        }
      })
      .catch((err) => {
        console.warn(JSON.parse(JSON.stringify(err)));
      });
  });

  useEffect(() => {
    if (isAuthenticated) {
      getAccount({ withAdditionalDataLoading: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <Router>
      <MsalProvider instance={authProvider}>
        <BreakpointProvider>
          {/* <ThemeProvider theme={theme}> */}
          <Breakpoint small down>
            <MobileAppMenu />
          </Breakpoint>
          {pageLoading && <LoadingMain></LoadingMain>}
          <Breakpoint medium up>
            <Routes />
          </Breakpoint>
          <Breakpoint small down>
            <div className="d-flex flex-row flex-grow-1 justify-content-center">
              <Routes />
            </div>
          </Breakpoint>
          <Breakpoint small down>
            <div style={{ height: 70 }}></div>
          </Breakpoint>
          <ToastContainer
            position={toast.POSITION.BOTTOM_RIGHT}
            autoClose={2000}
          />
          {/* </ThemeProvider> */}
        </BreakpointProvider>
      </MsalProvider>
    </Router>
  );
};

export default App;
