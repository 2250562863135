import axios from "axios";
import * as R from "ramda";
import { notify } from "common/utils/notify/notifyFunction";
import React, { useEffect, useState } from "react";
import InputText from "components/InputText";
import CheckBox from "components/CheckBox";
import TextArea from "common/components/TextArea/TextArea";
import MediumButtonLong from "components/MediumButtonLong";
import "./user-feedback.scss";
import { useStoreActions } from "store/store";

const UserFeedbackPage: React.FC = () => {
  const [initialDataLoading, setInitialDataLoading] = useState<boolean>(true);
  const [waitingForAPI, setWaitingForAPI] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<number>(0);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const SetLoading = useStoreActions(
    (actions) => actions.status.setAccountPageLoading
  );

  useEffect(() => {
    SetLoading(false);
  }, []);

  async function sendUserFeedback() {
    if (!waitingForAPI) {
      var newUserFeedback = {
        heading: title,
        description,
        type: selectedType,
        public: false,
      };
      try {
        setWaitingForAPI(true);
        const request = await axios.post("userFeedback", newUserFeedback);

        if (request.status === 201) {
          notify("Tusen takk for din tilbakemelding", true, "success");
          setTitle("");
          setDescription("");
        }
      } catch (error) {
        notify("Det oppsto en feil ved registreringen", true, "error");
      } finally {
        setWaitingForAPI(false);
      }
    }
  }

  return (
    <div className="user-feedback-container">
      <div className="orders-table-header text-center my-5">
        <h1 className="orders-table-header-text">Tilbakemeldinger</h1>
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex grow-1 flex-wrap flex-column content-align-center text-center">
          <h5 style={{ lineHeight: 1.5 }}>
            Er det noe du savner eller har du en idé du tror vil forbedre
            kundesystemet for deg? <br />
            Har du oppdaget en feil? Eller ønsker du bare å komme med en
            tilbakemelding? <br />
            <br />
            Vennligst velg en kategori og fyll ut skjemaet under. Vi setter stor
            pris på alle tilbakemeldinger vi mottar, slik at vi sammen kan lage
            en best mulig versjon av kundesystemet og en optimal
            brukeropplevelse for deg!
          </h5>
        </div>
        <div>
          <div className="d-flex flex-row justify-content-center">
            <div className="d-flex flex-column mt-4" style={{ maxWidth: 220 }}>
              <div className="d-flex flex-row justify-content-start ">
                <CheckBox
                  checked={selectedType === 0}
                  onChange={(e: any) => setSelectedType(0)}
                />
                <p className="ms-1 my-auto">Generell tilbakemelding</p>
              </div>
              <div className="d-flex flex-row justify-content-start">
                <CheckBox
                  checked={selectedType === 1}
                  onChange={(e: any) => setSelectedType(1)}
                />
                <p className="ms-1 my-auto">Noe jeg savner</p>
              </div>
              <div className="d-flex flex-row justify-content-start">
                <CheckBox
                  checked={selectedType === 2}
                  onChange={(e: any) => setSelectedType(2)}
                />
                <p className="ms-1 my-auto">Ide til en forbedring</p>
              </div>
              <div className="d-flex flex-row justify-content-start">
                <CheckBox
                  checked={selectedType === 3}
                  onChange={(e: any) => setSelectedType(3)}
                />
                <p className="ms-1 my-auto">Funnet en feil/bug</p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-grow-1 justify-content-center">
            <InputText
              inputTitle="Tittel"
              value={title}
              type="title"
              onChange={(e: any) => setTitle(e.target.value)}
              width={300}
            />
          </div>
          <TextArea
            inputTitle="Beskrivelse"
            value={description}
            type="description"
            onChange={(e: any) => setDescription(e.target.value)}
            width={300}
          />
          <div className="d-flex justify-content-center my-5">
            <MediumButtonLong
              buttonText="Send inn"
              onClick={() => sendUserFeedback()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserFeedbackPage;
