import { InteractionRequiredAuthError } from "@azure/msal-browser";
import Axios, { AxiosRequestConfig } from "axios";
import * as R from "ramda";

import { loginRequest } from "common/authentication/authConfig";
import { handleLogout } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";

import authProvider from "./authProvider";

let isModalBlocked = false;

Axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

Axios.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    try {
      if (!R.isNil(authProvider.getActiveAccount())) {
        const { accessToken } = await authProvider.acquireTokenSilent(
          loginRequest
        );
        config.headers["Authorization"] = "Bearer " + accessToken;
      } else {
      }

      return config;
    } catch (err) {
      console.log("error");
      console.log(err);
      if (
        (err instanceof InteractionRequiredAuthError && !isModalBlocked) as any
      ) {
        console.log("trying inside IRAE");

        try {
          await authProvider.acquireTokenRedirect(loginRequest);

          // if (accessToken) {
          //   console.log("accessToken");
          //   console.log(accessToken);
          //   config.headers["Authorization"] = `Bearer ${accessToken}`;

          //   isModalBlocked = false;

          //   return config;
          // }
        } catch (e: any) {
          console.log("inside error");
          console.log(e);
          notify("Det oppsto en feil prøv å logge ut og inn", false, "error");
          if (e.errorMessage === "User cancelled the flow.") {
            handleLogout(authProvider);
          }
        }
      }

      return config;
    }
  }
);

Axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (isModalBlocked) {
      return Promise.reject(err);
    }
    notify("Something went wrong", true, "error");
    return Promise.reject(err);
  }
);
