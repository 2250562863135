import React from "react";
import "./Burger.scss";

type BurgerProps = {
  open: boolean;
  setOpen: (event: any) => void;
};

const Burger: React.FC<BurgerProps> = ({ open, setOpen }) => {
  return (
    <div
      className={`${open ? "burger-open" : "burger-closed"}`}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div />
      <div />
      <div />
    </div>
  );
};

export default Burger;
