import React, { useMemo } from "react";
import Select from "react-select";
import "./style.scss";

type DropdownProps = {
  items: DropdownItems[];
  placeholder: string;
  onChange: (event: any) => void;
  value: any;
};
type DropdownItems = {
  label: string;
  value: number;
  id: string;
};

const Dropdown: React.FC<DropdownProps> = ({
  items,
  placeholder,
  onChange,
  value,
}) => {
  const customStyles = useMemo(
    () => ({
      container: (provided: any) => ({
        ...provided,
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        background: state.isFocused
          ? "#F0C0E8"
          : state.isSelected
          ? "#906088"
          : "white",

        color: "black",
        opacity: 0.8,
        padding: 20,
      }),
    }),
    []
  );

  return (
    <div className="dropdown-container">
      <Select
        value={value}
        escapeClearsValue={false}
        options={items}
        onChange={onChange}
        placeholder={placeholder}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,

            primary25: "#deb6b7",
            primary75: "#deb6b7",
            primary50: "#deb6b7",
            primary: "#906088",
          },
        })}
      />
    </div>
  );
};

export default Dropdown;
