import React from "react";
import "./RemindersOverview.scss";
import { Breakpoint } from "react-socks";
import { ReminderDetailed } from "types/ReminderDetailed";
import ReminderMobile from "pages/my-page/components/reminders/ReminderMobile";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MediumButtonLong from "components/MediumButtonLong";

type RemindersProps = {
  reminders: ReminderDetailed[];
  onCreate?: any;
  onDelay?: any;
  onDelete?: any;
};

const RemindersOverview: React.FC<RemindersProps> = ({
  reminders,
  onCreate,
  onDelay,
  onDelete,
}) => {
  if (reminders.length === 0) {
    return (
      <div className="d-flex flex-column justify-content-center mt-5 text-center align-items-center">
        <h4>Opprett din første påminnelse</h4>
        <MediumButtonLong
          buttonText="Ny påminnelse"
          onClick={() => onCreate()}
        />
      </div>
    );
  }

  return (
    <div className="d-flex flex-row flex-grow-1 justify-content-center">
      <Breakpoint medium up>
        {reminders.map((reminder: ReminderDetailed, index) => {
          return (
            <div key={"reminder-div" + index}>
              {reminder.customer.firstName}
            </div>
          );
        })}
      </Breakpoint>
      <Breakpoint small down>
        <div className="d-flex flex-row justify-content-center w-100 mb-2 mt-4">
          <h2 className="my-auto me-1">Påminnelser</h2>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 3001 }}>
                Opprett ny påminnelse
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
                fontSize: 30,
              }}
              size="small"
              onClick={onCreate}
            >
              <AddIcon fontSize="inherit" />
            </IconButton>
          </OverlayTrigger>
        </div>
        {reminders.map((reminder: ReminderDetailed, index) => {
          return (
            <div key={"reminder-mobile-div" + index}>
              <ReminderMobile
                reminder={reminder}
                onDelay={onDelay}
                onDelete={onDelete}
              />
            </div>
          );
        })}
      </Breakpoint>
    </div>
  );
};

export default RemindersOverview;
