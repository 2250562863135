import React from "react";
import Button from "@material-ui/core/Button";
import "./style.scss";

type ButtonProps = {
  buttonText: string;
  onClick: (event: any) => void;
};

const LargeButton: React.FC<ButtonProps> = ({ buttonText, onClick }) => {
  return (
    <div className="large-button-cointainer">
      <Button
        classes={{ label: "large-button-label", root: "large-botton-root" }}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default LargeButton;
