import React from "react";
import { Container } from "react-bootstrap";

const LoadingPage = () => {
  return (
    <Container fluid>
      {/* <Spinner
        role="status"
        animation="border"
        className="position-absolute top-50 start-50"
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner> */}
    </Container>
  );
};

export default LoadingPage;
