import React, { useState, useEffect } from "react";
import * as R from "ramda";
import "./CustomerOrdersOverview.scss";
import { Breakpoint } from "react-socks";
import { Order } from "types/Order";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import OrdersMobile from "pages/my-page/components/orders/OrdersMobile";
import { useHistory } from "react-router-dom";
import { OrderCustomerDetails } from "types/OrderCustomerDetails";
import CustomerOrdersMobile from "pages/customer-details/orders/CustomerOrdersMobile";

type OrdersProps = {
  orders: OrderCustomerDetails[];
  clickedOnPaid?: any;
  clickedOnDelivered?: any;
  clickedOnOrdered?: any;
  onOpenOrder?: any;
  onOpenReminder?: any;
  onEdit?: any;
  onDelete?: any;
  customerId: string;
};

const CustomerOrdersOverview: React.FC<OrdersProps> = ({
  orders,
  clickedOnPaid,
  clickedOnDelivered,
  clickedOnOrdered,
  onOpenOrder,
  onOpenReminder,
  onEdit,
  onDelete,
  customerId,
}) => {
  const history = useHistory();
  return (
    <div className="d-flex flex-row flex-grow-1">
      <Breakpoint medium up>
        {orders.map((order: OrderCustomerDetails) => {
          return <div>{order.totalPrice}</div>;
        })}
      </Breakpoint>
      <Breakpoint small down>
        <div className="d-flex flex-row justify-content-center w-100 mb-2 mt-4">
          <h2 className="my-auto me-1">Nyeste salg</h2>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 3001 }}>
                Opprett nytt salg
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
                fontSize: 20,
              }}
              size="small"
              onClick={() => history.push("/new-order/" + customerId)}
            >
              <AddIcon fontSize="medium" />
            </IconButton>
          </OverlayTrigger>
        </div>
        {orders.map((order: OrderCustomerDetails, index) => {
          return (
            <div>
              <CustomerOrdersMobile
                key={"order-mobile" + index}
                order={order}
                clickedOnPaid={clickedOnPaid}
                clickedOnDelivered={clickedOnDelivered}
                clickedOnOrdered={clickedOnOrdered}
                onOpenOrder={onOpenOrder}
                onOpenReminder={onOpenReminder}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </div>
          );
        })}
      </Breakpoint>
    </div>
  );
};

export default CustomerOrdersOverview;
