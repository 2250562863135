import React from "react";
import { OrderPost } from "../../types/OrderPost";
import "./OrderModal.scss";
import "./print.scss";
import { Dialog } from "@material-ui/core";
import { Breakpoint } from "react-socks";
import DataTable from "../../components/DataTable";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/Print";
import { Order } from "types/Order";
import TableCellText from "common/components/TableCellText/TableCellText";

type ModalProps = {
  open: boolean;
  id: string;
  onClose: any;
  onEdit: any;
  order: Order | undefined;
};

const OrderModal: React.FC<ModalProps> = ({
  open,
  id,
  onClose,
  onEdit,
  order,
}) => {
  const orders = order?.orderPosts;
  const totalPrice = order?.totalPrice;
  const notes = order?.notes;

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      width: "80%",
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const [modalStyle] = React.useState(getModalStyle);

  const startPrint = () => {
    window.print();
  };

  const columns = [
    {
      Header: "Produkt",
      accessor: "product.productName",
      width: 200,
      Cell: ({ row }: any) => {
        var text = row.original.product.productName;
        return <TableCellText text={text} width={200} />;
      },
    },
    // {
    //   Header: "Produkt",
    //   accessor: "product.productName",
    //   width: 200,
    // },
    {
      Header: "Type",
      accessor: "subProduct.subProductName",
      width: 120,
    },
    {
      Header: "Art.nr",
      accessor: "articleNumber",
      width: 120,
      Cell: ({ row }: any) => {
        return (
          <p style={{ margin: 0 }}>
            {row.original.subProduct
              ? row.original.subProduct.articleNumber
              : row.original.product.articleNumber}
          </p>
        );
      },
    },
    {
      Header: "Antall",
      accessor: "count",
      width: 80,
    },
    {
      Header: "Pris",
      accessor: "price", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return <p style={{ margin: 0 }}>{row.original.price} kr</p>;
      },
    },
    {
      Header: "Total",
      accessor: "total", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <p style={{ margin: 0 }}>
            {row.original.price * row.original.count} kr
          </p>
        );
      },
    },
  ];

  const columnsMobile = [
    {
      Header: "Produkt",
      accessor: "product.productName",
      Cell: ({ row }: any) => {
        var text =
          row.original.product.productName +
          (row.original.subProductName
            ? `, ${row.original.subProductName}`
            : "");
        return <TableCellText text={text} width={150} />;
      },
    },
    // {
    //   Header: "Produkt",
    //   accessor: "product.productName",
    //   width: 120,
    // },
    {
      Header: "Antall",
      accessor: "count",
      width: 55,
    },
    {
      Header: "Pris",
      accessor: "price", // String-based value accessors!
      width: 55,
    },
    {
      Header: "Total",
      accessor: "total", // String-based value accessors!
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <p style={{ margin: 0 }}>
            {row.original.price * row.original.count} kr
          </p>
        );
      },
    },
  ];

  if (!orders) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"} className="dialog">
      <div className="modal">
        <div className="modal-background">
          <div className="modal-top">
            <h2 id="simple-modal-title" className="modal-top-title">
              Produktliste:
            </h2>

            <div className="button-row">
              <IconButton
                style={{
                  color: "#906088",
                }}
                onClick={() => onEdit(id)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                style={{
                  color: "#906088",
                }}
                onClick={() => startPrint()}
              >
                <PrintIcon />
              </IconButton>

              <h2 className="modal-top-close" onClick={onClose}>
                X
              </h2>
            </div>
          </div>
          <div className="info-container">
            <div className="table-container">
              <Breakpoint medium up>
                <DataTable columns={columns} data={orders} />
              </Breakpoint>
              <Breakpoint small down>
                <DataTable columns={columnsMobile} data={orders} />
              </Breakpoint>
            </div>
            <div className="total-price-container">
              <p>
                <strong>Totalt</strong> {totalPrice} kr
              </p>
            </div>
            {notes && (
              <div className="note-container">
                <p>
                  <strong>Notat</strong>
                </p>
                <p className="text-align-left">{notes}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default OrderModal;
