import React, { useState, useEffect } from "react";
import * as R from "ramda";
import "./ReminderMobile.scss";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import DeleteIcon from "@material-ui/icons/Delete";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InfoIcon from "@material-ui/icons/Info";
import { ReminderDetailed } from "types/ReminderDetailed";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import TableCellText from "common/components/TableCellText/TableCellText";
type ReminderMobileProps = {
  reminder: ReminderDetailed;
  onDelete?: any;
  onDelay?: any;
};

const ReminderMobile: React.FC<ReminderMobileProps> = ({
  reminder,
  onDelete,
  onDelay,
}) => {
  function currentIcon() {
    if (reminder.daysToAlert) {
      if (reminder.daysToAlert < 0) {
        return <NotificationsNoneIcon fontSize="inherit" />;
      } else if (reminder.daysToAlert < 14) {
        return <HourglassBottomIcon fontSize="inherit" />;
      } else {
        return <CalendarMonthIcon fontSize="inherit" />;
      }
    }
  }

  const productName = () => {
    var text =
      reminder.product.productName +
      (reminder.subProduct ? `, ${reminder.subProduct.subProductName}` : "");
    return <TableCellText text={text} width={150} threeRows={true} />;
  };

  function daysInThisMonth() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
  }

  const timeUntilAlert = (): string => {
    if (R.isNil(reminder.daysToAlert) || reminder.daysToAlert <= 0) {
      return `Nå`;
    }
    const daysInMonth = daysInThisMonth();
    const months = (reminder.daysToAlert / daysInMonth) | 0;
    const days = reminder.daysToAlert - months * daysInMonth;
    if (months > 0) {
      if (months == 1) {
        if (days == 1) {
          return `en måned og en dag`;
        } else {
          return `en måned og ${days} dager`;
        }
      } else {
        if (days == 0) {
          return `${months} måneder`;
        } else if (days == 1) {
          return `${months} måneder og en dag`;
        } else {
          return `${months} måneder og ${days} dager`;
        }
      }
    } else {
      if (days == 1) {
        return `en dag`;
      } else {
        return `${days} dager`;
      }
    }
  };

  return (
    <div className="reminder-mobile-row">
      <div className="reminder-mobile-section flex-grow-1">
        <div className="reminder-mobile-section-icon">{currentIcon()}</div>
        <div
          className="reminder-mobile-section-content flex-grow-1 justify-content-start"
          style={{ width: 160 }}
        >
          <div className="d-flex flex-column px-2">
            <div className="mb-2 fs-3">
              <strong>
                {reminder.customer.firstName} {reminder.customer.lastName}
              </strong>{" "}
            </div>
            {productName()}
          </div>
        </div>
        <div className="reminder-mobile-section-content" style={{ width: 120 }}>
          <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-row flex-grow-1 mb-2">
              <div className="me-2">
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                      Se info
                    </Tooltip>
                  }
                >
                  <IconButton
                    style={{
                      color: "#906088",
                    }}
                    onClick={() => onDelay(reminder)}
                  >
                    <InfoIcon />
                  </IconButton>
                </OverlayTrigger>
              </div>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                    Slett
                  </Tooltip>
                }
              >
                <IconButton
                  style={{
                    color: "#906088",
                  }}
                  onClick={() => onDelete(reminder)}
                >
                  <DeleteIcon />
                </IconButton>
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-row flex-grow-1 justify-content-center pe-3">
              {timeUntilAlert()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReminderMobile;
