import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import axios from "axios";
import { notify } from "common/utils/notify/notifyFunction";
import React, { useEffect, useState } from "react";
import { Order } from "types/Order";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import InfoIcon from "@material-ui/icons/Info";
import DeleteIcon from "@material-ui/icons/Delete";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import CheckBox from "components/CheckBox";
import ReminderModal from "common/components/ReminderModal/ReminderModal";
import OrderModal from "pages/orders/OrderModal";
import { useHistory } from "react-router-dom";
import { Breakpoint } from "react-socks";
import DataTable from "components/DataTable";
import MediumButtonLong from "components/MediumButtonLong";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ViewReminderModal from "common/components/ViewReminderModal/ViewReminderModal";
import { ReminderDetailed } from "types/ReminderDetailed";
import TableCellText from "common/components/TableCellText/TableCellText";
import RemindersOverview from "pages/my-page/components/reminders/RemindersOverview";
import OrdersOverview from "pages/my-page/components/orders/OrdersOverview";
import { useStoreActions, useStoreState } from "store/store";
import "./MyPage.scss";

const MyPage: React.FC = () => {
  const [finnishedLoading, setFinnishedLoading] = React.useState(false);
  const [initialDataLoading, setInitialDataLoading] = useState<boolean>(true);
  const [orders, setOrders] = useState<Order[]>([]);
  const [reminders, setReminders] = useState<ReminderDetailed[]>([]);
  const [orderModalOpen, setOrderModalOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [selectedReminder, setSelectedReminder] = useState<any>();
  const [selectedDetailedReminder, setSelectedDetailedReminder] =
    useState<ReminderDetailed>();
  const [selectedOrderId, setSelectedOrderId] = React.useState("");
  const [selectedReminderId, setSelectedReminderId] = React.useState<number>();
  const [reminderLoadData, setReminderLoadData] = useState<string>("none");
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [openAlertReminderDialog, setOpenAlertReminderDialog] = useState(false);
  const [reminderModalOpen, setReminderModalOpen] = React.useState(false);
  const [viewReminderModalOpen, setViewReminderModalOpen] =
    React.useState(false);
  const [rosaMenCount, setRosaMenCount] = useState<number>(0);
  const [rosaWomenCount, setRosaWomenCount] = useState<number>(0);
  const [rosaTotalCount, setRosaTotalCount] = useState<number>(0);
  const history = useHistory();

  const SetLoading = useStoreActions(
    (actions) => actions.status.setAccountPageLoading
  );

  const system = useStoreState((state) => state.account?.user?.systemId);

  useEffect(() => {
    SetLoading(true);
    fetchOrders();
  }, []);

  useEffect(() => {
    if (system == 1) {
      fetchRosaSloyfe();
    }
  }, [system]);

  async function fetchOrders() {
    setInitialDataLoading(true);
    try {
      const request = await axios.get("orders");

      if (request.status === 200) {
        setOrders(request.data);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av ordre", true, "error");
    } finally {
      setInitialDataLoading(false);
      fetchReminders();
    }
  }
  async function fetchReminders() {
    setInitialDataLoading(true);
    try {
      const request = await axios.get("productNotifications");

      if (request.status === 200) {
        setReminders(request.data);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting", true, "error");
    } finally {
      setInitialDataLoading(false);
      SetLoading(false);
      setFinnishedLoading(true);
    }
  }

  async function fetchRosaSloyfe() {
    try {
      const request = await axios.get("products/rosa");

      if (request.status === 200) {
        if (request.status === 200) {
          console.log(request.data);
          setRosaMenCount(request.data.countMen);
          setRosaWomenCount(request.data.countWomen);
          setRosaTotalCount(request.data.countTotal);
        }
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting", true, "error");
    } finally {
    }
  }

  const reminderColumns = [
    {
      Header: "Kunde",
      accessor: "customer", // String-based value accessors!
      Cell: ({ row }: any) => {
        return (
          <p>
            {row.original.customer.firstName} {row.original.customer.lastName}
          </p>
        );
      },
    },
    {
      Header: "Produkt",
      accessor: "product", // String-based value accessors!
      Cell: ({ row }: any) => {
        var text =
          row.original.product.productName +
          (row.original.subProduct
            ? `, ${row.original.subProduct.subProductName}`
            : "");
        return <TableCellText text={text} width={200} />;
      },
    },
    {
      Header: "Dager",
      accessor: "daysToAlert",
      width: 60,
      Cell: ({ row }: any) => {
        return (
          <div className="text-center">
            <b>
              {row.original.daysToAlert < 0 && (
                <p style={{ color: "red" }}>{row.original.daysToAlert}</p>
              )}
              {row.original.daysToAlert < 7 &&
                row.original.daysToAlert >= 0 && (
                  <p style={{ color: "orange" }}>{row.original.daysToAlert}</p>
                )}
              {row.original.daysToAlert >= 7 && (
                <p>{row.original.daysToAlert}</p>
              )}
            </b>
          </div>
        );
      },
    },
    {
      accessor: "info",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Se info
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              aria-label="See info"
              onClick={() => clickedOnOpenReminder(row.original)}
            >
              <InfoIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "delete",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Slett
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              aria-label="Delete reminder"
              onClick={() => clickedOnDeleteReminder(row.original)}
            >
              <DeleteIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
  ];

  const updateOptions = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };

  async function updateOrder(order: any, id: string) {
    try {
      const request = await axios.patch(`orders/${id}`, order, updateOptions);

      if (request.status === 200) {
        const changedIndex = orders.findIndex((order) => order.id == id);
        orders[changedIndex] = request.data;
        if (order.isDeleted === true) {
          orders.splice(changedIndex, 1);
        }
        setOrders([...orders]);
      }
    } catch (error) {
      notify("Det oppsto en feil ved oppdatering av ordre", true, "error");
    } finally {
    }
  }

  async function updateReminder(reminder: any, id: string) {
    try {
      const request = await axios.patch(
        `productNotifications/${id}`,
        reminder,
        updateOptions
      );

      if (request.status === 200) {
      }
    } catch (error) {
      notify("Det oppsto en feil ved oppdatering av påminnelse", true, "error");
    } finally {
    }
  }

  function clickedOnPaid(orderId: string, checked: boolean) {
    updateOrder({ paid: checked }, orderId);
  }

  function clickedOnDelete(order: Order) {
    setSelectedOrder(order);
    setOpenAlertDialog(true);
  }

  function clickedOnDeleteReminder(reminder: any) {
    setSelectedReminder(reminder);
    setOpenAlertReminderDialog(true);
  }

  function deleteOrder() {
    setOpenAlertDialog(false);
    if (selectedOrder !== undefined) {
      updateOrder({ isDeleted: true }, selectedOrder.id);
    }
  }

  async function deleteReminder() {
    setOpenAlertReminderDialog(false);
    if (selectedReminder !== undefined) {
      try {
        const request = await axios.delete(
          `productNotifications/${selectedReminder.id}`
        );

        if (request.status === 200 || request.status === 204) {
          notify("En påminnelse ble slettet", true, "success");
          let newReminderList = reminders.filter(
            (p) => p.id != selectedReminder.id
          );
          setReminders(newReminderList);
        }
      } catch (error) {
        notify("Det oppsto en feil ved sletting av påminnelse", true, "error");
      } finally {
      }
    }
  }

  function openCreateReminder() {
    setReminderLoadData("all");
    setSelectedOrder(undefined);
    setReminderModalOpen(true);
  }

  function clickedOnOpenOrder(order: Order) {
    setSelectedOrderId(order.id);
    fetchOrder(order.id);
    setOrderModalOpen(true);
  }

  function clickedOnOpenReminder(reminder: any) {
    setSelectedReminderId(reminder.id);
    fetchReminder(reminder.id);
    setViewReminderModalOpen(true);
  }

  async function fetchOrder(id: string) {
    setInitialDataLoading(true);
    try {
      const request = await axios.get("orders/" + id);

      if (request.status === 200) {
        setSelectedOrder(request.data);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av ordren", true, "error");
    } finally {
      setInitialDataLoading(false);
    }
  }

  async function fetchReminder(id: number) {
    setInitialDataLoading(true);
    try {
      const request = await axios.get("productNotifications/" + id);

      if (request.status === 200) {
        setSelectedDetailedReminder(request.data);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av ordren", true, "error");
    } finally {
      setInitialDataLoading(false);
    }
  }

  const closeReminderModal = () => {
    setReminderModalOpen(false);
  };
  const closeViewReminderModal = () => {
    setViewReminderModalOpen(false);
  };

  function clickedOnDelivered(orderId: any, checked: boolean) {
    updateOrder({ delivered: checked }, orderId);
  }

  function clickedOnParitialyDelivered(orderId: any, checked: boolean) {
    updateOrder({ partialyDelivered: checked }, orderId);
  }

  function clickedOnOrdered(orderId: string, checked: boolean) {
    updateOrder({ orderedFromSupplier: checked }, orderId);
  }

  function clickedOnEditReminderModal(reminderId: number) {
    setSelectedReminderId(reminderId);
    fetchReminder(reminderId);
    setReminderModalOpen(true);
  }

  function clickedOnOpenReminderModal(order: Order) {
    setSelectedOrderId(order.id);
    fetchOrder(order.id);
    setReminderModalOpen(true);
  }

  function clickedOnChange(orderId: string) {
    history.push("/edit-order/" + orderId);
  }

  const orderColumns = [
    {
      Header: "Dato",
      accessor: "createdDate", // String-based value accessors!
      Cell: ({ row }: any) => {
        let date = new Date(row.original.createdDate);
        return (
          <p onClick={(e: any) => clickedOnOpenOrder(row.original)}>
            {date.getDate()}/{date.getMonth() + 1} {date.getFullYear()}
          </p>
        );
      },
    },
    {
      Header: "Kunde",
      accessor: "customer", // String-based value accessors!
      Cell: ({ row }: any) => {
        return (
          <p>
            {row.original.customer.firstName} {row.original.customer.lastName}
          </p>
        );
      },
    },
    {
      Header: "Bestilt",
      accessor: "orderedFromSupplier", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.orderedFromSupplier}
            onChange={(e: any) => {
              clickedOnOrdered(row.original.id, e.target.checked);
              row.original.orderedFromSupplier = e.target.checked;
            }}
          />
        );
      },
    },
    {
      Header: "Betalt",
      accessor: "paid", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.paid}
            onChange={(e: any) => {
              clickedOnPaid(row.original.id, e.target.checked);
              row.original.paid = e.target.checked;
            }}
          />
        );
      },
    },
    {
      Header: "Levert",
      accessor: "delivered", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.delivered}
            onChange={(e: any) => {
              clickedOnDelivered(row.original.id, e.target.checked);
              // console.log(e.target.checked);
              // row.original.delivered = e.target.checked;
              // console.log(row);
            }}
          />
        );
      },
    },
    {
      Header: "Delvis Levert",
      accessor: "partialyDelivered", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 3001 }}>
                Trykk på rediger for å velge hva som er levert
              </Tooltip>
            }
          >
            <div>
              <CheckBox
                checked={row.original.partialyDelivered}
                onChange={(e: any) => {
                  clickedOnParitialyDelivered(
                    row.original.id,
                    e.target.checked
                  );
                  row.original.delivered = e.target.checked;
                }}
              />
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "notes",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Bestillingsliste
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              aria-label="Order list"
              onClick={() => clickedOnOpenOrder(row.original)}
            >
              <InfoIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "reminder",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Opprett påminnelse
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              aria-label="create reminder"
              onClick={() => clickedOnOpenReminderModal(row.original)}
            >
              <NotificationsNoneIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "count",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Rediger salg
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              aria-label="Edit sale"
              onClick={() => clickedOnChange(row.original.id)}
            >
              <EditIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "system",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Slett
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              aria-label="Delete sale"
              onClick={() => clickedOnDelete(row.original)}
            >
              <DeleteIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
  ];

  if (!finnishedLoading) {
    return null;
  }

  return (
    <div className="d-flex flex-column">
      <Breakpoint medium up>
        <div className="orders-table-header text-center my-5">
          <h1 className="orders-table-header-text">Min side</h1>
        </div>
      </Breakpoint>
      {orders?.length === 0 && reminders?.length === 0 && (
        <div className="d-flex  content-align-center mt-2">
          <h2 className="flex-grow-1 text-center">
            Velkommen til Mitt kundesystem.{" "}
          </h2>
        </div>
      )}
      {rosaTotalCount > 0 && (
        <div className="d-flex flex-row flex-grow-1 justify-content-center">
          <div className="rosa-sloyfe-container">
            <h1 className="rosa-sloyfe-header-text">Rosa sløyfe 2024</h1>
            <p className="rosa-sloyfe-paragraph-text">
              Damepakker solgt: {rosaWomenCount}
            </p>
            <p className="rosa-sloyfe-paragraph-text">
              Herrepakker solgt: {rosaMenCount}
            </p>
            <p className="rosa-sloyfe-paragraph-text">
              Totalt: {rosaTotalCount}
            </p>
          </div>
        </div>
      )}

      <Breakpoint small down>
        {orders.length > 0 || reminders.length > 0 ? (
          <RemindersOverview
            reminders={reminders}
            onCreate={openCreateReminder}
            onDelay={clickedOnOpenReminder}
            onDelete={clickedOnDeleteReminder}
          />
        ) : null}
      </Breakpoint>
      <Breakpoint medium up>
        {reminders?.length > 0 ? (
          <div className="d-flex grow-1 flex-wrap flex-column m-auto mt-5 ">
            <div className="d-flex flex-row justify-content-center w-100">
              <h4 className="my-auto me-1">Påminnelser</h4>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id="customer-tooltip" style={{ zIndex: 3001 }}>
                    Opprett ny påminnelse
                  </Tooltip>
                }
              >
                <IconButton
                  style={{
                    color: "#906088",
                    fontSize: 20,
                  }}
                  size="small"
                  aria-label="Create a new reminder"
                  onClick={() => {
                    setReminderLoadData("all");
                    setSelectedOrder(undefined);
                    setReminderModalOpen(true);
                  }}
                >
                  <AddIcon fontSize="medium" />
                </IconButton>
              </OverlayTrigger>
            </div>
            <Breakpoint medium up>
              <DataTable columns={reminderColumns} data={reminders} />
            </Breakpoint>
          </div>
        ) : (
          orders?.length > 0 && (
            <div className="d-flex flex-column justify-content-center mt-5 text-center align-items-center">
              <h4>Opprett din første påminnelse</h4>
              <MediumButtonLong
                buttonText="Ny påminnelse"
                onClick={() => {
                  setReminderLoadData("all");
                  setReminderModalOpen(true);
                }}
              />
            </div>
          )
        )}
      </Breakpoint>

      <Breakpoint small down>
        <OrdersOverview
          orders={orders}
          clickedOnPaid={clickedOnPaid}
          clickedOnDelivered={clickedOnDelivered}
          clickedOnOrdered={clickedOnOrdered}
          onOpenOrder={clickedOnOpenOrder}
          onOpenReminder={clickedOnOpenReminderModal}
          onEdit={clickedOnChange}
          onDelete={clickedOnDelete}
        />
      </Breakpoint>
      <Breakpoint medium up>
        {orders?.length > 0 ? (
          <div
            className="d-flex grow-1 flex-wrap flex-column m-auto mt-5 "
            style={{ maxWidth: 800 }}
          >
            {" "}
            <div className="d-flex flex-row content-align-center">
              <h4 className="my-auto me-1">Nyeste salg</h4>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id="customer-tooltip" style={{ zIndex: 3001 }}>
                    Opprett nytt salg
                  </Tooltip>
                }
              >
                <IconButton
                  style={{
                    color: "#906088",
                    fontSize: 20,
                  }}
                  size="small"
                  aria-label="Create a new sale"
                  onClick={() => history.push("/new-order")}
                >
                  <AddIcon fontSize="medium" />
                </IconButton>
              </OverlayTrigger>
            </div>
            <Breakpoint medium up>
              <DataTable columns={orderColumns} data={orders} />
            </Breakpoint>
          </div>
        ) : (
          <div className="d-flex flex-column justify-content-center mt-5 text-center align-items-center">
            <h4>Opprett ditt første salg</h4>
            <MediumButtonLong
              buttonText="Nytt salg"
              onClick={() => history.push("/new-order")}
            />
          </div>
        )}
      </Breakpoint>

      <OrderModal
        id={selectedOrderId}
        open={orderModalOpen}
        onClose={() => {
          setOrderModalOpen(false);
          fetchReminders();
        }}
        order={selectedOrder}
        onEdit={clickedOnChange}
      />
      <ReminderModal
        id={selectedOrderId}
        open={reminderModalOpen}
        onClose={closeReminderModal}
        preSelectedOrder={selectedOrder}
        preSelectedReminder={selectedDetailedReminder}
        onCreate={() => fetchReminders()}
        loadData={reminderLoadData}
      />
      <ViewReminderModal
        onClose={() => {
          fetchReminders();
          setViewReminderModalOpen(false);
        }}
        open={viewReminderModalOpen}
        detailedReminder={selectedDetailedReminder}
      />
      <Dialog
        open={openAlertDialog}
        onClose={() => setOpenAlertDialog(false)}
        aria-labellledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Advarsel sletter salg"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Er du sikker på at du vil slette dette salget fra{" "}
            {selectedOrder
              ? selectedOrder.customer.firstName +
                " " +
                selectedOrder.customer.lastName
              : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlertDialog(false)} color="primary">
            Nei
          </Button>
          <Button onClick={() => deleteOrder()} color="primary" autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAlertReminderDialog}
        onClose={() => setOpenAlertReminderDialog(false)}
        aria-labellledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Advarsel sletter påminnelse"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Er du sikker på at du vil slette denne påminnelsen av{" "}
            {selectedReminder
              ? selectedReminder.product.productName +
                " til " +
                selectedReminder.customer.firstName +
                " " +
                selectedReminder.customer.lastName
              : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenAlertReminderDialog(false)}
            color="primary"
          >
            Nei
          </Button>
          <Button onClick={() => deleteReminder()} color="primary" autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyPage;
