import * as React from "react";
import { TextField } from "@material-ui/core";
import { Breakpoint } from "react-socks";

type InputTextProps = {
  inputTitle: string;
  value: any;
  type?: string;
  onChange: any;
  width?: number;
};

const TextArea: React.StatelessComponent<InputTextProps> = ({
  inputTitle,
  value,
  onChange,
  type = "search",
  width = 195,
}) => {
  return (
    <div>
      <Breakpoint medium up>
        <TextField
          id="standard-textarea"
          value={value}
          label={inputTitle}
          type={type}
          margin="normal"
          onChange={onChange}
          multiline
          inputProps={{
            style: { fontSize: 16, width },
          }}
          InputLabelProps={{ style: { fontSize: 16 } }}
        />
      </Breakpoint>
      <Breakpoint small down>
        <TextField
          id="standard-textarea"
          value={value}
          label={inputTitle}
          type={type}
          margin="normal"
          onChange={onChange}
          multiline
          inputProps={{
            style: { fontSize: 14, width: 300 },
          }}
          InputLabelProps={{ style: { fontSize: 14 } }}
        />
      </Breakpoint>
    </div>
  );
};

export default TextArea;
