import React, { FC } from "react";
import {
  useTable,
  useFilters,
  useSortBy,
  Column,
  TableInstance,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import "./style.scss";

type Data = object;

type Props = {
  columns: Column<Data>[];
  data: Data[];
};

const defaultColumn = {
  minWidth: 20,
  width: 175,
  maxWidth: 500,
};

const DataTable: FC<Props> = ({ columns, data }) => {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<Data>(
      {
        columns,
        defaultColumn,
        data,
      },
      useFilters,
      useSortBy,
      useBlockLayout,
      useResizeColumns
    ) as TableInstance<object>;
  // Render the UI for your table
  return (
    <table
      {...getTableProps()}
      className="d-flex flex-column m-0 align-content-center"
    >
      <thead className="d-flex flex-column align-self-center">
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index2) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody
        {...getTableBodyProps()}
        className="d-flex flex-column align-self-center"
      >
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, index3) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DataTable;
