import React, { useEffect, useState } from "react";
import LargeButton from "../../components/LargeButton";
import { Route } from "react-router-dom";
import DataTable from "../../components/DataTable";
import "./DeliveryPage.scss";
import useGetAllOrders from "../../hooks/orders/useGetAllOrders";
import CheckBox from "../../components/CheckBox";
import OrderInfoModal from "./OrderInfoModal";
import { OrderPost } from "../../types/OrderPost";
import MediumIconButton from "../../components/IconButton";
import { Breakpoint } from "react-socks";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import useUpdateOrder from "../../hooks/orders/useUpdateOrder";
import { UpdateOrder } from "../../types/UpdateOrder";
import { AuthenticationResult } from "@azure/msal-browser";
import { notify } from "common/utils/notify/notifyFunction";
import axios from "axios";
import { Order } from "types/Order";

const DeliveryPage: React.FC = () => {
  const [modalOpen, setOpenModal] = React.useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [openOrders, setOpenOrders] = React.useState<OrderPost[]>([]);
  const [initialDataLoading, setInitialDataLoading] = useState<boolean>(true);

  const columns = [
    {
      Header: "Dato",
      accessor: "createdDate", // String-based value accessors!
      Cell: ({ row }: any) => {
        let date = new Date(row.original.createdDate);
        return (
          <p onClick={(e: any) => clickedOnOpenOrder(row.original.id)}>
            {date.getDate()}/{date.getMonth()} {date.getFullYear()}
          </p>
        );
      },
    },
    {
      Header: "Kunde",
      accessor: "customer", // String-based value accessors!
      Cell: ({ row }: any) => {
        return (
          <p>
            {row.original.customer.firstName} {row.original.customer.lastName}
          </p>
        );
      },
    },
    {
      Header: "Adresse",
      accessor: "address", // String-based value accessors!
      Cell: ({ row }: any) => {
        return (
          <p>
            {row.original.customer.address} {row.original.customer.lastName}
          </p>
        );
      },
    },
    {
      Header: "Betalt",
      accessor: "paid", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.paid}
            onChange={(e: any) => {
              clickedOnPaid(row.original.id, e.target.checked);
              row.original.paid = e.target.checked;
            }}
          />
        );
      },
    },
    {
      Header: "Levert",
      accessor: "delivered", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.delivered}
            onChange={(e: any) => {
              clickedOnDelivered(row.original.id, e.target.checked);
              row.original.delivered = e.target.checked;
            }}
          />
        );
      },
    },
    {
      accessor: "notes",
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            color="primary"
            onClick={() => clickedOnOpenOrder(row.original.id)}
          >
            <InfoIcon />
          </IconButton>
        );
      },
    },
  ];

  const columnsMobile = [
    {
      Header: "Kunde",
      accessor: "customer", // String-based value accessors!
      width: 90,
      Cell: ({ row }: any) => {
        return (
          <p onClick={(e: any) => clickedOnOpenOrder(row.original.id)}>
            {row.original.customer.firstName} {row.original.customer.lastName}
          </p>
        );
      },
    },
    {
      Header: "Adresse",
      accessor: "address", // String-based value accessors!
      Cell: ({ row }: any) => {
        return (
          <p>
            {row.original.customer.address} {row.original.customer.lastName}
          </p>
        );
      },
    },
    {
      Header: "Betalt",
      accessor: "paid", // String-based value accessors!
      width: 70,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.paid}
            onChange={(e: any) => {
              clickedOnPaid(row.original.id, e.target.checked);
              row.original.paid = e.target.checked;
            }}
          />
        );
      },
    },
    {
      Header: "Levert",
      accessor: "delivered", // String-based value accessors!
      width: 70,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.delivered}
            onChange={(e: any) => {
              clickedOnDelivered(row.original.id, e.target.checked);
              row.original.delivered = e.target.checked;
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    fetchOrders();
  }, []);

  async function fetchOrders() {
    setInitialDataLoading(true);
    try {
      const request = await axios.get("orders");

      if (request.status === 200) {
        setOrders(request.data);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av ordre", true, "error");
    } finally {
      setInitialDataLoading(false);
    }
  }

  const updateOptions = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };

  async function updateOrder(order: any, id: string) {
    try {
      const request = await axios.patch(`orders/${id}`, order, updateOptions);

      if (request.status === 200) {
      }
    } catch (error) {
      notify("Det oppsto en feil ved oppdatering av ordre", true, "error");
    } finally {
    }
  }

  function clickedOnPaid(orderId: string, checked: boolean) {
    updateOrder({ paid: checked }, orderId);
  }

  function clickedOnDelete(orderId: string) {
    console.log("delete " + orderId);
  }

  function clickedOnDelivered(orderId: any, checked: boolean) {
    updateOrder({ delivered: checked }, orderId);
  }
  function clickedOnOpenOrder(orderId: string) {
    const order = orders.find((order) => order.id === orderId);
    if (order) {
      setOpenOrders(order.orderPosts);
    }

    openModal();
  }
  const closeModal = () => {
    setOpenModal(false);
  };
  const openModal = () => {
    setOpenModal(true);
  };

  return (
    <div className="d-flex flex-column">
      <div className="orders-table-header text-center my-5">
        <h1 className="orders-table-header-text">LEVERING</h1>
      </div>
      <div className="d-flex  content-align-center mt-5">
        <h2 className="flex-grow-1 text-center">Denne modulen kommer senere</h2>
      </div>

      {/* <div className="orders-table-container">
          <Breakpoint medium up>
            <DataTable columns={columns} data={orders} />
          </Breakpoint>
          <Breakpoint medium down>
            <DataTable columns={columnsMobile} data={orders} />
          </Breakpoint>

          <OrderInfoModal
            open={modalOpen}
            onClose={closeModal}
            orders={openOrders}
          />
        </div> */}
    </div>
  );
};

export default DeliveryPage;
