import React, { useState, useEffect } from "react";
import * as R from "ramda";
import "./CustomersMobile.scss";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";

import { Order } from "types/Order";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import TableCellText from "common/components/TableCellText/TableCellText";
import CheckBox from "components/CheckBox";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import EditIcon from "@material-ui/icons/Edit";
import { Customer } from "types/Customer";
type OrdersMobileProps = {
  customer: Customer;
  onOpen?: any;
  onEdit?: any;
  onDelete?: any;
};

const CustomersMobile: React.FC<OrdersMobileProps> = ({
  customer,
  onOpen,
  onEdit,
  onDelete,
}) => {
  return (
    <div className="customer-mobile-row">
      <div className="customer-mobile-section flex-grow-1">
        <div className="d-flex flex-column flex-grow-1 ms-3 ">
          <div className="d-flex flex-row flex-grow-1 align-content-center fs-3">
            {/* {dateData()} */}

            <strong>
              <div className="h-100 d-flex flex-row justify-content-center align-center">
                <p
                  className="m-auto"
                  style={{ cursor: "pointer" }}
                  onClick={() => onOpen(customer.id)}
                >
                  {customer.firstName} {customer.lastName}
                </p>
              </div>
            </strong>
          </div>
        </div>
        <div className="customer-mobile-section-content" style={{ width: 120 }}>
          <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-row flex-grow-1 mb-2">
              <div className="me-1">
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                      Salgsoversikt
                    </Tooltip>
                  }
                >
                  <IconButton
                    style={{
                      color: "#906088",
                    }}
                    onClick={() => onOpen(customer.id)}
                  >
                    <InfoIcon />
                  </IconButton>
                </OverlayTrigger>
              </div>

              <div className="me-1">
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                      Rediger kunde
                    </Tooltip>
                  }
                >
                  <IconButton
                    style={{
                      color: "#906088",
                    }}
                    onClick={() => onEdit(customer.id)}
                  >
                    <EditIcon />
                  </IconButton>
                </OverlayTrigger>
              </div>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                    Slett kunde
                  </Tooltip>
                }
              >
                <IconButton
                  style={{
                    color: "#906088",
                  }}
                  onClick={() => onDelete(customer)}
                >
                  <DeleteIcon />
                </IconButton>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersMobile;
