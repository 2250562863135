import React, { useState, useEffect } from "react";
import * as R from "ramda";
import "./ReminderModal.scss";
import { Dialog } from "@material-ui/core";
import { Order } from "types/Order";
import { Product } from "types/Product";
import { Customer } from "types/Customer";
import { NewOrder } from "types/NewOrder";
import { NewOrderPost } from "types/NewOrderPost";
import InputText from "components/InputText";
import MediumButton from "components/MediumButton";
import SearchableDropdown from "components/SearchableDropdown";
import CheckBox from "components/CheckBox";
import { NewProduct } from "types/NewProduct";
import { NewCustomer } from "types/NewCustomer";
import { Breakpoint } from "react-socks";
import { SubProduct } from "types/SubProduct";
import { NewSubProduct } from "types/NewSubProduct";
import { Col, Row } from "reactstrap";
import axios from "axios";
import { notify } from "common/utils/notify/notifyFunction";
import { OrderPost } from "types/OrderPost";
import { NewReminder } from "types/NewReminder";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReminderDetailed } from "types/ReminderDetailed";
import { ProductShort } from "types/ProductShort";
import { SubProductShort } from "types/SubProductShort";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import DiamondIcon from "@mui/icons-material/Diamond";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import useWindowDimensions from "common/functions/windowSize";

const reminderDelays = [
  { label: "Tre dager", value: 0, id: "0" },
  { label: "En uke", value: 1, id: "1" },
  { label: "To uker", value: 2, id: "2" },
  { label: "En måned", value: 3, id: "3" },
  { label: "To måneder", value: 4, id: "4" },
  { label: "Tre måneder", value: 5, id: "5" },
  { label: "Seks måneder", value: 6, id: "6" },
  { label: "Ett år", value: 7, id: "7" },
];

type ModalProps = {
  open: boolean;
  id: string;
  onClose: any;
  onCreate?: any;
  preSelectedOrder?: Order;
  preSelectedProduct?: Product;
  preSelectedCustomer?: Customer;
  preSelectedReminder?: ReminderDetailed;
  loadData?: string;
  edit?: boolean;
};

const ReminderModal: React.FC<ModalProps> = ({
  open,
  id,
  onClose,
  onCreate,
  preSelectedOrder,
  preSelectedProduct,
  preSelectedCustomer,
  preSelectedReminder,
  loadData = "none",
  edit = false,
}) => {
  const [waitingForAPI, setWaitingForAPI] = useState<boolean>(false);
  const [addSubProducts, setAddSubProducts] = useState<boolean>(false);
  const [tempSubProducts, setTempSubProducts] = useState<NewSubProduct[]>([]);
  const [tempSubProductName, setTempSubProductName] = useState("");
  const [tempSubProductArticleNumber, setTempSubProductArticleNumber] =
    useState("");

  const [customer, setCustomer] = useState<Customer>();
  const [product, setProduct] = useState<ProductShort>();
  const [reminderDelay, setReminderDelay] = useState<number | undefined>();
  const [tempReminderDelay, setTempReminderDelay] = useState<string>("");
  const [selectedReminderDelay, setSelectedReminderDelay] = useState<any>();
  const [subProduct, setSubProduct] = useState<SubProductShort>();
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<any | undefined>();
  const [selectedSubProduct, setSelectedSubProduct] = useState<any>();
  const [tempCustomerInput, setTempCustomerInput] = useState<string>("");
  const [tempProductInput, setTempProductInput] = useState<string>("");
  const [tempSubProductInput, setTempSubProductInput] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [repetitive, setRepetitive] = useState(false);
  const [customers, setCustomers] = useState<any>(null);
  const [products, setProducts] = useState<any>(null);
  const [sortedSubProducts, setSortedSubProducts] = useState<any>(null);
  const [productList, setProductList] = useState<NewOrderPost[]>([]);
  const [subProductList, setSubProductList] = useState<SubProductShort[]>([]);
  const [allProducts, setAllProducts] = useState<ProductShort[]>([]);
  const [openArticleDialog, setOpenArticleDialog] = useState<boolean>(false);
  const [openSubProductDialog, setOpenSubProductDialog] =
    useState<boolean>(false);
  const [lastProductSearch, setLastProductSearch] = useState("");
  const [preventSearch, setPreventSearch] = useState(false);
  const { windowHeight, windowWidth } = useWindowDimensions();

  useEffect(() => {
    if (open == true) {
      fetchCustomers();
      if (loadData === "product" || loadData === "all") {
        fetchProducts();
      }
    }
  }, [open]);

  useEffect(() => {
    if (preSelectedOrder !== undefined) {
      getDataFromOrder();
    }
  }, [preSelectedOrder]);

  useEffect(() => {
    checkForCreatedProduct();
  }, [products]);

  useEffect(() => {
    checkForCreatedCustomer();
  }, [customers]);

  useEffect(() => {
    getDataFromReminder();
  }, [preSelectedReminder]);

  function getDataFromReminder() {
    if (!R.isNil(preSelectedReminder)) {
      let customer = {
        label:
          preSelectedReminder?.customer.firstName +
          " " +
          preSelectedReminder?.customer.lastName,
        value: preSelectedReminder?.customer.id,
        id: preSelectedReminder?.customer.id,
      };
      setSelectedCustomer(customer);
      setCustomer(preSelectedReminder.customer);
      setCustomers([customer]);

      let productDropdownItem;
      if (preSelectedReminder.product?.articleNumber) {
        productDropdownItem = {
          label:
            preSelectedReminder.product.productName +
            " (" +
            preSelectedReminder.product.articleNumber +
            ")",
          value: preSelectedReminder.product.id,
          id: preSelectedReminder.product.id,
        };
      } else {
        productDropdownItem = {
          label: preSelectedReminder.product.productName,
          value: preSelectedReminder.product.id,
          id: preSelectedReminder.product.id,
        };
      }
      setProducts([productDropdownItem]);
      setSelectedProduct(productDropdownItem);
      const preSelectedProduct: ProductShort = {
        id: preSelectedReminder.product.id,
        articleNumber: preSelectedReminder.product.articleNumber,
        name: preSelectedReminder.product.productName,
        subProducts: [],
        defaultPrice: 0,
      };
      setProduct(preSelectedProduct);
      setAllProducts([preSelectedProduct]);
      if (preSelectedReminder.subProduct) {
        let subProduct = {
          label: preSelectedReminder.subProduct.subProductName,
          value: preSelectedReminder.subProduct.id,
          id: preSelectedReminder.subProduct.id,
        };
        const preSelectedSubProduct: SubProductShort = {
          id: preSelectedReminder.subProduct.id,
          articleNumber: preSelectedReminder.subProduct.articleNumber,
          name: preSelectedReminder.subProduct.subProductName,
          productId: preSelectedReminder.product.id,
        };

        setSubProductList([preSelectedSubProduct]);
        setSelectedSubProduct(subProduct);
        setSubProduct(preSelectedSubProduct);
        setSortedSubProducts([subProduct]);
      }
    }
  }

  function getDataFromOrder() {
    let customer = {
      label:
        preSelectedOrder?.customer.firstName +
        " " +
        preSelectedOrder?.customer.lastName,
      value: preSelectedOrder?.customer.id,
      id: preSelectedOrder?.customer.id,
    };
    setSelectedCustomer(customer);
    setCustomer(preSelectedOrder?.customer);

    let allProductsDropdown: any[] = [];
    let allProducts: ProductShort[] = [];
    let allSubProducts: SubProductShort[] = [];

    preSelectedOrder?.orderPosts.forEach((post) => {
      if (post.product) {
        const productShort: ProductShort = {
          id: post.product.id,
          articleNumber: post.product.articleNumber,
          name: post.product.productName,
          subProducts: [],
          defaultPrice: 0,
        };
        let productDropdownItem = {
          label: getProductDropdownText(productShort),
          value: productShort.id,
          id: productShort.id,
        };
        if (allProductsDropdown.length > 0) {
          if (!allProductsDropdown.find((x) => x.id == post.product.id)) {
            allProductsDropdown.push(productDropdownItem);
          }
        } else {
          allProductsDropdown = [productDropdownItem];
        }

        if (allProducts.length > 0) {
          if (!allProducts.find((x) => x.id == post.product.id)) {
            allProducts.push(productShort);
          }
        } else {
          allProducts = [productShort];
        }
        if (post.subProduct) {
          const subProductShort: SubProductShort = {
            id: post.subProduct.id,
            articleNumber: post.subProduct.articleNumber,
            name: post.subProduct.subProductName,
            productId: post.product.id,
          };
          if (allSubProducts.length > 0) {
            if (!allSubProducts.find((x) => x.id == subProductShort.id)) {
              allSubProducts.push(subProductShort);
            }
          } else {
            allSubProducts = [subProductShort];
          }
        }
      }
    });

    if (allProducts.length > 0) {
      setProducts(allProductsDropdown);
      setSelectedProduct(allProductsDropdown[0]);
      setProduct(allProducts[0]);
      setAllProducts(allProducts);
    }

    if (allSubProducts.length > 0) {
      setSubProductList(allSubProducts);
      sortSubProductsFromInput(allSubProducts, allProducts[0].id);
    }
  }

  async function fetchCustomers() {
    try {
      const request = await axios.get("customers");

      if (request.status === 200) {
        let allCustomers = request.data.map((customer: Customer) => {
          return {
            label: customer.firstName + " " + customer.lastName,
            value: customer.id,
            id: customer.id,
          };
        });
        setCustomers(allCustomers);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av kunder", true, "error");
    }
  }

  function getProductDropdownText(product: ProductShort) {
    if (product.articleNumber == "" && R.isEmpty(product.subProducts)) {
      return product.name;
    }
    if (R.isEmpty(product.subProducts)) {
      return product.name + " (" + product.articleNumber + ")";
    }
    let text = product.name + " (";
    product.subProducts.forEach((subProduct, index) => {
      if (index == 0) {
        text += subProduct.articleNumber;
      } else {
        text += ", " + subProduct.articleNumber;
      }
    });
    return text + ")";
  }

  async function fetchProducts() {
    try {
      let encodedInput = encodeURIComponent(tempProductInput);
      const request = await axios.get("products" + "?query=" + encodedInput);
      setLastProductSearch(tempProductInput);

      if (request.status === 200) {
        let allProducts = request.data.products.map((product: ProductShort) => {
          return {
            label: getProductDropdownText(product),
            value: product.id,
            id: product.id,
          };
        });
        setAllProducts(request.data.products);
        setProducts(allProducts);

        setSubProductList(request.data.subProducts);
        if (product) {
          sortSubProductsFromInput(request.data.subProducts, product.id);
        }
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av produkter", true, "error");
    } finally {
    }
  }

  useEffect(() => {
    if (preventSearch) {
      setPreventSearch(false);
      return;
    }
    if (tempProductInput?.length >= 3 || lastProductSearch?.length >= 3) {
      // if (localValue != value) {
      let timer = setTimeout(() => {
        fetchProducts();
      }, 800);

      return () => clearTimeout(timer);
    }
  }, [tempProductInput]);

  function checkForCreatedCustomer() {
    if (tempCustomerInput !== "") {
      let createdCustomer = customers.find(
        (p: any) => p.label === tempCustomerInput
      );
      if (createdCustomer) {
        setSelectedCustomer(createdCustomer);
        setCustomer(createdCustomer);
        setTempCustomerInput("");
      }
    }
  }

  async function checkForCreatedProduct() {
    if (tempProductInput !== "") {
      let createdProduct = allProducts.find(
        (p: any) => p.name === tempProductInput
      );
      if (createdProduct) {
        let dropDownItem = products.find(
          (c: any) => c.id === createdProduct?.id
        );
        setSelectedProduct(dropDownItem);
        setProduct(createdProduct);
        setSelectedSubProduct(null);
        setSelectedSubProduct(null);
        sortSubProductsFromInput(createdProduct.subProducts, createdProduct.id);

        setTempProductInput("");
      }
    }
  }

  async function sortSubProducts(productId: string) {
    const sortedList = subProductList.filter((s) => s.productId === productId);
    if (sortedList.length > 0) {
      let allSubProducts = sortedList.map((subProduct: SubProductShort) => {
        if (subProduct.articleNumber) {
          return {
            label: subProduct.name + " (" + subProduct.articleNumber + ")",
            value: subProduct.id,
            id: subProduct.id,
          };
        } else {
          return {
            label: subProduct.name,
            value: subProduct.id,
            id: subProduct.id,
          };
        }
      });
      setSortedSubProducts(allSubProducts);
    } else {
      setSortedSubProducts(null);
    }
  }

  function createTempSubProduct() {
    if (tempSubProductName !== "" && tempSubProductArticleNumber !== "") {
      const subProduct: NewSubProduct = {
        subProductName: tempSubProductName,
        articleNumber: tempSubProductArticleNumber,
      };

      const newSubProductList = tempSubProducts.concat(subProduct);
      setTempSubProducts(newSubProductList);
      setTempSubProductName("");
      setTempSubProductArticleNumber("");
    }
  }

  async function sortSubProductsFromInput(
    subProductList: SubProductShort[],
    productId: string
  ) {
    const sortedList = subProductList.filter((s) => s.productId === productId);
    if (sortedList.length > 0) {
      let allSubProducts = sortedList.map((subProduct: SubProductShort) => {
        if (subProduct.articleNumber) {
          return {
            label: subProduct.name + " (" + subProduct.articleNumber + ")",
            value: subProduct.id,
            id: subProduct.id,
          };
        } else {
          return {
            label: subProduct.name,
            value: subProduct.id,
            id: subProduct.id,
          };
        }
      });
      setSortedSubProducts(allSubProducts);

      if (tempSubProductInput !== "") {
        let createdSubProduct = sortedList.find(
          (p: any) => p.name === tempSubProductInput
        );
        if (createdSubProduct) {
          let dropDownItem = allSubProducts.find(
            (c: any) => c.id === createdSubProduct?.id
          );
          setSelectedSubProduct(dropDownItem);
          setSubProduct(createdSubProduct);
        }
      }
    } else {
      setSortedSubProducts(null);
    }
    setTempSubProductInput("");
  }

  async function createCustomer(customer: any) {
    var newCustomer: NewCustomer = {
      customerType: 1,
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      address2: "",
      postCode: "",
      postArea: "",
      otherInfo: "",
      phone: "",
    };
    var res: string[] = [];
    res = customer.split(" ");
    if (!res || res.length === 0) {
      return;
    } else if (res.length === 1) {
      newCustomer.firstName = res[0];
    } else if (res.length === 2) {
      newCustomer.firstName = res[0];
      newCustomer.lastName = res[1];
    } else {
      let firstName = "";
      res.map((word: string, i: number) => {
        if (i + 1 < res.length) {
          firstName = firstName + " " + word;
        } else {
          newCustomer.lastName = word;
        }
      });
      newCustomer.firstName = firstName;
    }

    try {
      setWaitingForAPI(true);
      const request = await axios.post("customers", newCustomer);

      if (request.status === 201) {
        notify("En ny kunde ble opprettet", true, "success");
        fetchCustomers();
      }
    } catch (error) {
      notify("Det oppsto en feil ved opprettelse av kunde", true, "error");
    } finally {
      setWaitingForAPI(false);
    }
  }

  async function editReminder() {
    if (customer && product) {
      var newReminder: NewReminder = {
        customerId: Number(customer.id),
        productId: Number(product.id),
        timeIntervale: Number(reminderDelay),
        subProductId: Number(subProduct?.id),
      };

      try {
        setWaitingForAPI(true);
        const request = await axios.post("productNotifications", newReminder);

        if (request.status === 201) {
          notify("En påminnelse ble opprettet", true, "success");
          onCreate();
          setSelectedCustomer(undefined);
          setSelectedProduct(undefined);
          setSelectedSubProduct(undefined);
          setSelectedReminderDelay(undefined);
          onClose(true);
        }
      } catch (error) {
        notify(
          "Det oppsto en feil ved opprettelse av påminnelse",
          true,
          "error"
        );
      } finally {
        setWaitingForAPI(false);
      }
    }
  }

  async function createReminder() {
    if (customer && product) {
      var newReminder: NewReminder = {
        customerId: Number(customer.id),
        productId: Number(product.id),
        timeIntervale: Number(reminderDelay),
        subProductId: Number(subProduct?.id),
      };

      try {
        setWaitingForAPI(true);
        const request = await axios.post("productNotifications", newReminder);

        if (request.status === 201) {
          notify("En påminnelse ble opprettet", true, "success");
          onCreate();
          setSelectedCustomer(undefined);
          setSelectedProduct(undefined);
          setSelectedSubProduct(undefined);
          setSelectedReminderDelay(undefined);
          onClose(true);
        }
      } catch (error) {
        notify(
          "Det oppsto en feil ved opprettelse av påminnelse",
          true,
          "error"
        );
      } finally {
        setWaitingForAPI(false);
      }
    }

    async function createProduct(product: string, articleNr: string) {
      const newProduct: NewProduct = {
        productName: product,
        articleNumber: articleNr,
        subProducts: tempSubProducts,
      };

      try {
        setWaitingForAPI(true);
        const request = await axios.post("products", newProduct);

        if (request.status === 201) {
          notify("Et nytt product ble opprettet", true, "success");
          fetchProducts();
        }
      } catch (error) {
        notify("Det oppsto en feil ved opprettelse av produkt", true, "error");
      } finally {
        setWaitingForAPI(false);
      }
    }
  }

  async function createSubProduct(subProduct: string, articleNr: string) {
    if (product) {
      const newSubProduct: NewSubProduct = {
        subProductName: subProduct,
        articleNumber: articleNr,
        productId: product.id,
      };

      try {
        setWaitingForAPI(true);
        const request = await axios.post("subproducts", newSubProduct);

        if (request.status === 201) {
          notify("En ny product sub kategori ble opprettet", true, "success");
          fetchCustomers();
        }
      } catch (error) {
        notify(
          "Det oppsto en feil ved opprettelse av sub produkt",
          true,
          "error"
        );
      } finally {
        setWaitingForAPI(false);
      }
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"md"}
      className="dialog"
      fullScreen={windowWidth < 500}
    >
      <div className="reminder-modal">
        <div className="reminder-modal-background">
          <div className="reminder-modal-top">
            <h2
              id="reminder-simple-modal-title"
              className="reminder-modal-top-title"
            >
              Påminnelse:
            </h2>
            <div className="d-flex flex-column" onClick={onClose}>
              <h2 className="reminder-modal-top-close">X</h2>
            </div>
          </div>
          <div className="info-container">
            <Breakpoint medium up>
              <div className="d-flex m-4 mb-0">
                <p>
                  Her kan du legge inn en påminnelse, slik at du ikke glemmer å
                  spør kunden om den vil ha mer senere.
                </p>
              </div>
            </Breakpoint>
            <Breakpoint small down>
              <div className="d-flex flex-column px-2">
                <div className="d-flex m-1 mb-0 mt-3">
                  <p>
                    Her kan du legge inn en påminnelse, slik at du ikke glemmer
                    å spør kunden om den vil ha mer senere.
                  </p>
                </div>
              </div>
            </Breakpoint>
            {/* <Breakpoint medium up> */}
            <div className="reminder-modal-row">
              <div className="reminder-modal-section flex-grow-1">
                <div className="reminder-modal-section-icon">
                  <PermIdentityOutlinedIcon fontSize="inherit" />
                </div>
                <div className="reminder-modal-section-content">
                  <div className="d-flex flex-column">
                    <div className="d-flex flex-row flex-grow-1 align-content-center">
                      <h3
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          display: "flex",
                          flexGrow: 1,
                        }}
                      >
                        Kunde
                      </h3>
                      <div className="d-flex flex-row justify-content-end">
                        {/* <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip
                              id="customer-tooltip"
                              style={{ zIndex: 1301 }}
                            >
                              Trykk for å laste inn flere kunder
                            </Tooltip>
                          }
                        >
                          <IconButton
                            style={{
                              color: "#906088",
                              fontSize: 20,
                            }}
                            size="small"
                            onClick={() => fetchCustomers()}
                          >
                            <AddIcon fontSize="medium" />
                          </IconButton>
                        </OverlayTrigger> */}
                      </div>
                    </div>
                    <div className="reminder-modal-select flex-column">
                      <SearchableDropdown
                        value={selectedCustomer}
                        items={customers}
                        typeText="kunde"
                        width={225}
                        onChange={(opt) => {
                          setCustomer(opt);
                          setSelectedCustomer(opt);
                          setTempCustomerInput(opt.firstName);
                        }}
                        onCreateOption={(opt2) => {
                          if (!waitingForAPI) {
                            setWaitingForAPI(true);
                            createCustomer(opt2);
                          } else {
                          }
                        }}
                        onInputChange={(text) => {
                          if (
                            (tempCustomerInput &&
                              tempCustomerInput.length < 2) ||
                            text !== ""
                          ) {
                            setTempCustomerInput(text);
                          }
                        }}
                        inputValue={tempCustomerInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Breakpoint medium up>
              <div className="reminder-modal-row">
                <div className="reminder-modal-section flex-grow-1">
                  <div className="reminder-modal-section-icon">
                    <DiamondIcon fontSize="inherit" />
                  </div>
                  <div className="reminder-modal-section-content">
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row align-content-center">
                        <h3
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            display: "flex",
                            width: 195,
                          }}
                        >
                          Produkt
                        </h3>
                        {/* <div className="d-flex flex-row justify-content-end">
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip
                                id="product-tooltip"
                                style={{ zIndex: 1301 }}
                              >
                                Trykk for å laste inn flere produkter
                              </Tooltip>
                            }
                          >
                            <IconButton
                              style={{
                                color: "#906088",
                                fontSize: 20,
                              }}
                              size="small"
                              onClick={() => {
                                fetchProducts();
                              }}
                            >
                              <AddIcon fontSize="medium" />
                            </IconButton>
                          </OverlayTrigger>
                        </div> */}
                      </div>

                      <div className="d-flex flex-row">
                        <div className="reminder-modal-select me-4">
                          <SearchableDropdown
                            noFilter={true}
                            value={selectedProduct}
                            items={products}
                            typeText="produkt"
                            width={225}
                            onChange={(opt) => {
                              setProduct(
                                allProducts.find((p) => p.id === opt.id)
                              );
                              setSelectedProduct(opt);
                              setSelectedSubProduct(null);
                              setTempProductInput(opt.productName);
                              sortSubProducts(opt.id);
                            }}
                            onCreateOption={(opt2) => {
                              setOpenArticleDialog(true);
                            }}
                            onInputChange={(text) => {
                              if (
                                (tempProductInput &&
                                  tempProductInput.length < 2) ||
                                text !== ""
                              ) {
                                setTempProductInput(text);
                              }
                            }}
                            inputValue={tempProductInput}
                          />
                        </div>
                        {sortedSubProducts ? (
                          <div className="reminder-modal-select flex-column">
                            <SearchableDropdown
                              value={selectedSubProduct}
                              items={sortedSubProducts}
                              typeText="type"
                              width={225}
                              onChange={(opt) => {
                                setSubProduct(
                                  subProductList.find((p) => p.id === opt.id)
                                );
                                setSelectedSubProduct(opt);
                                setTempSubProductInput(opt.productName);
                              }}
                              onInputChange={(text) => {
                                if (
                                  (tempSubProductInput &&
                                    tempSubProductInput.length < 2) ||
                                  text !== ""
                                ) {
                                  setTempSubProductInput(text);
                                }
                              }}
                              inputValue={tempSubProductInput}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Breakpoint>
            <Breakpoint small down>
              <div className="reminder-modal-row">
                <div className="reminder-modal-section flex-grow-1">
                  <div className="reminder-modal-section-icon">
                    <DiamondIcon fontSize="inherit" />
                  </div>
                  <div className="reminder-modal-section-content">
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row align-content-center">
                        <h3
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            display: "flex",
                            width: 195,
                          }}
                        >
                          Produkt
                        </h3>
                        <div className="d-flex flex-row justify-content-end">
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip
                                id="product-tooltip"
                                style={{ zIndex: 1301 }}
                              >
                                Trykk for å laste inn flere produkter
                              </Tooltip>
                            }
                          >
                            <IconButton
                              style={{
                                color: "#906088",
                                fontSize: 20,
                              }}
                              size="small"
                              onClick={() => {
                                fetchProducts();
                              }}
                            >
                              <AddIcon fontSize="medium" />
                            </IconButton>
                          </OverlayTrigger>
                        </div>
                      </div>

                      <div className="reminder-modal-select me-4">
                        <SearchableDropdown
                          noFilter={true}
                          value={selectedProduct}
                          items={products}
                          typeText="produkt"
                          width={225}
                          onChange={(opt) => {
                            setProduct(
                              allProducts.find((p) => p.id === opt.id)
                            );
                            setSelectedProduct(opt);
                            setSelectedSubProduct(null);
                            setTempProductInput(opt.productName);
                            sortSubProducts(opt.id);
                          }}
                          onCreateOption={(opt2) => {
                            setOpenArticleDialog(true);
                          }}
                          onInputChange={(text) => {
                            if (
                              (tempProductInput &&
                                tempProductInput.length < 2) ||
                              text !== ""
                            ) {
                              setTempProductInput(text);
                            }
                          }}
                          inputValue={tempProductInput}
                        />
                      </div>
                      {sortedSubProducts ? (
                        <div className="reminder-modal-select flex-column mt-2">
                          <SearchableDropdown
                            value={selectedSubProduct}
                            items={sortedSubProducts}
                            typeText="type"
                            width={225}
                            onChange={(opt) => {
                              setSubProduct(
                                subProductList.find((p) => p.id === opt.id)
                              );
                              setSelectedSubProduct(opt);
                              setTempSubProductInput(opt.productName);
                            }}
                            onInputChange={(text) => {
                              if (
                                (tempSubProductInput &&
                                  tempSubProductInput.length < 2) ||
                                text !== ""
                              ) {
                                setTempSubProductInput(text);
                              }
                            }}
                            inputValue={tempSubProductInput}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </Breakpoint>
            <Breakpoint medium up>
              <div className="reminder-modal-row">
                <div className="reminder-modal-section flex-grow-1">
                  <div className="reminder-modal-section-icon">
                    <NotificationsNoneIcon fontSize="inherit" />
                  </div>
                  <div className="reminder-modal-section-content">
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row flex-grow-1 align-content-center">
                        <h3
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            display: "flex",
                            flexGrow: 1,
                          }}
                        >
                          Påminnelse om
                        </h3>
                      </div>
                      <div className="reminder-modal-select flex-column">
                        <SearchableDropdown
                          value={selectedReminderDelay}
                          items={reminderDelays}
                          typeText="periode"
                          onChange={(opt) => {
                            setReminderDelay(opt.id);
                            setSelectedReminderDelay(opt);
                          }}
                          inputValue={tempReminderDelay}
                        />
                        {/* <div className="d-flex me-5">
                  <p className="my-auto">
                    <b>eller</b>
                  </p>
                </div>
                <div className="reminder-view-modal-number-input">
                  <InputText
                    inputTitle="Antall dager"
                    value={price !== 0 ? price : ""}
                    type="number"
                    onChange={(e: any) => setPrice(e.target.value)}
                  />
                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reminder-modal-section w-25 ms-2">
                  <div className="create-order-button-container">
                    {!edit ? (
                      <MediumButton
                        buttonText="LEGG TIL"
                        onClick={() => createReminder()}
                      />
                    ) : (
                      <MediumButton
                        buttonText="OPPDATER"
                        onClick={() => editReminder()}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Breakpoint>
            <Breakpoint small down>
              <div className="reminder-modal-row">
                <div className="reminder-modal-section flex-grow-1">
                  <div className="reminder-modal-section-icon">
                    <NotificationsNoneIcon fontSize="inherit" />
                  </div>
                  <div className="reminder-modal-section-content">
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-row flex-grow-1 align-content-center">
                        <h3
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            display: "flex",
                            flexGrow: 1,
                          }}
                        >
                          Påminnelse om
                        </h3>
                      </div>
                      <div className="reminder-modal-select flex-column">
                        <SearchableDropdown
                          value={selectedReminderDelay}
                          items={reminderDelays}
                          typeText="periode"
                          onChange={(opt) => {
                            setReminderDelay(opt.id);
                            setSelectedReminderDelay(opt);
                          }}
                          inputValue={tempReminderDelay}
                        />
                        {/* <div className="d-flex me-5">
                  <p className="my-auto">
                    <b>eller</b>
                  </p>
                </div>
                <div className="reminder-view-modal-number-input">
                  <InputText
                    inputTitle="Antall dager"
                    value={price !== 0 ? price : ""}
                    type="number"
                    onChange={(e: any) => setPrice(e.target.value)}
                  />
                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="reminder-modal-row">
                <div className="reminder-modal-section flex-grow-1">
                  <div className="create-order-button-container">
                    {!edit ? (
                      <MediumButton
                        buttonText="LEGG TIL"
                        onClick={() => createReminder()}
                      />
                    ) : (
                      <MediumButton
                        buttonText="OPPDATER"
                        onClick={() => editReminder()}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Breakpoint>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ReminderModal;
