import React from "react";
import "./OrdersOverview.scss";
import { Breakpoint } from "react-socks";
import { Order } from "types/Order";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import OrdersMobile from "pages/my-page/components/orders/OrdersMobile";
import { useHistory } from "react-router-dom";
import MediumButtonLong from "components/MediumButtonLong";

type OrdersProps = {
  orders: Order[];
  clickedOnPaid?: any;
  clickedOnDelivered?: any;
  clickedOnOrdered?: any;
  onOpenOrder?: any;
  onOpenReminder?: any;
  onEdit?: any;
  onDelete?: any;
};

const OrdersOverview: React.FC<OrdersProps> = ({
  orders,
  clickedOnPaid,
  clickedOnDelivered,
  clickedOnOrdered,
  onOpenOrder,
  onOpenReminder,
  onEdit,
  onDelete,
}) => {
  const history = useHistory();

  if (orders.length === 0) {
    return (
      <div className="d-flex flex-column justify-content-center mt-5 text-center align-items-center">
        <h4>Opprett ditt første salg</h4>
        <MediumButtonLong
          buttonText="Nytt salg"
          onClick={() => history.push("/new-order")}
        />
      </div>
    );
  }

  return (
    <div className="d-flex flex-row flex-grow-1 justify-content-center">
      <Breakpoint medium up>
        {orders.map((order: Order) => {
          return (
            <div key={"order-div" + order.id}>{order.customer.firstName}</div>
          );
        })}
      </Breakpoint>
      <Breakpoint small down>
        <div className="d-flex flex-row justify-content-center w-100 mb-2 mt-4">
          <h2 className="my-auto me-1">Nyeste salg</h2>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 3001 }}>
                Opprett nytt salg
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
                fontSize: 20,
              }}
              size="small"
              onClick={() => history.push("/new-order")}
            >
              <AddIcon fontSize="medium" />
            </IconButton>
          </OverlayTrigger>
        </div>
        {orders.map((order: Order, index) => {
          return (
            <div key={"order-mobile-div" + order.id}>
              <OrdersMobile
                order={order}
                clickedOnPaid={clickedOnPaid}
                clickedOnDelivered={clickedOnDelivered}
                clickedOnOrdered={clickedOnOrdered}
                onOpenOrder={onOpenOrder}
                onOpenReminder={onOpenReminder}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </div>
          );
        })}
      </Breakpoint>
    </div>
  );
};

export default OrdersOverview;
