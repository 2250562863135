import React, { useState, useEffect } from "react";
import * as R from "ramda";
import "./ViewReminderModal.scss";
import { Dialog } from "@material-ui/core";
import { Order } from "types/Order";
import { Product } from "types/Product";
import { Customer } from "types/Customer";
import { NewOrder } from "types/NewOrder";
import { NewOrderPost } from "types/NewOrderPost";
import InputText from "components/InputText";
import MediumButton from "components/MediumButton";
import SearchableDropdown from "components/SearchableDropdown";
import CheckBox from "components/CheckBox";
import { NewProduct } from "types/NewProduct";
import { NewCustomer } from "types/NewCustomer";
import { Breakpoint } from "react-socks";
import { SubProduct } from "types/SubProduct";
import { NewSubProduct } from "types/NewSubProduct";
import { Col, Row } from "reactstrap";
import axios from "axios";
import { notify } from "common/utils/notify/notifyFunction";
import { OrderPost } from "types/OrderPost";
import { NewReminder } from "types/NewReminder";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Reminder } from "types/Reminder";
import { ReminderDetailed } from "types/ReminderDetailed";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import DiamondIcon from "@mui/icons-material/Diamond";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

const reminderDelays = [
  { label: "Tre dager", value: 0, id: "0" },
  { label: "En uke", value: 1, id: "1" },
  { label: "To uker", value: 2, id: "2" },
  { label: "En måned", value: 3, id: "3" },
  { label: "To måneder", value: 4, id: "4" },
  { label: "Tre måneder", value: 5, id: "5" },
  { label: "Seks måneder", value: 6, id: "6" },
  { label: "Ett år", value: 7, id: "7" },
];

type ModalProps = {
  open: boolean;
  onClose: any;
  detailedReminder: ReminderDetailed | undefined;
};

const ViewReminderModal: React.FC<ModalProps> = ({
  open,
  detailedReminder,
  onClose,
}) => {
  const [waitingForAPI, setWaitingForAPI] = useState<boolean>(false);
  const [currentReminder, setCurrentReminder] = useState<
    ReminderDetailed | undefined
  >(detailedReminder);

  const [reminderDelay, setReminderDelay] = useState<number | undefined>();
  const [tempReminderDelay, setTempReminderDelay] = useState<string>("");
  const [selectedReminderDelay, setSelectedReminderDelay] = useState<any>();

  useEffect(() => {
    setCurrentReminder(detailedReminder);
  }, [detailedReminder]);

  async function updateReminder() {
    var newReminder = {
      delayType: Number(reminderDelay),
    };
    try {
      setWaitingForAPI(true);
      const request = await axios.post(
        `productNotifications/postpone/${detailedReminder?.id}`,
        newReminder
      );
      if (request.status === 200) {
        setCurrentReminder(request.data);
        notify("En påminnelse ble oppdatert", true, "success");
        onClose();
      }
    } catch (error) {
      notify("Det oppsto en feil ved opprettelse av påminnelse", true, "error");
    } finally {
      setWaitingForAPI(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"} className="dialog">
      {detailedReminder && (
        <div className="reminder-modal">
          <div className="reminder-modal-background">
            <div className="reminder-modal-top">
              <h2
                id="reminder-simple-modal-title"
                className="reminder-modal-top-title"
              >
                Påminnelse:
              </h2>
              <div className="d-flex flex-column" onClick={onClose}>
                <h2 className="reminder-modal-top-close">X</h2>
              </div>
            </div>
            <div className="info-container">
              <Breakpoint medium up>
                <div className="d-flex flex-row flex-grow-1">
                  <div className="d-flex m-4 mb-0">
                    <p>Her kan du utsette påminnelsen.</p>
                  </div>
                  {currentReminder?.daysToAlert && (
                    <div className="d-flex flex-row align-content-center m-auto">
                      <div className="d-flex font-weight-bold">
                        <p
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            margin: "auto",
                            marginRight: 10,
                          }}
                        >
                          Dager igjen:
                        </p>
                        <div className="bg-white p-2">
                          {currentReminder.daysToAlert < 0 ? (
                            <p
                              style={{
                                margin: "auto",
                                fontWeight: 600,
                                fontSize: 16,
                              }}
                            >
                              {0}
                            </p>
                          ) : (
                            <p
                              style={{
                                margin: "auto",
                                fontWeight: 600,
                                fontSize: 16,
                              }}
                            >
                              {detailedReminder.daysToAlert}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Breakpoint>
              <Breakpoint small down>
                <div className="d-flex flex-row px-2">
                  <div className="d-flex m-1 mb-0 mt-3">
                    <p>Her kan du utsette påminnelsen.</p>
                  </div>
                  <div className="d-flex flex-row flex-grow-1 justify-content-center pt-2">
                    {currentReminder?.daysToAlert && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip
                            id="customer-tooltip"
                            style={{ zIndex: 1301 }}
                          >
                            Dager igjen
                          </Tooltip>
                        }
                      >
                        <div className="bg-white p-2 h-75">
                          {currentReminder.daysToAlert < 0 ? (
                            <p
                              style={{
                                margin: "auto",
                                fontWeight: 600,
                                fontSize: 16,
                              }}
                            >
                              {0}
                            </p>
                          ) : (
                            <p
                              style={{
                                margin: "auto",
                                fontWeight: 600,
                                fontSize: 16,
                              }}
                            >
                              {detailedReminder.daysToAlert}
                            </p>
                          )}
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </Breakpoint>
              {/* <Breakpoint medium up> */}
              <div className="reminder-modal-row">
                <div className="reminder-modal-section flex-grow-1">
                  <div className="reminder-modal-section-icon">
                    <PermIdentityOutlinedIcon fontSize="inherit" />
                  </div>
                  <div className="reminder-modal-section-content">
                    <div className="d-flex flex-column">
                      <h3 style={{ fontWeight: 600, fontSize: 16 }}>Kunde:</h3>
                      <p>
                        <strong>
                          {detailedReminder.customer.firstName}{" "}
                          {detailedReminder.customer.lastName}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <Breakpoint medium up>
                <div className="reminder-modal-row">
                  <div className="reminder-modal-section flex-grow-1">
                    <div className="reminder-modal-section-icon">
                      <DiamondIcon fontSize="inherit" />
                    </div>
                    <div className="reminder-modal-section-content">
                      <div className="d-flex flex-column">
                        <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                          Produkt:
                        </h3>
                        <p>
                          <strong>
                            {detailedReminder.product.productName}
                            {detailedReminder.subProduct
                              ? ", " +
                                detailedReminder.subProduct.subProductName
                              : null}
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Breakpoint>
              <Breakpoint small down>
                <div className="reminder-modal-row">
                  <div className="reminder-modal-section flex-grow-1">
                    <div className="reminder-modal-section-icon">
                      <DiamondIcon fontSize="inherit" />
                    </div>
                    <div className="reminder-modal-section-content">
                      <div className="d-flex flex-column ms-2">
                        <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                          Produkt:
                        </h3>
                        <p>
                          <strong>
                            {detailedReminder.product.productName}
                            {detailedReminder.subProduct
                              ? ", " +
                                detailedReminder.subProduct.subProductName
                              : null}
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Breakpoint>
              <Breakpoint medium up>
                <div className="reminder-modal-row">
                  <div className="reminder-modal-section flex-grow-1">
                    <div className="reminder-modal-section-icon">
                      <NotificationsNoneIcon fontSize="inherit" />
                    </div>
                    <div className="reminder-modal-section-content">
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row flex-grow-1 align-content-center">
                          <h3
                            style={{
                              fontWeight: 600,
                              fontSize: 16,
                              display: "flex",
                              flexGrow: 1,
                            }}
                          >
                            Påminnelse om
                          </h3>
                        </div>
                        <div className="reminder-modal-select flex-column">
                          <SearchableDropdown
                            value={selectedReminderDelay}
                            items={reminderDelays}
                            typeText="periode"
                            onChange={(opt) => {
                              setReminderDelay(opt.id);
                              setSelectedReminderDelay(opt);
                            }}
                            inputValue={tempReminderDelay}
                          />
                          {/* <div className="d-flex me-5">
                  <p className="my-auto">
                    <b>eller</b>
                  </p>
                </div>
                <div className="reminder-view-modal-number-input">
                  <InputText
                    inputTitle="Antall dager"
                    value={price !== 0 ? price : ""}
                    type="number"
                    onChange={(e: any) => setPrice(e.target.value)}
                  />
                </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="reminder-modal-section w-25 ms-2">
                    <div className="create-order-button-container">
                      <MediumButton
                        buttonText="OPPDATER"
                        onClick={() => updateReminder()}
                      />
                    </div>
                  </div>
                </div>
              </Breakpoint>
              <Breakpoint small down>
                <div className="reminder-modal-row">
                  <div className="reminder-modal-section flex-grow-1">
                    <div className="reminder-modal-section-icon">
                      <NotificationsNoneIcon fontSize="inherit" />
                    </div>
                    <div className="reminder-modal-section-content">
                      <div className="d-flex flex-column">
                        <div className="d-flex me-5">
                          <p
                            style={{
                              fontWeight: 600,
                              fontSize: 16,
                              margin: "auto",
                            }}
                          >
                            Utsett i{" "}
                          </p>
                        </div>
                        <div className="d-flex reminder-view-modal-select mb-2 align-items-center">
                          <SearchableDropdown
                            value={selectedReminderDelay}
                            items={reminderDelays}
                            typeText="periode"
                            onChange={(opt) => {
                              console.log();
                              setReminderDelay(opt.id);
                              setSelectedReminderDelay(opt);
                            }}
                            inputValue={tempReminderDelay}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="reminder-modal-row">
                  <div className="reminder-modal-section flex-grow-1">
                    <div className="create-order-button-container">
                      <MediumButton
                        buttonText="OPPDATER"
                        onClick={() => updateReminder()}
                      />
                    </div>
                  </div>
                </div>
              </Breakpoint>
            </div>
          </div>
        </div>
      )}

      {/* {currentReminder?.daysToAlert && (
                  <div className="d-flex flex-row align-content-center m-auto">
                    <div className="d-flex font-weight-bold">
                      <p
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          margin: "auto",
                          marginRight: 10,
                        }}
                      >
                        Dager igjen:
                      </p>
                      <div className="bg-white p-2">
                        {currentReminder.daysToAlert < 0 && (
                          <p
                            style={{
                              color: "red",
                              margin: "auto",
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            {currentReminder.daysToAlert}
                          </p>
                        )}
                        {currentReminder.daysToAlert < 7 &&
                          currentReminder.daysToAlert >= 0 && (
                            <p
                              style={{
                                color: "orange",
                                margin: "auto",
                                fontWeight: 600,
                                fontSize: 16,
                              }}
                            >
                              {detailedReminder.daysToAlert}
                            </p>
                          )}
                        {currentReminder.daysToAlert >= 7 && (
                          <p
                            style={{
                              margin: "auto",
                              fontWeight: 600,
                              fontSize: 16,
                            }}
                          >
                            {detailedReminder.daysToAlert}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )} */}
    </Dialog>
  );
};

export default ViewReminderModal;
