import React, { useState, useEffect } from "react";
import * as R from "ramda";
import "./SystemModal.scss";
import { Dialog } from "@material-ui/core";
import InputText from "components/InputText";
import MediumButton from "components/MediumButton";
import axios from "axios";
import { notify } from "common/utils/notify/notifyFunction";
import { string } from "yup";
import { Breakpoint } from "react-socks";

type ModalProps = {
  open: boolean;
  onClose: any;
  onCreate?: any;
  tempName?: string;
  setCreatedSystemName?: any;
};

const SystemModal: React.FC<ModalProps> = ({
  open,
  onClose,
  onCreate,
  tempName,
  setCreatedSystemName,
}) => {
  const [waitingForAPI, setWaitingForAPI] = useState<boolean>(false);

  const [systemName, setSystemName] = useState<string>();

  useEffect(() => {
    if (tempName && tempName !== "") {
      setSystemName(tempName);
    }
  }, [open]);

  async function createSystem() {
    var newSystem = {
      name: systemName,
    };

    try {
      setWaitingForAPI(true);
      const request = await axios.post("systems", newSystem);

      if (request.status === 201) {
        notify(newSystem.name + " ble registrert", true, "success");
        setCreatedSystemName(newSystem.name);
        onCreate();
        onClose();
      }
    } catch (error) {
      notify("Det oppsto en feil ved opprettelse av firma", true, "error");
    } finally {
      setWaitingForAPI(false);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"md"} className="dialog">
      <div className="system-modal">
        <div className="system-modal-background">
          <div className="system-modal-top">
            <h2
              id="reminder-simple-modal-title"
              className="system-modal-top-title"
            >
              Salgsfirma:
            </h2>
            <h2 className="system-modal-top-close" onClick={onClose}>
              X
            </h2>
          </div>
          <div className="info-container">
            <Breakpoint medium up>
              <div className="d-flex m-4 mb-0">
                <p>Her kan du registrere et nytt salgsfirma.</p>
              </div>
              <div className="d-flex flex-column mx-4">
                <div className="system-modal-repetitative">
                  <div className="system-modal-number-input">
                    <InputText
                      inputTitle="Firmanavn"
                      value={systemName}
                      onChange={(e: any) => setSystemName(e.target.value)}
                    />
                  </div>
                  <div className="create-order-button-container">
                    <MediumButton
                      buttonText="Register"
                      onClick={() => createSystem()}
                    />
                  </div>
                </div>
              </div>
            </Breakpoint>
            <Breakpoint small down>
              <div className="d-flex m-4 mb-0">
                <p>Her kan du registrere et nytt salgsfirma.</p>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="">
                  <InputText
                    inputTitle="Firmanavn"
                    value={systemName}
                    onChange={(e: any) => setSystemName(e.target.value)}
                  />
                </div>
                <div className="create-order-button-container">
                  <MediumButton
                    buttonText="Register"
                    onClick={() => createSystem()}
                  />
                </div>
              </div>
            </Breakpoint>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SystemModal;
