import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./TableCellText.scss";

type CellTextProps = {
  text: string;
  width: number;
  threeRows?: boolean;
  onClick?: (event: any) => void;
};

const TableCellText: React.FC<CellTextProps> = ({
  text,
  width = 200,
  threeRows = false,
  onClick,
}) => {
  const [oneLine, setOneLine] = useState<boolean>(false);

  useEffect(() => {
    formatText();
  }, [text, width]);

  function formatText() {
    if (text.length < width / 7) {
      setOneLine(true);
    } else {
      setOneLine(false);
    }
  }

  return (
    <div>
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={
          <Tooltip id={`${text}-tooltip`} style={{ zIndex: 1301 }}>
            {text}
          </Tooltip>
        }
      >
        <p
          className={oneLine ? "cut-text-centered" : "cut-text"}
          style={{ height: threeRows ? 60 : 40, marginBottom: 0 }}
        >
          {text}
        </p>
      </OverlayTrigger>
    </div>
  );
};

export default TableCellText;
