import React, { useState, useEffect } from "react";
import "./NewProductPage.scss";
import { NewProduct } from "../../types/NewProduct";
import * as R from "ramda";
import useCreateProduct from "../../hooks/products/useCreateProduct";
import useCreateProductCategory from "../../hooks/products/useCreateProductCategory";
import { Route, useHistory } from "react-router-dom";
import LargeButton from "../../components/LargeButton";
import InputText from "../../components/InputText";
import MediumButton from "../../components/MediumButton";
import { Breakpoint } from "react-socks";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { ProductCategory } from "../../types/ProductCategory";
import { NewProductCategory } from "../../types/NewProductCategory";
import SearchableDropdown from "../../components/SearchableDropdown";
import { DropDownItem } from "../../types/DropDownItem";
import { Col, Row } from "reactstrap";
import MediumButtonLong from "../../components/MediumButtonLong";
import DataTable from "../../components/DataTable";
import { NewSubProduct } from "../../types/NewSubProduct";
import axios from "axios";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions } from "store/store";

const NewProductPage: React.FC = () => {
  const [category, setCategory] = useState<ProductCategory>();
  const [subCategories, setSubCategories] = useState<NewSubProduct[]>([]);
  const [productName, setProductName] = useState("");
  const [articleNumber, setArticleNumber] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [tempCategoryInput, setTempCategoryInput] = useState<string>("");
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState<any>(null);

  const testCustomer: NewProduct = {
    productName: "",
    articleNumber: "",
    productCategory: "",
  };

  const [newProduct, setProduct] = useState<NewProduct>(testCustomer);

  const [showSubCategories, setShowSubCategories] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subArticleNumber, setSubArticleNumber] = useState("");

  const SetLoading = useStoreActions(
    (actions) => actions.status.setAccountPageLoading
  );

  useEffect(() => {
    SetLoading(true);
    fetchCategories();
  }, []);

  useEffect(() => {
    checkForCreatedCategory();
  }, [categories]);

  async function fetchCategories() {
    try {
      const request = await axios.get("productCategories");

      if (request.status === 200) {
        let data = request.data;
        let allCategories = data.map((category: ProductCategory) => {
          return {
            label: category.categoryName,
            value: category.id,
            id: category.id,
          };
        });
        console.log(allCategories);
        let preSelected = allCategories.find(
          (x: DropDownItem) => x.label === tempCategoryInput
        );

        setCategories(allCategories);
        if (preSelected !== null) {
          console.log("preSelected");
          console.log(preSelected);
          setTempCategoryInput("");
          setSelectedCategory(preSelected);
        }
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av Kategorier", true, "error");
    } finally {
      SetLoading(false);
    }
  }

  async function createCategory(category: any) {
    console.log("create category " + category);
    var newCategory: NewProductCategory = {
      categoryName: category,
    };

    try {
      const request = await axios.post("productCategories", newCategory);

      if (request.status === 201) {
        notify("En ny product kategori ble opprettet", true, "success");
        fetchCategories();
      }
    } catch (error) {
      notify("Det oppsto en feil ved opprettelse av kategori", true, "error");
    }
  }

  function checkForCreatedCategory() {
    if (tempCategoryInput !== "") {
      let createdCategory = categories.find(
        (p: any) => p.label === tempCategoryInput
      );
      if (createdCategory) {
        setSelectedCategory(createdCategory);
        setCategory(createdCategory);
        setTempCategoryInput("");
      }
    }
  }

  async function createProduct() {
    const product: NewProduct = {
      productName: productName,
      articleNumber: articleNumber,
      subProducts: subCategories,
    };
    if (!R.isNil(selectedCategory)) {
      product.productCategory = selectedCategory.id;
    }

    try {
      const request = await axios.post("products", product);

      if (request.status === 201) {
        notify("Et nytt product ble opprettet", true, "success");
        setProduct(product);
        setProductName("");
        setArticleNumber("");
        setSelectedCategory(null);
        setSubCategories([]);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opprettelse av produkt", true, "error");
    }
  }

  function tryingToGoBack() {
    if (productName !== "" || articleNumber !== "") {
      setOpen(true);
    } else {
      history.goBack();
    }
  }

  const columns = [
    {
      Header: "Type",
      accessor: "subProductName",
      width: 200,
    },
    {
      Header: "Art. nr.",
      accessor: "articleNumber",
      width: 200,
    },
  ];

  function createSubProduct() {
    if (subArticleNumber !== "" && subCategoryName !== "") {
      const subProduct: NewSubProduct = {
        subProductName: subCategoryName,
        articleNumber: subArticleNumber,
      };
      const newSubProductList = subCategories.concat(subProduct);
      setSubCategories(newSubProductList);
      setSubCategoryName("");
      setSubArticleNumber("");
    }
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row justify-content-center pt-4">
        <div className="d-flex flex-row" style={{ width: 800 }}>
          <h1 style={{ flexGrow: 1, fontWeight: 100, fontSize: 24 }}>
            LEGG TIL NYTT PRODUKT
          </h1>
          <Breakpoint medium up>
            <Route
              render={() => (
                <LargeButton
                  buttonText="Tilbake"
                  onClick={() => tryingToGoBack()}
                />
              )}
            />
          </Breakpoint>
          <Breakpoint medium down>
            <Route
              render={() => (
                <IconButton
                  style={{
                    color: "#906088",
                  }}
                  onClick={() => tryingToGoBack()}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
            />
          </Breakpoint>
        </div>
      </div>
      <div
        className="d-flex grow-1 flex-wrap flex-column m-auto "
        style={{ width: 800 }}
      >
        <div className="d-flex flex-row flex-wrap justify-content-center">
          <div className="new-category-form-select">
            <Row>
              <Col
                lg={3}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SearchableDropdown
                  value={selectedCategory}
                  items={categories}
                  typeText="kategori"
                  onChange={(opt) => {
                    setCategory(opt);
                    setSelectedCategory(opt);
                    setTempCategoryInput(opt.categoryName);
                  }}
                  onCreateOption={(opt2) => createCategory(opt2)}
                  onInputChange={(text) => {
                    if (
                      (tempCategoryInput && tempCategoryInput.length < 2) ||
                      text !== ""
                    ) {
                      setTempCategoryInput(text);
                    }
                  }}
                  inputValue={tempCategoryInput}
                />
              </Col>
              <Col lg={4}>
                <InputText
                  inputTitle="Produktnavn"
                  value={productName}
                  onChange={(e: any) => setProductName(e.target.value)}
                />
              </Col>
              <Col lg={3}>
                <InputText
                  inputTitle="Artikkelnummer"
                  value={articleNumber}
                  onChange={(e: any) => setArticleNumber(e.target.value)}
                />
              </Col>
              <Col lg={2}>
                <div className="create-product-button-container">
                  <MediumButton
                    buttonText="Legg til"
                    onClick={() => createProduct()}
                  />
                </div>
              </Col>
            </Row>

            <div className="show-sub-prorducts-button-container mt-5">
              <MediumButtonLong
                buttonText={
                  showSubCategories ? "Skjul undergrupper" : "Vis undergrupper"
                }
                onClick={() => setShowSubCategories(!showSubCategories)}
              />
            </div>
            <div>
              {showSubCategories && (
                <div className="sub-category-container">
                  {subCategories.length > 0 && (
                    <div>
                      <h3
                        style={{
                          flexGrow: 1,
                          fontWeight: 100,
                          fontSize: 20,
                          padding: 0,
                          marginBottom: 30,
                        }}
                      >
                        UNDERGRUPPER:
                      </h3>

                      <DataTable columns={columns} data={subCategories} />
                    </div>
                  )}
                  <h3
                    style={{
                      flexGrow: 1,
                      fontWeight: 100,
                      fontSize: 20,
                      padding: 0,
                      marginBottom: 0,
                      marginTop: 30,
                    }}
                  >
                    LEGG TIL NY GRUPPE:
                  </h3>
                  <Row>
                    <Col lg={4}>
                      <InputText
                        inputTitle="Navn"
                        value={subCategoryName}
                        onChange={(e: any) =>
                          setSubCategoryName(e.target.value)
                        }
                      />
                    </Col>
                    <Col lg={4}>
                      <InputText
                        inputTitle="Artikkelnummer"
                        value={subArticleNumber}
                        onChange={(e: any) =>
                          setSubArticleNumber(e.target.value)
                        }
                      />
                    </Col>
                    <Col lg={3}>
                      <div className="create-product-button-container">
                        <MediumButton
                          buttonText="legg til"
                          onClick={() => createSubProduct()}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </div>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Produktet er ikke lagret"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Er du sikker på at vil gå tilbake uten å opprette produktet
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Nei
              </Button>
              <Button
                onClick={() => history.push("/products")}
                color="primary"
                autoFocus
              >
                Ja
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default NewProductPage;
