export const TimeFilter : any = [
    { label: "Alle", value: 0, id: 0 },
    { label: "Siste 3 dager", value: 1, id: 1 },
    { label: "Siste uke", value: 2, id: 2 },
    { label: "Siste 2 uker", value: 3, id: 3 },
    { label: "Siste måned", value: 4, id: 4 },
    { label: "Siste 2 måneder", value: 5, id: 5 }
];

export const PayedFilter : any = [
    { label: "Alle", value: 0, id: 0 },
    { label: "Betalt", value: 1, id: 1 },
    { label: "Ikke Betalt", value: 2, id: 2 }
];

export const DeliveredFilter : any = [
    { label: "Alle", value: 0, id: 0 },
    { label: "Levert", value: 1, id: 1 },
    { label: "Ikke Levert", value: 2, id: 2 }
];

