import React, { useState, useEffect } from "react";
import * as R from "ramda";
import "./CustomersOverview.scss";
import { Breakpoint } from "react-socks";
import { Order } from "types/Order";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import OrdersMobile from "pages/my-page/components/orders/OrdersMobile";
import { useHistory } from "react-router-dom";
import { Customer } from "types/Customer";
import CustomersMobile from "pages/customers/customers/CustomersMobile";

type OrdersProps = {
  customers: Customer[];
  onOpen?: any;
  onEdit?: any;
  onDelete?: any;
};

const CustomersOverview: React.FC<OrdersProps> = ({
  customers,
  onOpen,
  onEdit,
  onDelete,
}) => {
  const history = useHistory();
  return (
    <div className="d-flex flex-row flex-grow-1">
      <Breakpoint medium up>
        {customers.map((customer: Customer) => {
          return <div>{customer.firstName}</div>;
        })}
      </Breakpoint>
      <Breakpoint small down>
        <div className="d-flex flex-row justify-content-center w-100 mb-2 mt-4">
          <h2 className="my-auto me-1">Mine kunder</h2>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 3001 }}>
                Opprett ny kunde
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
                fontSize: 20,
              }}
              size="small"
              onClick={() => history.push("/new-customer")}
            >
              <AddIcon fontSize="medium" />
            </IconButton>
          </OverlayTrigger>
        </div>
        {customers.map((customer: Customer, index) => {
          return (
            <div>
              <CustomersMobile
                key={"customer-mobile" + index}
                customer={customer}
                onEdit={onEdit}
                onDelete={onDelete}
                onOpen={onOpen}
              />
            </div>
          );
        })}
      </Breakpoint>
    </div>
  );
};

export default CustomersOverview;
