import { AuthenticationResult } from "@azure/msal-browser";
import React from "react";

const NotificationsPage: React.FC = () => {
  return (
    <div>
      <h1 style={{ flexGrow: 1, fontWeight: 100, fontSize: 24 }}>
        NOTIFIKASJONER
      </h1>
      <h3>Denne siden er under konstruksjon</h3>
    </div>
  );
};

export default NotificationsPage;
