import React, { useState, useEffect } from "react";
import LargeButton from "../../components/LargeButton";
import DataTable from "../../components/DataTable";
import { Customer } from "../../types/Customer";
import { Route, useHistory } from "react-router-dom";
import "./CustomersPage.scss";
import { Breakpoint } from "react-socks";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { CircleLoader } from "react-spinners";
import axios from "axios";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions } from "store/store";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CustomersOverview from "pages/customers/customers/CustomersOverview";

const CustomersPage: React.FC = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [open, setOpen] = useState(false);
  // const { updateCustomerService, updateCustomer } = useUpdateCustomer();
  const history = useHistory();

  const SetLoading = useStoreActions(
    (actions) => actions.status.setAccountPageLoading
  );

  useEffect(() => {
    SetLoading(true);
    fetchCustomers();
  }, []);

  async function fetchCustomers() {
    try {
      const request = await axios.get("customers");

      if (request.status === 200) {
        setCustomers(request.data);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av kunder", true, "error");
    } finally {
      SetLoading(false);
    }
  }

  const updateOptions = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };

  async function updateCustomer(customer: any, id: string) {
    try {
      const request = await axios.patch(
        `customers/${id}`,
        customer,
        updateOptions
      );

      if (request.status === 200) {
        notify("Kunden ble oppdatert", true, "success");
        if (request.data) {
          const changedIndex = customers.findIndex(
            (customer) => customer.id == id
          );
          customers[changedIndex] = request.data;
          if (customer.isDeleted === true) {
            customers.splice(changedIndex, 1);
          }
          setCustomers([...customers]);
        }
      }
    } catch (error) {
      notify("Det oppsto en feil ved oppdatering av kunde", true, "error");
    } finally {
    }
  }

  const columns = [
    {
      Header: "Fornavn",
      accessor: "firstName", // String-based value accessors!
      width: 200,
    },
    {
      Header: "Etternavn",
      accessor: "lastName",
      width: 200,
    },
    {
      Header: "Telefon",
      accessor: "phone", // String-based value accessors!
      width: 200,
    },
    {
      Header: "Adresse",
      accessor: "address", // String-based value accessors!
    },
    {
      accessor: "info",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Se salgs oversikt
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              onClick={() => clickedOnSales(row.original.id)}
            >
              <ShoppingBasket />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "postCode",
      width: 40,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => clickedOnChange(row.original.id)}
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
    {
      accessor: "postArea",
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => clickedOnDelete(row.original)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  function clickedOnDelete(customer: Customer) {
    setSelectedCustomer(customer);
    setOpen(true);
  }

  function deleteCustomer() {
    setOpen(false);
    if (selectedCustomer !== undefined) {
      updateCustomer({ isDeleted: true }, selectedCustomer.id);
    }
  }

  function clickedOnChange(orderId: string) {
    history.push("/edit-customer/" + orderId);
  }

  function clickedOnSales(customerId: string) {
    history.push("/details-customer/" + customerId);
  }

  return (
    <div>
      <div className="customer-header">
        <Route
          render={({ history }) => (
            <LargeButton
              buttonText="Ny Kunde"
              onClick={() => history.push("/new-customer")}
            />
          )}
        />
      </div>
      <Breakpoint medium up>
        <div
          className="d-flex grow-1 flex-wrap flex-column m-auto "
          style={{ width: 800 }}
        >
          <div className="customer-table-header">
            <h1 className="customer-table-header-text">KUNDEOVERSIKT</h1>
          </div>
          <div className="customer-table-container">
            <DataTable columns={columns} data={customers} />
          </div>
        </div>
      </Breakpoint>
      <Breakpoint small down>
        <CustomersOverview
          customers={customers}
          onOpen={clickedOnSales}
          onEdit={clickedOnChange}
          onDelete={clickedOnDelete}
        />
      </Breakpoint>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Advarsel sletter kunde"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Er du sikker på at du vil slette{" "}
            {selectedCustomer
              ? selectedCustomer.firstName + " " + selectedCustomer.lastName
              : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Nei
          </Button>
          <Button onClick={() => deleteCustomer()} color="primary" autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomersPage;
