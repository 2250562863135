import { useMsal } from "@azure/msal-react";
import React from "react";
import { useStoreState } from "store/store";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import Image from "react-bootstrap/Image";
import { handleLogout } from "common/utils/functions";
import ProfileMenuToggle from "./ProfileMenuToggle";
import "./style.scss";
import { IconButton } from "@material-ui/core";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";

const ProfileMenu: React.FC = () => {
  const { instance } = useMsal();

  const userDetails = useStoreState((state) => state.account.user);

  function logout() {
    handleLogout(instance);
  }

  return (
    <Dropdown
      className="align-content-center align-self-center"
      style={{ alignSelf: "center", alignContent: "center" }}
    >
      <Dropdown.Toggle as={ProfileMenuToggle}>
        {userDetails?.profileImage ? (
          <Image
            src={userDetails?.profileImage}
            className="small-profile-picture"
            alt="profile"
          />
        ) : (
          <div className="header-profile-container">
            <IconButton
              style={{
                color: "#906088",
              }}
            >
              <PermIdentityOutlinedIcon />
            </IconButton>
          </div>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="context-dropdown profile-dropdown"
        align={{ lg: "end" }}
      >
        <p
          className="m-0 ui-s ps-sm-3 ps-2"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          Hei {userDetails?.firstName}
        </p>
        <Button onClick={logout}>Logg ut</Button>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileMenu;
