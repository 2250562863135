import React, { useCallback, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { handleLogout } from "common/utils/functions";
import Button from "react-bootstrap/Button";
import "./ProfileSubMenu.scss";

type MenuProps = {
  open: boolean;
  setOpen: any;
};
const ProfileSubMenu: React.FC<MenuProps> = ({ open, setOpen }) => {
  const history = useHistory();
  const { instance } = useMsal();

  function logout() {
    handleLogout(instance);
  }

  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setOpen(!open);
    }
  }, []);

  function redirect(url: string) {
    setOpen(false);
    history.push(url);
  }

  return (
    <>
      {open ? (
        <div className="profile-menu-container">
          {/* <Link to="/products">
            <span className="profile-menu-item">Produktoversikt</span>
          </Link> */}
          <Link
            className="profile-menu-item-link"
            to="/customers"
            onClick={() => {
              setOpen(false);
            }}
          >
            <p className="profile-menu-item">Kunder</p>
          </Link>
          <Button onClick={logout}>Logg ut</Button>
        </div>
      ) : null}
    </>
  );
};

export default ProfileSubMenu;
