import React, { useState, useEffect } from "react";
import { useParams, useHistory, Route } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import "./CustomerDetailsPage.scss";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import InfoIcon from "@material-ui/icons/Info";
import DeleteIcon from "@material-ui/icons/Delete";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { Breakpoint } from "react-socks";
import { useStoreActions } from "store/store";
import { OrderCustomerDetails } from "types/OrderCustomerDetails";
import { notify } from "common/utils/notify/notifyFunction";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Order } from "types/Order";
import CheckBox from "components/CheckBox";
import OrderModal from "pages/orders/OrderModal";
import ReminderModal from "common/components/ReminderModal/ReminderModal";
import ViewReminderModal from "common/components/ViewReminderModal/ViewReminderModal";
import DataTable from "components/DataTable";
import { ReminderDetailed } from "types/ReminderDetailed";
import CustomerOrdersOverview from "pages/customer-details/orders/CustomerOrdersOverview";

const CustomerDetailsPage: React.FC = () => {
  const [name, setName] = useState("");
  const [orders, setOrders] = useState<OrderCustomerDetails[]>([]);
  const [selectedOrderId, setSelectedOrderId] = React.useState("");
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [orderModalOpen, setOrderModalOpen] = React.useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [reminderModalOpen, setReminderModalOpen] = React.useState(false);
  const [openAlertReminderDialog, setOpenAlertReminderDialog] = useState(false);
  const [viewReminderModalOpen, setViewReminderModalOpen] =
    React.useState(false);
  const [reminders, setReminders] = useState<ReminderDetailed[]>([]);
  const [selectedReminder, setSelectedReminder] = useState<any>();
  const [selectedDetailedReminder, setSelectedDetailedReminder] =
    useState<ReminderDetailed>();
  const [reminderLoadData, setReminderLoadData] = useState<string>("none");
  const { id } = useParams<ParamTypes>();
  const history = useHistory();

  interface ParamTypes {
    id: string;
  }

  const SetLoading = useStoreActions(
    (actions) => actions.status.setAccountPageLoading
  );

  useEffect(() => {
    SetLoading(true);
    fetchCustomer();
  }, []);

  async function fetchCustomer() {
    try {
      const request = await axios.get("customers/details/" + id);

      if (request.status === 200) {
        var customer = request.data;
        setName(customer.name);
        setOrders(customer.orders);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av kunde", true, "error");
    } finally {
      SetLoading(false);
    }
  }

  async function fetchOrder(id: string) {
    try {
      const request = await axios.get("orders/" + id);

      if (request.status === 200) {
        setSelectedOrder(request.data);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av ordren", true, "error");
    } finally {
    }
  }

  async function fetchReminders() {
    try {
      const request = await axios.get("productNotifications");

      if (request.status === 200) {
        setReminders(request.data);
        SetLoading(false);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting", true, "error");
    } finally {
    }
  }

  const updateOptions = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };

  async function updateOrder(order: any, id: string) {
    try {
      const request = await axios.patch(`orders/${id}`, order, updateOptions);

      if (request.status === 200) {
        const changedIndex = orders.findIndex((order) => order.id == id);
        orders[changedIndex] = request.data;
        if (order.isDeleted === true) {
          orders.splice(changedIndex, 1);
        }
        setOrders([...orders]);
      }
    } catch (error) {
      notify("Det oppsto en feil ved oppdatering av ordre", true, "error");
    } finally {
    }
  }

  function clickedOnOpenReminderModal(order: Order) {
    setSelectedOrderId(order.id);
    fetchOrder(order.id);
    setReminderModalOpen(true);
  }

  function clickedOnPaid(orderId: string, checked: boolean) {
    updateOrder({ paid: checked }, orderId);
  }

  function clickedOnDelete(order: Order) {
    setSelectedOrder(order);
    setOpenAlertDialog(true);
  }

  function clickedOnOpenOrder(order: Order) {
    setSelectedOrderId(order.id);
    fetchOrder(order.id);
    setOrderModalOpen(true);
  }

  function deleteOrder() {
    setOpenAlertDialog(false);
    if (selectedOrder !== undefined) {
      updateOrder({ isDeleted: true }, selectedOrder.id);
    }
  }

  function clickedOnDelivered(orderId: any, checked: boolean) {
    updateOrder({ delivered: checked }, orderId);
  }

  function clickedOnParitialyDelivered(orderId: any, checked: boolean) {
    updateOrder({ partialyDelivered: checked }, orderId);
  }

  function clickedOnOrdered(orderId: string, checked: boolean) {
    updateOrder({ orderedFromSupplier: checked }, orderId);
  }

  function clickedOnChange(orderId: string) {
    history.push("/edit-order/" + orderId);
  }

  const closeReminderModal = () => {
    setReminderModalOpen(false);
  };

  function clickedOnEditCustomer() {
    history.push("/edit-customer/" + id);
  }

  async function deleteReminder() {
    setOpenAlertReminderDialog(false);
    if (selectedReminder !== undefined) {
      try {
        const request = await axios.delete(
          `productNotifications/${selectedReminder.id}`
        );

        if (request.status === 200 || request.status === 204) {
          notify("En påminnelse ble slettet", true, "success");
          let newReminderList = reminders.filter(
            (p) => p.id != selectedReminder.id
          );
          setReminders(newReminderList);
        }
      } catch (error) {
        notify("Det oppsto en feil ved sletting av påminnelse", true, "error");
      } finally {
      }
    }
  }

  function tryingToGoBack() {
    history.goBack();
  }

  const orderColumns = [
    {
      Header: "Dato",
      accessor: "createdDate", // String-based value accessors!
      Cell: ({ row }: any) => {
        let date = new Date(row.original.createdDate);
        return (
          <p onClick={(e: any) => clickedOnOpenOrder(row.original)}>
            {date.getDate()}/{date.getMonth() + 1} {date.getFullYear()}
          </p>
        );
      },
    },
    {
      Header: "Pris",
      accessor: "totalPrice", // String-based value accessors!
      Cell: ({ row }: any) => {
        return <p>{row.original.totalPrice}</p>;
      },
    },
    {
      Header: "Bestilt",
      accessor: "orderedFromSupplier", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.orderedFromSupplier}
            onChange={(e: any) => {
              clickedOnOrdered(row.original.id, e.target.checked);
              row.original.orderedFromSupplier = e.target.checked;
            }}
          />
        );
      },
    },
    {
      Header: "Betalt",
      accessor: "paid", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.paid}
            onChange={(e: any) => {
              clickedOnPaid(row.original.id, e.target.checked);
              row.original.paid = e.target.checked;
            }}
          />
        );
      },
    },
    {
      Header: "Levert",
      accessor: "delivered", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.delivered}
            onChange={(e: any) => {
              clickedOnDelivered(row.original.id, e.target.checked);
              // console.log(e.target.checked);
              // row.original.delivered = e.target.checked;
              // console.log(row);
            }}
          />
        );
      },
    },
    {
      Header: "Delvis Levert",
      accessor: "partialyDelivered", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 3001 }}>
                Trykk på rediger for å velge hva som er levert
              </Tooltip>
            }
          >
            <div>
              <CheckBox
                checked={row.original.partialyDelivered}
                onChange={(e: any) => {
                  clickedOnParitialyDelivered(
                    row.original.id,
                    e.target.checked
                  );
                  row.original.delivered = e.target.checked;
                }}
              />
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "notes",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Bestillingsliste
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              onClick={() => clickedOnOpenOrder(row.original)}
            >
              <InfoIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "reminder",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Påminnelse
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              onClick={() => clickedOnOpenReminderModal(row.original)}
            >
              <NotificationsNoneIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "count",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Rediger kunde
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              onClick={() => clickedOnChange(row.original.id)}
            >
              <EditIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "system",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Slett
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              onClick={() => clickedOnDelete(row.original)}
            >
              <DeleteIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
  ];

  return (
    <div className="d-flex flex-row justify-content-center pt-4">
      <div className="d-flex flex-column">
        <Breakpoint medium up>
          <div className="customer-details-header-container">
            <h1 className="customer-details-header-text">
              Salgsoversikt for: <strong>{name}</strong>
            </h1>
            <div className="d-flex flex-row flex-grow-1">
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                    Rediger kunde
                  </Tooltip>
                }
              >
                <IconButton
                  style={{
                    color: "#906088",
                  }}
                  onClick={() => clickedOnEditCustomer()}
                >
                  <EditIcon />
                </IconButton>
              </OverlayTrigger>
            </div>
            <Route
              render={() => (
                <IconButton
                  style={{
                    color: "#906088",
                    cursor: "pointer",
                    fontSize: 40,
                  }}
                  onClick={() => tryingToGoBack()}
                >
                  <ArrowBackIcon fontSize="inherit" />
                </IconButton>
              )}
            />
          </div>
        </Breakpoint>
        <Breakpoint small down>
          <div className="orders-table-header ms-3">
            <div className="d-flex flex-column">
              <h1 className="orders-table-header-text">Salgsoversikt for:</h1>
              <div className="d-flex flex-row align-content-center">
                <h1 className="orders-table-header-text mb-0 me-2 mt-2">
                  <strong>{name}</strong>
                </h1>
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                      Rediger kunde
                    </Tooltip>
                  }
                >
                  <IconButton
                    style={{
                      color: "#906088",
                    }}
                    onClick={() => clickedOnEditCustomer()}
                  >
                    <EditIcon />
                  </IconButton>
                </OverlayTrigger>
              </div>
            </div>
            <div className="d-flex flex-row flex-grow-1 justify-content-end">
              <Route
                render={() => (
                  <IconButton
                    style={{
                      color: "#906088",
                      cursor: "pointer",
                      fontSize: 30,
                    }}
                    onClick={() => tryingToGoBack()}
                  >
                    <ArrowBackIcon fontSize="inherit" />
                  </IconButton>
                )}
              />
            </div>
          </div>
        </Breakpoint>{" "}
        <Breakpoint small down>
          <CustomerOrdersOverview
            orders={orders}
            clickedOnPaid={clickedOnPaid}
            clickedOnDelivered={clickedOnDelivered}
            clickedOnOrdered={clickedOnOrdered}
            onOpenOrder={clickedOnOpenOrder}
            onOpenReminder={clickedOnOpenReminderModal}
            onEdit={clickedOnChange}
            onDelete={clickedOnDelete}
            customerId={id}
          />
        </Breakpoint>
        <Breakpoint medium up>
          {orders?.length > 0 && (
            <div
              className="d-flex grow-1 flex-wrap flex-column m-auto mt-5 "
              style={{ maxWidth: 800 }}
            >
              {" "}
              <div className="d-flex flex-row content-align-center">
                <h4 className="my-auto me-1">Nyeste salg</h4>
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="customer-tooltip" style={{ zIndex: 3001 }}>
                      Opprett nytt salg
                    </Tooltip>
                  }
                >
                  <IconButton
                    style={{
                      color: "#906088",
                      fontSize: 20,
                    }}
                    size="small"
                    onClick={() => history.push("/new-order/" + id)}
                  >
                    <AddIcon fontSize="medium" />
                  </IconButton>
                </OverlayTrigger>
              </div>
              <Breakpoint medium up>
                <DataTable columns={orderColumns} data={orders} />
              </Breakpoint>
            </div>
          )}
        </Breakpoint>
        <OrderModal
          id={selectedOrderId}
          open={orderModalOpen}
          onClose={() => {
            setOrderModalOpen(false);
            fetchReminders();
          }}
          order={selectedOrder}
          onEdit={clickedOnChange}
        />
        <ReminderModal
          id={selectedOrderId}
          open={reminderModalOpen}
          onClose={closeReminderModal}
          preSelectedOrder={selectedOrder}
          preSelectedReminder={selectedDetailedReminder}
          onCreate={() => fetchReminders()}
          loadData={reminderLoadData}
        />
        <ViewReminderModal
          onClose={() => {
            fetchReminders();
            setViewReminderModalOpen(false);
          }}
          open={viewReminderModalOpen}
          detailedReminder={selectedDetailedReminder}
        />
        <Dialog
          open={openAlertDialog}
          onClose={() => setOpenAlertDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Advarsel sletter salg"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Er du sikker på at du vil slette dette salget fra{" "}
              {selectedOrder
                ? selectedOrder.customer.firstName +
                  " " +
                  selectedOrder.customer.lastName
                : ""}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenAlertDialog(false)} color="primary">
              Nei
            </Button>
            <Button onClick={() => deleteOrder()} color="primary" autoFocus>
              Ja
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openAlertReminderDialog}
          onClose={() => setOpenAlertReminderDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Advarsel sletter påminnelse"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Er du sikker på at du vil slette denne påminnelsen av{" "}
              {selectedReminder
                ? selectedReminder.product.productName +
                  " til " +
                  selectedReminder.customer.firstName +
                  " " +
                  selectedReminder.customer.lastName
                : ""}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenAlertReminderDialog(false)}
              color="primary"
            >
              Nei
            </Button>
            <Button onClick={() => deleteReminder()} color="primary" autoFocus>
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default CustomerDetailsPage;
