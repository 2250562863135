import React, { useEffect, useState } from "react";
import MediumButton from "../../components/MediumButton";
import InputText from "../../components/InputText";
import "./NewCustomerPage.scss";
import { NewCustomer } from "../../types/NewCustomer";
import { Route, useHistory } from "react-router-dom";
import LargeButton from "../../components/LargeButton";
import { Breakpoint } from "react-socks";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions } from "store/store";

const NewCustomerPage: React.FC = () => {
  const [forename, setForename] = useState("");
  const [lastName, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [otherInfo, setOtherInfo] = useState("");
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const SetLoading = useStoreActions(
    (actions) => actions.status.setAccountPageLoading
  );

  useEffect(() => {
    SetLoading(false);
  }, []);

  async function createCustomer() {
    console.log("create customer");
    console.log(forename);
    const customer: NewCustomer = {
      customerType: 1,
      firstName: forename.trim(),
      lastName: lastName.trim(),
      email: email.trim(),
      address: address.trim(),
      address2: "",
      postCode: "",
      postArea: "",
      otherInfo: otherInfo.trim(),
      phone: phone.trim(),
    };

    try {
      const request = await axios.post("customers", customer);

      if (request.status === 201) {
        notify("En ny kunde ble opprettet", true, "success");
        setForename("");
        setLastname("");
        setPhone("");
        setEmail("");
        setAddress("");
        setOtherInfo("");
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av kunder", true, "error");
    }
  }
  function tryingToGoBack() {
    if (
      forename !== "" ||
      lastName !== "" ||
      phone !== "" ||
      email !== "" ||
      address !== "" ||
      otherInfo !== ""
    ) {
      setOpen(true);
    } else {
      history.goBack();
    }
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row justify-content-center pt-4">
        <div className="d-flex flex-row ms-2" style={{ width: 800 }}>
          <h1 style={{ flexGrow: 1, fontWeight: 100, fontSize: 24 }}>
            Legg til ny kunde
          </h1>
          <Breakpoint medium up>
            <Route
              render={() => (
                <LargeButton
                  buttonText="Tilbake"
                  onClick={() => tryingToGoBack()}
                />
              )}
            />
          </Breakpoint>
          <Breakpoint medium down>
            <Route
              render={() => (
                <IconButton
                  style={{
                    color: "#906088",
                  }}
                  onClick={() => tryingToGoBack()}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
            />
          </Breakpoint>
        </div>
      </div>
      <div className="d-flex grow-1 flex-wrap flex-column m-auto new-customer-form">
        <div className="d-flex flex-row flex-wrap justify-content-center">
          <div className="new-customer-form-input">
            <InputText
              inputTitle="Fornavn"
              value={forename}
              onChange={(e: any) => setForename(e.target.value)}
            />
          </div>
          <div className="new-customer-form-input">
            <InputText
              inputTitle="Etternavn"
              value={lastName}
              onChange={(e: any) => setLastname(e.target.value)}
            />
          </div>
          <div className="new-customer-form-input">
            <InputText
              inputTitle="Telefon"
              value={phone}
              onChange={(e: any) => setPhone(e.target.value)}
            />
          </div>
          <div className="new-customer-form-input">
            <InputText
              inputTitle="Epost"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </div>
          <div className="new-customer-form-input">
            <InputText
              inputTitle="Adresse"
              value={address}
              onChange={(e: any) => setAddress(e.target.value)}
            />
          </div>
          <div className="new-customer-form-input">
            <InputText
              inputTitle="Mer info"
              value={otherInfo}
              onChange={(e: any) => setOtherInfo(e.target.value)}
            />
          </div>
        </div>
        <div className="create-customer-button-container">
          <MediumButton
            buttonText="Legg til"
            onClick={() => createCustomer()}
          />
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Kunden er ikke lagret"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Er du sikker på at vil gå tilbake uten å opprette kunden
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Nei
          </Button>
          <Button
            onClick={() => history.push("/customers")}
            color="primary"
            autoFocus
          >
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewCustomerPage;
