import React, { useCallback, useMemo } from "react";
import CreatableSelect from "react-select/creatable";
import "./style.scss";

type SearchableDropdownProps = {
  items: DropdownItems[];
  typeText: string;
  width?: number;
  onChange: (event: any) => void;
  onCreateOption?: (event: any) => void;
  onInputChange?: (event: any) => void;
  value: any;
  inputValue?: any;
  noFilter?: boolean;
};
type DropdownItems = {
  label: string;
  value: number;
  id: string;
};

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  items,
  typeText,
  onChange,
  onCreateOption,
  onInputChange,
  inputValue,
  value,
  width = 175,
  noFilter = false,
}) => {
  const customStyles = useMemo(
    () => ({
      container: (provided: any) => ({
        ...provided,
      }),
      option: (provided: any, state: any) => ({
        ...provided,
        background: state.isFocused
          ? "#F0C0E8"
          : state.isSelected
          ? "#906088"
          : "white",

        color: "black",
        opacity: 0.8,
        padding: 20,
      }),
    }),
    []
  );
  const customFilter = useCallback((option, searchText) => {
    if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }
    return false;
  }, []);

  const emptyFilter = useCallback((candidate, input) => {
    return true; // if not search, then all match
  }, []);

  return (
    <div className="dropdown-container" style={{ width }}>
      <CreatableSelect
        value={value}
        escapeClearsValue={false}
        options={items}
        onChange={onChange}
        onInputChange={onInputChange}
        onCreateOption={onCreateOption}
        filterOption={noFilter ? emptyFilter : customFilter}
        inputValue={inputValue}
        formatCreateLabel={(userInput) => `Opprett ${typeText} ${userInput}`}
        placeholder={"Velg " + typeText}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,

            primary25: "#deb6b7",
            primary75: "#deb6b7",
            primary50: "#deb6b7",
            primary: "#906088",
          },
        })}
      />
    </div>
  );
};

export default SearchableDropdown;
