import React, { useState } from "react";
import "./NewProductCategoryPage.scss";
import { NewProductCategory } from "../../types/NewProductCategory";
import useCreateProductCategory from "../../hooks/products/useCreateProductCategory";
import { Route, useHistory } from "react-router-dom";
import LargeButton from "../../components/LargeButton";
import InputText from "../../components/InputText";
import MediumButton from "../../components/MediumButton";
import { Breakpoint } from "react-socks";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { AuthenticationResult } from "@azure/msal-browser";
import { notify } from "common/utils/notify/notifyFunction";
import axios from "axios";

const NewProductCategoryPage: React.FC = () => {
  const [categoryName, setCategoryName] = useState("");
  const [articleNumber, setArticleNumber] = useState("");
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const testCategory: NewProductCategory = {
    categoryName: "",
  };

  const [newCategory, setCategory] = useState<NewProductCategory>(testCategory);

  async function createCategory() {
    console.log("create category " + categoryName);
    var newCategory: NewProductCategory = {
      categoryName: categoryName,
    };

    try {
      const request = await axios.post("productCategories", newCategory);

      if (request.status === 201) {
        notify("En ny product kategori ble opprettet", true, "success");
        setCategory(request.data);
        setCategoryName("");
        setArticleNumber("");
      }
    } catch (error) {
      notify("Det oppsto en feil ved opprettelse av kategori", true, "error");
    }
  }

  function tryingToGoBack() {
    if (categoryName !== "" || articleNumber !== "") {
      setOpen(true);
    } else {
      history.goBack();
    }
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row justify-content-center pt-4">
        <div className="d-flex flex-row" style={{ width: 800 }}>
          <h1 style={{ flexGrow: 1, fontWeight: 100, fontSize: 24 }}>
            LEGG TIL NY PRODUKTKATEGORI
          </h1>
          <Breakpoint medium up>
            <Route
              render={() => (
                <LargeButton
                  buttonText="Tilbake"
                  onClick={() => tryingToGoBack()}
                />
              )}
            />
          </Breakpoint>
          <Breakpoint medium down>
            <Route
              render={() => (
                <IconButton
                  style={{
                    color: "#906088",
                  }}
                  onClick={() => tryingToGoBack()}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
            />
          </Breakpoint>
        </div>
      </div>
      <div
        className="d-flex grow-1 flex-wrap flex-column m-auto "
        style={{ width: 800 }}
      >
        <div className="d-flex flex-row flex-wrap justify-content-center">
          <div className="new-product-form-input">
            <InputText
              inputTitle="kategori"
              value={categoryName}
              onChange={(e: any) => setCategoryName(e.target.value)}
            />
          </div>
          <div className="create-product-button-container">
            <MediumButton
              buttonText="Legg til"
              onClick={() => createCategory()}
            />
          </div>
        </div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Kategorien er ikke lagret"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Er du sikker på at vil gå tilbake uten å opprette kategorien
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Nei
            </Button>
            <Button
              onClick={() => history.push("/products")}
              color="primary"
              autoFocus
            >
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default NewProductCategoryPage;
