import React, { forwardRef } from "react";

type ToggleProps = {
  children: JSX.Element;
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const ProfileMenuToggle = forwardRef<HTMLAnchorElement, ToggleProps>(
  ({ children, onClick }, ref) => (
    <a
      href="/#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="p-4" style={{ cursor: "pointer" }}>
        {children}
      </div>
    </a>
  )
);

export default ProfileMenuToggle;
