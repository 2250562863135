import React, { useState, useEffect } from "react";
import "./OrdersMobile.scss";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";

import { Order } from "types/Order";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import CheckBox from "components/CheckBox";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
type OrdersMobileProps = {
  order: Order;
  clickedOnPaid?: any;
  clickedOnDelivered?: any;
  clickedOnOrdered?: any;
  onOpenOrder?: any;
  onOpenReminder?: any;
  onEdit?: any;
  onDelete?: any;
};

const OrdersMobile: React.FC<OrdersMobileProps> = ({
  order,
  clickedOnPaid,
  clickedOnDelivered,
  clickedOnOrdered,
  onOpenOrder,
  onOpenReminder,
  onEdit,
  onDelete,
}) => {
  const history = useHistory();
  function dateData() {
    const date = new Date(order.createdDate);
    return (
      <div className="me-2">
        {/* order-mobile-date-container */}
        <strong>
          <div>
            {date.getDate()}/{date.getMonth() + 1}
          </div>
        </strong>
      </div>
    );
  }
  function clickedOnCustomerSales(customerId: string) {
    history.push("/details-customer/" + customerId);
  }
  return (
    <div className="order-mobile-row">
      <div className="order-mobile-section flex-grow-1">
        <div className="d-flex flex-column flex-grow-1 ms-3 ">
          <div className="d-flex flex-row flex-grow-1 align-content-center fs-3">
            {dateData()}

            <strong>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => clickedOnCustomerSales(order.customer.id)}
              >
                {order.customer.firstName} {order.customer.lastName}
              </div>
            </strong>
          </div>
          <div className="d-flex flex-row flex-grow-1 mt-2">
            <div className="order-checkbox me-3">
              <h3 style={{ fontWeight: 600, fontSize: 16, marginBottom: 0 }}>
                Bestilt
              </h3>
              <CheckBox
                checked={order.orderedFromSupplier}
                onChange={(e: any) =>
                  clickedOnOrdered(order.id, e.target.checked)
                }
              />
            </div>
            <div className="order-checkbox me-3">
              <h3 style={{ fontWeight: 600, fontSize: 16, marginBottom: 0 }}>
                Levert
              </h3>
              <CheckBox
                checked={order.delivered}
                onChange={(e: any) =>
                  clickedOnDelivered(order.id, e.target.checked)
                }
              />
            </div>
            <div className="order-checkbox">
              <h3 style={{ fontWeight: 600, fontSize: 16, marginBottom: 0 }}>
                Betalt
              </h3>
              <CheckBox
                checked={order.paid}
                onChange={(e: any) => clickedOnPaid(order.id, e.target.checked)}
              />
            </div>
          </div>
        </div>
        <div className="order-mobile-section-content" style={{ width: 120 }}>
          <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-row flex-grow-1 mb-2">
              <div className="me-2">
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                      Se info
                    </Tooltip>
                  }
                >
                  <IconButton
                    style={{
                      color: "#906088",
                    }}
                    onClick={() => onOpenOrder(order)}
                  >
                    <InfoIcon />
                  </IconButton>
                </OverlayTrigger>
              </div>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                    Påminnelse
                  </Tooltip>
                }
              >
                <IconButton
                  style={{
                    color: "#906088",
                  }}
                  onClick={() => onOpenReminder(order)}
                >
                  <NotificationsNoneIcon />
                </IconButton>
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-row flex-grow-1 mb-2">
              <div className="me-2">
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                      Rediger salg
                    </Tooltip>
                  }
                >
                  <IconButton
                    style={{
                      color: "#906088",
                    }}
                    onClick={() => onEdit(order.id)}
                  >
                    <EditIcon />
                  </IconButton>
                </OverlayTrigger>
              </div>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={
                  <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                    Slett
                  </Tooltip>
                }
              >
                <IconButton
                  style={{
                    color: "#906088",
                  }}
                  onClick={() => onDelete(order)}
                >
                  <DeleteIcon />
                </IconButton>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersMobile;
