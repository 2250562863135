import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { NewOrder } from "../../types/NewOrder";
import { NewOrderPost } from "../../types/NewOrderPost";
import useCreateOrder from "../../hooks/orders/useCreateOrder";
import InputText from "../../components/InputText";
import MediumButton from "../../components/MediumButton";
import SearchableDropdown from "../../components/SearchableDropdown";
import AddIcon from "@material-ui/icons/Add";
import { Customer } from "../../types/Customer";
import { Product } from "../../types/Product";
import { Order } from "../../types/Order";
import CheckBox from "../../components/CheckBox";
import "./EditOrderPage.scss";
import LargeButton from "../../components/LargeButton";
import { Route, useParams, useHistory } from "react-router-dom";
import { NewProduct } from "../../types/NewProduct";
import { Breakpoint } from "react-socks";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import DataTable from "../../components/DataTable";
import { NewOrderItem } from "../../types/NewOrderItem";
import { UpdateOrder } from "../../types/UpdateOrder";
import { SubProduct } from "../../types/SubProduct";
import { NewSubProduct } from "../../types/NewSubProduct";
import { OrderPost } from "../../types/OrderPost";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { notify } from "common/utils/notify/notifyFunction";
import { Col, Row } from "reactstrap";
import EditableCell from "common/components/EditableCell/EditableCell";
import TableCellText from "common/components/TableCellText/TableCellText";
import { SubProductShort } from "types/SubProductShort";
import { ProductShort } from "types/ProductShort";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import DiamondIcon from "@mui/icons-material/Diamond";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useStoreActions } from "store/store";
import TextArea from "common/components/TextArea/TextArea";

const EditOrderPage: React.FC = () => {
  const [waitingForAPI, setWaitingForAPI] = useState<boolean>(false);
  const [addSubProducts, setAddSubProducts] = useState<boolean>(false);
  const [tempSubProducts, setTempSubProducts] = useState<NewSubProduct[]>([]);
  const [tempSubProductName, setTempSubProductName] = useState("");
  const [tempSubProductArticleNumber, setTempSubProductArticleNumber] =
    useState("");

  const [customer, setCustomer] = useState<Customer>();
  const [product, setProduct] = useState<ProductShort>();
  const [subProduct, setSubProduct] = useState<SubProductShort>();
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [selectedSubProduct, setSelectedSubProduct] = useState<any>();
  const [tempCustomerInput, setTempCustomerInput] = useState<string>("");
  const [tempProductInput, setTempProductInput] = useState<string>("");
  const [tempSubProductInput, setTempSubProductInput] = useState<string>("");
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const [count, setCount] = useState<number>(1);
  const [price, setPrice] = useState<number>(0);
  const [catalog, setCatalog] = useState<number>(0);
  const [delivered, setDelivered] = useState(false);
  const [partialyDelivered, setPartialyDelivered] = useState(false);
  const [paid, setPaid] = useState(false);
  const [orderedFromSupplier, setOrderedFromSupplier] = useState(false);
  const [orderId, setOrderId] = useState<any>();

  const [products, setProducts] = useState<any>(null);
  const [productList, setProductList] = useState<NewOrderPost[]>([]);
  const [sortedSubProducts, setSortedSubProducts] = useState<any>(null);
  const [subProductList, setSubProductList] = useState<SubProductShort[]>([]);

  const [totalPrice, setTotalPrice] = React.useState<number>(0);
  const { id } = useParams<ParamTypes>();
  const [allProducts, setAllProducts] = useState<ProductShort[]>([]);
  const [articleNumber, setArticleNumber] = useState<string>("");
  const [openArticleDialog, setOpenArticleDialog] = useState<boolean>(false);
  const [openSubProductDialog, setOpenSubProductDialog] =
    useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [lastProductSearch, setLastProductSearch] = useState("");
  const [preventSearch, setPreventSearch] = useState(false);
  const history = useHistory();

  interface ParamTypes {
    id: string;
  }

  const SetLoading = useStoreActions(
    (actions) => actions.status.setAccountPageLoading
  );

  useEffect(() => {
    SetLoading(true);
    fetchProducts();
  }, []);

  useEffect(() => {
    fetchOrder();
  }, []);

  useEffect(() => {
    checkForCreatedProduct();
  }, [products]);

  useEffect(() => {
    reCalculateTotalPrice();
  }, [productList]);

  function getProductDropdownText(product: ProductShort) {
    if (product.articleNumber == "" && R.isEmpty(product.subProducts)) {
      return product.name;
    }
    if (R.isEmpty(product.subProducts)) {
      return product.name + " (" + product.articleNumber + ")";
    }
    let text = product.name + " (";
    product.subProducts.forEach((subProduct, index) => {
      if (index == 0) {
        text += subProduct.articleNumber;
      } else {
        text += ", " + subProduct.articleNumber;
      }
    });
    return text + ")";
  }

  async function fetchProducts() {
    try {
      let encodedInput = encodeURIComponent(tempProductInput);
      const request = await axios.get("products" + "?query=" + encodedInput);
      setLastProductSearch(tempProductInput);

      if (request.status === 200) {
        let allProducts = request.data.products.map((product: ProductShort) => {
          return {
            label: getProductDropdownText(product),
            value: product.id,
            id: product.id,
          };
        });
        setAllProducts(request.data.products);
        setProducts(allProducts);

        setSubProductList(request.data.subProducts);
        if (product) {
          sortSubProductsFromInput(request.data.subProducts, product.id);
        }
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av produkter", true, "error");
    } finally {
    }
  }

  useEffect(() => {
    if (preventSearch) {
      setPreventSearch(false);
      return;
    }
    if (tempProductInput?.length >= 3 || lastProductSearch?.length >= 3) {
      // if (localValue != value) {
      let timer = setTimeout(() => {
        fetchProducts();
      }, 800);

      return () => clearTimeout(timer);
    }
  }, [tempProductInput]);

  async function checkForCreatedProduct() {
    if (tempProductInput !== "") {
      let createdProduct = allProducts.find(
        (p: any) => p.name === tempProductInput
      );
      if (createdProduct) {
        let dropDownItem = products.find(
          (c: any) => c.id === createdProduct?.id
        );
        setSelectedProduct(dropDownItem);
        setProduct(createdProduct);
        setSelectedSubProduct(null);
        setSelectedSubProduct(null);
        sortSubProductsFromInput(createdProduct.subProducts, createdProduct.id);

        setTempProductInput("");
      }
    }
  }

  async function sortSubProducts(productId: string) {
    const sortedList = subProductList.filter((s) => s.productId === productId);
    if (sortedList.length > 0) {
      let allSubProducts = sortedList.map((subProduct: SubProductShort) => {
        if (subProduct.articleNumber) {
          return {
            label: subProduct.name + " (" + subProduct.articleNumber + ")",
            value: subProduct.id,
            id: subProduct.id,
          };
        } else {
          return {
            label: subProduct.name,
            value: subProduct.id,
            id: subProduct.id,
          };
        }
      });
      setSortedSubProducts(allSubProducts);
    } else {
      setSortedSubProducts(null);
    }
  }

  function createTempSubProduct() {
    if (tempSubProductName !== "" && tempSubProductArticleNumber !== "") {
      const subProduct: NewSubProduct = {
        subProductName: tempSubProductName,
        articleNumber: tempSubProductArticleNumber,
      };

      const newSubProductList = tempSubProducts.concat(subProduct);
      setTempSubProducts(newSubProductList);
      setTempSubProductName("");
      setTempSubProductArticleNumber("");
    }
  }

  async function sortSubProductsFromInput(
    subProductList: SubProductShort[],
    productId: string
  ) {
    const sortedList = subProductList.filter((s) => s.productId === productId);
    if (sortedList.length > 0) {
      let allSubProducts = sortedList.map((subProduct: SubProductShort) => {
        if (subProduct.articleNumber) {
          return {
            label: subProduct.name + " (" + subProduct.articleNumber + ")",
            value: subProduct.id,
            id: subProduct.id,
          };
        } else {
          return {
            label: subProduct.name,
            value: subProduct.id,
            id: subProduct.id,
          };
        }
      });
      setSortedSubProducts(allSubProducts);

      if (tempSubProductInput !== "") {
        let createdSubProduct = sortedList.find(
          (p: any) => p.name === tempSubProductInput
        );
        if (createdSubProduct) {
          let dropDownItem = allSubProducts.find(
            (c: any) => c.id === createdSubProduct?.id
          );
          setSelectedSubProduct(dropDownItem);
          setSubProduct(createdSubProduct);
        }
      }
    } else {
      setSortedSubProducts(null);
    }
    setTempSubProductInput("");
  }

  async function fetchOrder() {
    try {
      const request = await axios.get("orders/" + id);

      if (request.status === 200) {
        let data: Order = await request.data;
        if (data !== null) {
          setOrderId(data.id);
          setCustomer(data.customer);
          setCatalog(data.catalog);
          setDelivered(data.delivered);
          setPartialyDelivered(data.partialyDelivered);
          setPaid(data.paid);
          setOrderedFromSupplier(data.orderedFromSupplier);
          setSelectedCustomer(
            "" + data.customer.firstName + " " + data.customer.lastName
          );
          if (!R.isNil(data.notes)) {
            console.log(data.notes);
            setNotes(data.notes);
            setShowNotes(true);
          }

          if (data.orderPosts !== null) {
            setProductList(
              data.orderPosts.map((x) => {
                return {
                  productId: x.product.id,
                  subProductId: "",
                  count: x.count,
                  name: x.product.productName,
                  subProductName: x.subProduct?.subProductName,
                  price: x.price,
                  delivered: x.delivered,
                  id: x.id,
                  articleNumber: x.subProduct
                    ? x.subProduct.articleNumber
                    : x.product.articleNumber,
                };
              })
            );
          }

          setTotalPrice(data.totalPrice);
        }
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av orderen", true, "error");
    } finally {
      SetLoading(false);
    }
  }

  const columns = [
    {
      Header: "Produkt",
      accessor: "name",
      width: 220,
      Cell: ({ row }: any) => {
        var text = row.original.name;
        return <TableCellText text={text} width={200} />;
      },
    },
    {
      Header: "Type",
      accessor: "subProductName",
      width: 120,
    },
    {
      Header: "Art.nr",
      accessor: "articleNumber",
      width: 60,
    },
    {
      Header: "Antall",
      accessor: "count",
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <div className="d-flex pb-2" style={{ maxWidth: 45 }}>
            <EditableCell
              index={row.original.id}
              value={row.original.count}
              onChange={setCountProduct}
            />
          </div>
        );
      },
    },
    {
      Header: "Pris",
      accessor: "price", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <div className="d-flex pb-2" style={{ maxWidth: 50 }}>
            <EditableCell
              index={row.original.id}
              value={row.original.price}
              onChange={setPriceProduct}
            />
          </div>
        );
      },
    },
    {
      Header: "Total",
      accessor: "total", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <p style={{ margin: 0 }}>
            {row.original.price * row.original.count} kr
          </p>
        );
      },
    },
    {
      accessor: "system",
      width: 60,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => deleteSelectedOrderItem(row.original)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  const columnsWithDelivered = [
    {
      Header: "Produkt",
      accessor: "name",
      width: 200,
      Cell: ({ row }: any) => {
        var text = row.original.name;
        return <TableCellText text={text} width={200} />;
      },
    },
    {
      Header: "Type",
      accessor: "subProductName",
      width: 120,
    },
    {
      Header: "Art.nr",
      accessor: "articleNumber",
      width: 60,
    },
    {
      Header: "Antall",
      accessor: "count",
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <div className="d-flex pb-2" style={{ maxWidth: 45 }}>
            <EditableCell
              index={row.original.id}
              value={row.original.count}
              onChange={setCountProduct}
            />
          </div>
        );
      },
    },
    {
      Header: "Pris",
      accessor: "price", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <div className="d-flex pb-2" style={{ maxWidth: 50 }}>
            <EditableCell
              index={row.original.id}
              value={row.original.price}
              onChange={setPriceProduct}
            />
          </div>
        );
      },
    },
    {
      Header: "Total",
      accessor: "total", // String-based value accessors!
      width: 70,
      Cell: ({ row }: any) => {
        return (
          <p style={{ margin: 0 }}>
            {row.original.price * row.original.count} kr
          </p>
        );
      },
    },
    {
      Header: "Levert",
      accessor: "delivered", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.delivered}
            onChange={(e: any) => {
              clickedDelivered(row.original.id, e.target.checked);
              row.original.paid = e.target.checked;
            }}
          />
        );
      },
    },
    {
      accessor: "system",
      width: 60,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => deleteSelectedOrderItem(row.original)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  const columnsMobile = [
    {
      Header: "Produkt",
      accessor: "name",
      width: 150,
      Cell: ({ row }: any) => {
        var text =
          row.original.name +
          (row.original.subProductName
            ? `, ${row.original.subProductName}`
            : "");
        return <TableCellText text={text} width={130} />;
      },
    },
    {
      Header: "Antall",
      accessor: "count",
      width: 60,
      Cell: ({ row }: any) => {
        return (
          <div className="d-flex pb-2 ps-1" style={{ maxWidth: 40 }}>
            <EditableCell
              index={row.original.id}
              value={row.original.count}
              onChange={setCountProduct}
            />
          </div>
        );
      },
    },
    {
      Header: "Pris",
      accessor: "price", // String-based value accessors!
      width: 55,
      Cell: ({ row }: any) => {
        return (
          <div className="d-flex pb-2" style={{ maxWidth: 50 }}>
            <EditableCell
              index={row.original.id}
              value={row.original.price}
              onChange={setPriceProduct}
            />
          </div>
        );
      },
    },
    {
      Header: "Total",
      accessor: "total", // String-based value accessors!
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <p style={{ margin: 0 }}>
            {row.original.price * row.original.count} kr
          </p>
        );
      },
    },
    {
      accessor: "system",
      width: 30,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => deleteSelectedOrderItem(row.original)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  const subProductColumns = [
    {
      Header: "Type",
      accessor: "subProductName",
      width: 200,
    },
    {
      Header: "Art. nr.",
      accessor: "articleNumber",
      width: 200,
    },
  ];

  const updateOptions = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };

  async function updateOrderPost(
    order: any,
    orderId: string,
    orderPostId: string
  ) {
    console.log(orderPostId);
    try {
      const request = await axios.patch(
        `orders/${orderId}/orderpost/${orderPostId}`,
        order,
        updateOptions
      );

      if (request.status === 200) {
        let changedOrderItemIndex = productList.findIndex(
          (o) => o.id === request.data.id
        );
        let newOrderPostList = productList;
        let x = request.data;
        newOrderPostList[changedOrderItemIndex] = {
          productId: x.product.id,
          subProductId: "",
          count: x.count,
          name: x.product.productName,
          subProductName: x.subProduct?.subProductName,
          price: x.price,
          delivered: x.delivered,
          id: x.id,
          articleNumber: x.subProduct
            ? x.subProduct.articleNumber
            : x.product.articleNumber,
        };
        setProductList([...newOrderPostList]);

        notify("Salgsposten ble oppdatert", true, "success");
      }
    } catch (error) {
      notify("Det oppsto en feil ved oppdatering av salg", true, "error");
    }
  }

  function clickedDelivered(orderItemId: string, checked: boolean) {
    const row = productList.find((p) => p.id == orderItemId.toString());
    if (!R.isNil(row?.price)) {
      updateOrderPost(
        { delivered: checked, price: row?.price.toString() },
        orderId,
        "" + orderItemId
      );
    } else {
      updateOrderPost({ delivered: checked }, orderId, "" + orderItemId);
    }

    var tempProductList = productList;
    var index = tempProductList.findIndex((x) => x.id === orderItemId);
    tempProductList[index].delivered = checked;
    setProductList(tempProductList);
  }

  async function updateOrder(order: any, id: string) {
    try {
      const request = await axios.patch(`orders/${id}`, order, updateOptions);

      if (request.status === 200) {
        notify("Salget ble oppdatert", true, "success");
      }
    } catch (error) {
      notify("Det oppsto en feil ved oppdatering av kunde", true, "error");
    }
  }

  function reCalculateTotalPrice() {
    const result = productList.reduce(
      (total, currentvalue) =>
        (total = total + currentvalue.count * currentvalue.price),
      0
    );
    setTotalPrice(result);
  }
  function setCountProduct(orderPostId: number, count: number) {
    // productList[rowId].count = count;
    const row = productList.find((p) => p.id == orderPostId.toString());
    if (!R.isNil(row?.price)) {
      updateOrderPost(
        { count: count, price: row?.price.toString() },
        orderId,
        "" + orderPostId
      );
    } else {
      updateOrderPost({ count: count }, orderId, "" + orderPostId);
    }

    // setProductList([...productList]);
  }

  function setPriceProduct(orderPostId: number, price: number) {
    // productList[rowId].price = price;
    updateOrderPost({ price }, orderId, "" + orderPostId);
    // setProductList([...productList]);
  }

  function update() {
    if (customer) {
      const orderPosts = productList;

      if (product && count && price) {
        orderPosts.push({
          productId: product.id,
          subProductId: "",
          name: product.name,
          subProductName: "",
          delivered: false,
          count: Number(count),
          price: Number(price),
        });
      }

      const order: UpdateOrder = {
        catalog: Number(catalog),
        delivered: delivered,
        partialyDelivered: partialyDelivered,
        paid: paid,
        orderedFromSupplier: orderedFromSupplier,
        notes,
      };

      updateOrder(order, orderId).then(() => {
        history.push("/orders");
      });
    }
    //add error message
  }

  async function addNewOrderItem() {
    if (count && product) {
      const orderItem: NewOrderItem = subProduct
        ? {
            productId: product.id,
            subProductId: subProduct.id,
            count: Number(count),
            price: Number(price),
          }
        : {
            productId: product.id,
            count: Number(count),
            price: Number(price),
          };
      try {
        const request = await axios.post("/orders/" + id + "/item", orderItem);

        if (request.status === 200) {
          notify("En nytt produkt ble lagt til salget", true, "success");
          addOrderItemToList(request.data);
        }
      } catch (error) {
        notify(
          "Det oppsto en feil når produktet skulle bli lagt til",
          true,
          "error"
        );
      } finally {
        fetchProducts();
      }
    }
  }

  function addOrderItemToList(orderItem: OrderPost) {
    const newProductList = productList.concat(
      orderItem.subProduct
        ? {
            productId: orderItem.product.id,
            subProductId: orderItem.subProduct.id,
            name: "" + orderItem.product.productName,
            subProductName: "" + orderItem.subProduct?.subProductName,
            articleNumber: orderItem.subProduct.articleNumber,
            count: Number(orderItem.count),
            price: Number(orderItem.price),
            delivered: false,
            id: orderItem.id,
          }
        : {
            productId: orderItem.product.id,
            name: "" + orderItem.product.productName,
            articleNumber: orderItem.product.articleNumber,
            count: Number(orderItem.count),
            price: Number(orderItem.price),
            delivered: false,
            id: orderItem.id,
          }
    );
    setProductList(newProductList);
    setTotalPrice(Number(totalPrice) + Number(price * count));
    setSelectedProduct(null);
    setSelectedSubProduct(null);
    setCount(1);
    setPrice(0);
  }

  async function deleteSelectedOrderItem(orderPost: NewOrderPost) {
    if (orderPost.id !== undefined && orderId !== undefined) {
      try {
        const request = await axios.delete(
          "orders/" + orderId + "/item/" + orderPost.id
        );

        if (request.status === 200 || request.status === 204) {
          notify("Et produkt ble slettet fra salget", true, "success");
          removeProductFromList(orderPost);
        }
      } catch (error) {
        notify(
          "Det oppsto en feil når produktet skulle bli slettet",
          true,
          "error"
        );
      }
    }
  }

  function removeProductFromList(orderPost: NewOrderPost) {
    const newProductList = productList.filter(
      (item) => item.id !== orderPost.id
    );
    setProductList(newProductList);
    setTotalPrice(
      Number(totalPrice) - Number(orderPost.price * orderPost.count)
    );
  }

  async function createProduct(product: string, articleNr: string) {
    const newProduct: NewProduct = {
      productName: product,
      articleNumber: articleNr,
      subProducts: tempSubProducts,
    };

    try {
      setWaitingForAPI(true);
      const request = await axios.post("products", newProduct);

      if (request.status === 201) {
        notify("Et nytt product ble opprettet", true, "success");
        fetchProducts();
      }
    } catch (error) {
      notify("Det oppsto en feil ved opprettelse av produkt", true, "error");
    } finally {
      setWaitingForAPI(false);
    }
  }

  async function createSubProduct(subProduct: string, articleNr: string) {
    if (product) {
      const newSubProduct: NewSubProduct = {
        subProductName: subProduct,
        articleNumber: articleNr,
        productId: product.id,
      };

      try {
        setWaitingForAPI(true);
        const request = await axios.post("subproducts", newSubProduct);

        if (request.status === 201) {
          notify("En ny product sub kategori ble opprettet", true, "success");
          fetchProducts();
        }
      } catch (error) {
        notify(
          "Det oppsto en feil ved opprettelse av sub produkt",
          true,
          "error"
        );
      } finally {
        setWaitingForAPI(false);
      }
    }
  }

  function tryingToGoBack() {
    history.goBack();
  }

  function closeProductModal() {
    setOpenArticleDialog(false);
    setAddSubProducts(false);
    setArticleNumber("");
    setTempSubProductArticleNumber("");
    setTempSubProductName("");
    setTempSubProducts([]);
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row justify-content-center pt-4">
        <div className="edit-order-header-container">
          <h1 style={{ fontWeight: 100, fontSize: 24, textAlign: "left" }}>
            Rediger salg
          </h1>
          <Breakpoint medium up>
            <Route
              render={() => (
                <IconButton
                  style={{
                    color: "#906088",
                    cursor: "pointer",
                    fontSize: 40,
                  }}
                  onClick={() => tryingToGoBack()}
                >
                  <ArrowBackIcon fontSize="inherit" />
                </IconButton>
              )}
            />
          </Breakpoint>
          <Breakpoint small down>
            <Route
              render={() => (
                <IconButton
                  style={{
                    color: "#906088",
                    cursor: "pointer",
                    fontSize: 30,
                  }}
                  onClick={() => tryingToGoBack()}
                >
                  <ArrowBackIcon fontSize="inherit" />
                </IconButton>
              )}
            />
          </Breakpoint>
        </div>
      </div>

      <div className="new-order-container grow-1 flex-wrap flex-column m-auto ">
        <div className="d-flex flex-row flex-grow-1">
          <div className="d-flex flex-column flex-wrap justify-content-center mt-1 w-100">
            <Breakpoint medium up>
              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="new-order-section-icon">
                    <PermIdentityOutlinedIcon fontSize="inherit" />
                  </div>
                  <div className="new-order-section-content">
                    <div className="edit-order-form-input">
                      <h3 style={{ fontWeight: 600, fontSize: 16 }}>Kunde</h3>
                      <p>
                        <strong>{selectedCustomer}</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="new-order-section ms-2 w-25 ">
                  <div className="new-order-section-content flex-grow-1 pb-3">
                    <InputText
                      inputTitle="Katalog"
                      value={catalog !== 0 ? catalog : ""}
                      type="number"
                      width={80}
                      onChange={(e: any) => setCatalog(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Breakpoint>
            <Breakpoint small down>
              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="new-order-section-content flex-grow-1">
                    <div className="edit-order-form-input">
                      <h3 style={{ fontWeight: 600, fontSize: 16 }}>Kunde</h3>
                      <p>
                        <strong>{selectedCustomer}</strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="new-order-section-content flex-grow-1 pb-3">
                    <InputText
                      inputTitle="Katalog"
                      value={catalog !== 0 ? catalog : ""}
                      type="number"
                      width={80}
                      onChange={(e: any) => setCatalog(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Breakpoint>
            {allProducts?.length === 0 ? (
              <div className="new-order-info-text">
                <p>
                  Opprette produkter direkte ved å skrive inn{" "}
                  <strong>navnet på produktet</strong> i søkefeltet og deretter
                  trykke opprett produkt!
                </p>
              </div>
            ) : null}

            <Breakpoint medium up>
              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="new-order-section-icon">
                    <DiamondIcon fontSize="inherit" />
                  </div>
                  <div className="new-order-section-content">
                    <div className="d-flex flex-column">
                      <h3 style={{ fontWeight: 600, fontSize: 16 }}>Produkt</h3>
                      <div className="d-flex flex-row">
                        <div className="new-order-select">
                          <SearchableDropdown
                            noFilter={true}
                            value={selectedProduct}
                            items={products}
                            typeText="produkt"
                            onChange={(opt) => {
                              setPreventSearch(true);
                              setProduct(
                                allProducts.find((p) => p.id === opt.id)
                              );
                              setSelectedProduct(opt);
                              setSelectedSubProduct(null);
                              setTempProductInput("");
                              sortSubProducts(opt.id);
                            }}
                            onCreateOption={(opt2) => {
                              setOpenArticleDialog(true);
                            }}
                            onInputChange={(text) => {
                              if (
                                (tempProductInput &&
                                  tempProductInput.length < 2) ||
                                text !== ""
                              ) {
                                setTempProductInput(text);
                              }
                            }}
                            inputValue={tempProductInput}
                          />
                        </div>
                        {sortedSubProducts ? (
                          <div className="new-order-select">
                            <SearchableDropdown
                              value={selectedSubProduct}
                              items={sortedSubProducts}
                              typeText="type"
                              onChange={(opt) => {
                                setSubProduct(
                                  subProductList.find((p) => p.id === opt.id)
                                );
                                setSelectedSubProduct(opt);
                                setTempSubProductInput(opt.productName);
                              }}
                              onCreateOption={(opt2) => {
                                setOpenSubProductDialog(true);
                              }}
                              onInputChange={(text) => {
                                if (
                                  (tempSubProductInput &&
                                    tempSubProductInput.length < 2) ||
                                  text !== ""
                                ) {
                                  setTempSubProductInput(text);
                                }
                              }}
                              inputValue={tempSubProductInput}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="d-flex flex-row mt-4">
                      <div className="">
                        <InputText
                          inputTitle="ANTALL"
                          value={count !== 0 ? count : ""}
                          type="number"
                          width={60}
                          onChange={(e: any) => setCount(e.target.value)}
                        />
                      </div>
                      <div className="px-3">
                        <InputText
                          inputTitle="PRIS"
                          value={price !== 0 ? price : ""}
                          type="number"
                          width={60}
                          onChange={(e: any) => setPrice(e.target.value)}
                        />
                      </div>
                      <div className="create-order-button-container">
                        <MediumButton
                          buttonText="Legg til"
                          onClick={() => addNewOrderItem()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Breakpoint>
            <Breakpoint small down>
              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="d-flex flex-column w-100 justify-content-center">
                    <h3
                      style={{
                        fontWeight: 600,
                        fontSize: 16,
                        textAlign: "center",
                      }}
                    >
                      Produkt
                    </h3>
                    <div
                      className="new-order-select mb-2"
                      style={{ width: 225, alignSelf: "center" }}
                    >
                      <SearchableDropdown
                        noFilter={true}
                        value={selectedProduct}
                        items={products}
                        typeText="produkt"
                        width={225}
                        onChange={(opt) => {
                          setPreventSearch(true);
                          setProduct(allProducts.find((p) => p.id === opt.id));
                          setSelectedProduct(opt);
                          setSelectedSubProduct(null);
                          setTempProductInput("");
                          sortSubProducts(opt.id);
                        }}
                        onCreateOption={(opt2) => {
                          setOpenArticleDialog(true);
                        }}
                        onInputChange={(text) => {
                          if (
                            (tempProductInput && tempProductInput.length < 2) ||
                            text !== ""
                          ) {
                            setTempProductInput(text);
                          }
                        }}
                        inputValue={tempProductInput}
                      />
                    </div>
                    {sortedSubProducts ? (
                      <div
                        className="new-order-select"
                        style={{ width: 225, alignSelf: "center" }}
                      >
                        <SearchableDropdown
                          value={selectedSubProduct}
                          items={sortedSubProducts}
                          typeText="type"
                          width={225}
                          onChange={(opt) => {
                            setSubProduct(
                              subProductList.find((p) => p.id === opt.id)
                            );
                            setSelectedSubProduct(opt);
                            setTempSubProductInput(opt.productName);
                          }}
                          onCreateOption={(opt2) => {
                            setOpenSubProductDialog(true);
                          }}
                          onInputChange={(text) => {
                            if (
                              (tempSubProductInput &&
                                tempSubProductInput.length < 2) ||
                              text !== ""
                            ) {
                              setTempSubProductInput(text);
                            }
                          }}
                          inputValue={tempSubProductInput}
                        />
                      </div>
                    ) : null}

                    <div className="d-flex flex-row justify-content-center">
                      <div className="">
                        <InputText
                          inputTitle="ANTALL"
                          value={count !== 0 ? count : ""}
                          type="number"
                          width={60}
                          onChange={(e: any) => setCount(e.target.value)}
                        />
                      </div>
                      <div className="ps-3">
                        <InputText
                          inputTitle="PRIS"
                          value={price !== 0 ? price : ""}
                          type="number"
                          width={60}
                          onChange={(e: any) => setPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="create-order-button-container">
                      <MediumButton
                        buttonText="Legg til"
                        onClick={() => addNewOrderItem()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Breakpoint>
            {productList.length > 0 ? (
              <div>
                <Breakpoint medium up>
                  <div className="new-order-row">
                    <div className="new-order-section flex-grow-1">
                      <div className="new-order-section-icon">
                        <ShoppingCartIcon fontSize="inherit" />
                      </div>
                      <div className="new-order-section-content">
                        <div className="d-flex flex-column">
                          <h2
                            style={{
                              flexGrow: 1,
                              fontWeight: 100,
                              fontSize: 20,
                              margin: 0,
                            }}
                          >
                            Produkter i ordren
                          </h2>
                          {partialyDelivered ? (
                            <DataTable
                              columns={columnsWithDelivered}
                              data={productList}
                            />
                          ) : (
                            <DataTable columns={columns} data={productList} />
                          )}
                          <div
                            style={{
                              flexGrow: 1,
                              fontSize: 16,
                              marginTop: 15,
                              paddingRight: 75,
                              textAlign: "end",
                            }}
                          >
                            <p>
                              <strong>Totalpris:</strong> {totalPrice} kr
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Breakpoint>
                <Breakpoint small down>
                  <div className="new-order-row">
                    <div className="new-order-section-content">
                      <div className="d-flex flex-column">
                        <h2
                          style={{
                            flexGrow: 1,
                            fontWeight: 100,
                            fontSize: 20,
                            margin: 0,
                          }}
                        >
                          Produkter i ordren
                        </h2>
                        <DataTable columns={columnsMobile} data={productList} />
                        <div
                          style={{
                            flexGrow: 1,
                            fontSize: 16,
                            marginTop: 15,
                            paddingRight: 35,
                            textAlign: "end",
                          }}
                        >
                          <p>
                            <strong>Totalpris:</strong> {totalPrice} kr
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Breakpoint>
              </div>
            ) : null}
            <Breakpoint medium up>
              <>
                {showNotes ? (
                  <>
                    <div className="new-order-row">
                      <div className="new-order-section flex-grow-1">
                        <div className="new-order-section-content">
                          <div className="new-order-form-group">
                            <div className="new-order-form-input">
                              <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                                Bestilt
                              </h3>
                              <CheckBox
                                checked={orderedFromSupplier}
                                onChange={(e: any) =>
                                  setOrderedFromSupplier(e.target.checked)
                                }
                              />
                            </div>
                            <div className="new-order-form-input">
                              <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                                Levert
                              </h3>
                              <CheckBox
                                checked={delivered}
                                onChange={(e: any) =>
                                  setDelivered(e.target.checked)
                                }
                              />
                            </div>
                            <div className="new-order-form-input">
                              <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                                Delvis Levert
                              </h3>
                              <CheckBox
                                checked={partialyDelivered}
                                onChange={(e: any) =>
                                  setPartialyDelivered(e.target.checked)
                                }
                              />
                            </div>
                            <div className="new-order-form-input">
                              <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                                Betalt
                              </h3>
                              <CheckBox
                                checked={paid}
                                onChange={(e: any) => setPaid(e.target.checked)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="new-order-row">
                      <div className="new-order-section flex-grow-1 justify-content-center">
                        <TextArea
                          inputTitle="Notat"
                          value={notes}
                          type="text"
                          width={400}
                          onChange={(e: any) => setNotes(e.target.value)}
                        />
                      </div>
                      <div className="new-order-section ms-2 w-25 ">
                        <div className="new-order-section-content flex-grow-1">
                          <div className="create-order-button-container">
                            <MediumButton
                              buttonText="Oppdater"
                              onClick={() => update()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="new-order-row">
                    <div className="new-order-section flex-grow-1">
                      <div className="new-order-section-content">
                        <div className="new-order-form-group">
                          <div className="new-order-form-input">
                            <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                              Bestilt
                            </h3>
                            <CheckBox
                              checked={orderedFromSupplier}
                              onChange={(e: any) =>
                                setOrderedFromSupplier(e.target.checked)
                              }
                            />
                          </div>
                          <div className="new-order-form-input">
                            <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                              Levert
                            </h3>
                            <CheckBox
                              checked={delivered}
                              onChange={(e: any) =>
                                setDelivered(e.target.checked)
                              }
                            />
                          </div>
                          <div className="new-order-form-input">
                            <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                              Delvis Levert
                            </h3>
                            <CheckBox
                              checked={partialyDelivered}
                              onChange={(e: any) =>
                                setPartialyDelivered(e.target.checked)
                              }
                            />
                          </div>
                          <div className="new-order-form-input">
                            <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                              Betalt
                            </h3>
                            <CheckBox
                              checked={paid}
                              onChange={(e: any) => setPaid(e.target.checked)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="new-order-section ms-2 px-4">
                      <div className="d-flex flex-row align-items-center">
                        <div className="d-flex flex-column">
                          <div className="d-flex w-50 flex-row m-auto">
                            <IconButton
                              style={{
                                color: "#906088",
                                fontSize: 30,
                              }}
                              aria-label="Add notes"
                              onClick={() => {
                                setShowNotes(true);
                              }}
                            >
                              <AddIcon fontSize="inherit" />
                            </IconButton>
                          </div>
                          <h5 className="text-center">Legg til et notat:</h5>
                        </div>
                      </div>
                    </div>
                    <div className="new-order-section ms-2 w-25 ">
                      <div className="new-order-section-content flex-grow-1">
                        <div className="create-order-button-container">
                          <MediumButton
                            buttonText="Oppdater"
                            onClick={() => update()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </Breakpoint>
            <Breakpoint small down>
              {showNotes ? (
                <div className="new-order-row">
                  <div className="new-order-section-no-padding pb-4 flex-grow-1">
                    <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                      <TextArea
                        inputTitle="Notat"
                        value={notes}
                        type="text"
                        width={400}
                        onChange={(e: any) => setNotes(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="new-order-row">
                  <div className="new-order-section-no-padding flex-grow-1">
                    <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                      <IconButton
                        style={{
                          color: "#906088",
                          fontSize: 30,
                        }}
                        aria-label="Add notes"
                        onClick={() => {
                          setShowNotes(true);
                        }}
                      >
                        <AddIcon fontSize="inherit" />
                      </IconButton>

                      <h5 className="text-center m-0">Legg til et notat:</h5>
                    </div>
                  </div>
                </div>
              )}
              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="new-order-section-content">
                    <div className="new-order-form-group">
                      <div className="new-order-form-input">
                        <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                          Bestilt
                        </h3>
                        <CheckBox
                          checked={orderedFromSupplier}
                          onChange={(e: any) =>
                            setOrderedFromSupplier(e.target.checked)
                          }
                        />
                      </div>
                      <div className="new-order-form-input">
                        <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                          Levert
                        </h3>
                        <CheckBox
                          checked={delivered}
                          onChange={(e: any) => setDelivered(e.target.checked)}
                        />
                      </div>
                      <div className="new-order-form-input">
                        <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                          Betalt
                        </h3>
                        <CheckBox
                          checked={paid}
                          onChange={(e: any) => setPaid(e.target.checked)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="new-order-section-content flex-grow-1">
                    <div className="create-order-button-container">
                      <MediumButton
                        buttonText="Oppdater"
                        onClick={() => update()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Breakpoint>
          </div>
        </div>
        <Dialog
          open={openArticleDialog}
          onClose={() => closeProductModal()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ backgroundColor: "#c0e9d7", color: "#3C5663" }}
          >
            {
              "Her kan du legg til et artikkelnummer eller underkategorier (eks farge eller type)?"
            }
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#c0e9d7", color: "#FFF" }}>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontWeight: 600 }}
            >
              Produktnavn: {tempProductInput}
            </DialogContentText>
            <InputText
              inputTitle="Artikkelnummer"
              value={articleNumber}
              type="text"
              onChange={(e: any) => setArticleNumber(e.target.value)}
            />
            {addSubProducts && (
              <div>
                <div style={{ color: "#3C5663" }}>
                  <h3
                    style={{
                      flexGrow: 1,
                      fontWeight: 600,
                      fontSize: 18,
                      padding: 0,
                      marginBottom: 30,
                      marginTop: 50,
                      color: "#3C5663",
                    }}
                  >
                    Underkategorier:
                  </h3>
                  {tempSubProducts.length > 0 && (
                    <DataTable
                      columns={subProductColumns}
                      data={tempSubProducts}
                    />
                  )}
                </div>
                <Row>
                  <Col lg={4} style={{ marginRight: 10 }}>
                    <InputText
                      inputTitle="Navn"
                      value={tempSubProductName}
                      onChange={(e: any) =>
                        setTempSubProductName(e.target.value)
                      }
                    />
                  </Col>
                  <Col lg={4} style={{ marginRight: 10 }}>
                    <InputText
                      inputTitle="Artikkelnummer"
                      value={tempSubProductArticleNumber}
                      onChange={(e: any) =>
                        setTempSubProductArticleNumber(e.target.value)
                      }
                    />
                  </Col>
                  <Col lg={3}>
                    <div className="create-product-button-container">
                      <MediumButton
                        buttonText="legg til"
                        onClick={() => createTempSubProduct()}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{ backgroundColor: "#c0e9d7", paddingRight: 20 }}
          >
            <Button
              onClick={() => {
                setAddSubProducts((openSubPanel) => !openSubPanel);
              }}
              style={{
                backgroundColor: "#906088",
                color: "#FFF",
              }}
            >
              {addSubProducts ? "Skjul underkategorier" : "Vis underkategorier"}
            </Button>
            <Button
              onClick={() => {
                if (!waitingForAPI) {
                  setWaitingForAPI(true);
                  createProduct(tempProductInput, articleNumber);
                  closeProductModal();
                }
              }}
              style={{
                backgroundColor: "#906088",
                color: "#FFF",
              }}
            >
              Opprett
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSubProductDialog}
          onClose={() => setOpenSubProductDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Vil du legg til et artikkelnummer"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Type: {tempSubProductInput}
            </DialogContentText>
            <InputText
              inputTitle="Artikkelnummer"
              value={articleNumber}
              type="text"
              onChange={(e: any) => setArticleNumber(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setTempProductInput("");
                setOpenSubProductDialog(false);
                setArticleNumber("");
              }}
              color="primary"
            >
              Hopp over
            </Button>
            <Button
              onClick={() => {
                if (!waitingForAPI) {
                  setWaitingForAPI(true);
                  createSubProduct(tempSubProductInput, "");
                  setOpenSubProductDialog(false);
                  setArticleNumber("");
                }
              }}
              color="primary"
            >
              Nei
            </Button>
            <Button
              onClick={() => {
                if (!waitingForAPI) {
                  setWaitingForAPI(true);
                  createSubProduct(tempSubProductInput, articleNumber);
                  setOpenSubProductDialog(false);
                  setArticleNumber("");
                }
              }}
              color="primary"
              autoFocus
            >
              Ja
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Salget er ikke lagret"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Er du sikker på at vil gå tilbake uten å opprette salget
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Nei
            </Button>
            <Button
              onClick={() => history.push("/orders")}
              color="primary"
              autoFocus
            >
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default EditOrderPage;
