import { Action, action, ThunkOn, thunkOn } from "easy-peasy";
import { StoreModel } from "store/MainModel";

export interface StatusModel {
  accountRequestReceived: boolean;
  pageLoading: boolean;
  setAccountRequestReceived: Action<this, boolean>;
  setAccountPageLoading: Action<this, boolean>;
  finishedInitialLoading: boolean;
  setFinishedInitialLoading: Action<this, boolean>;

  onLastInitialLoad: ThunkOn<StatusModel, string, StoreModel>;
}

export const status: StatusModel = {
  accountRequestReceived: false,
  pageLoading: true,
  setAccountRequestReceived: action((state, payload) => {
    state.accountRequestReceived = payload;
  }),
  setAccountPageLoading: action((state, payload) => {
    state.pageLoading = payload;
  }),
  finishedInitialLoading: false,
  setFinishedInitialLoading: action((state, payload) => {
    state.finishedInitialLoading = payload;
  }),

  onLastInitialLoad: thunkOn(
    (actions, storeActions) => storeActions.account.getAccountThunk,
    (state, target) => {
      state.setAccountRequestReceived(true);
    }
  ),
};
