import React from "react";
import "./style.scss";
import ProfileMenu from "common/layout/Header/components/ProfileMenu";
import { useStoreState } from "store/store";
import Button from "common/components/Button/Button";
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "common/authentication/authConfig";

//   const MediumButtonLong: React.FC<ButtonProps> = ({ buttonText, onClick }) => {

const Header: React.FC = () => {
  const isAuthenticated = useStoreState(
    (state) => state.authentication.isAuthenticated
  );

  function login() {
    const authProvider = new PublicClientApplication(msalConfig);
    authProvider.loginRedirect(loginRequest);
  }

  return (
    <div className="header-content">
      <div style={{ width: 100 }}> </div>
      <h1 className="header-text">MITT KUNDESYSTEM</h1>
      <div className="d-flex flex-row m-0 align-center " style={{ width: 100 }}>
        {isAuthenticated ? (
          <ProfileMenu />
        ) : (
          <div className="header-login-button">
            <Button
              text="Logg inn"
              onClick={() => {
                login();
              }}
            ></Button>
          </div>
        )}
      </div>
    </div>
  );
};
export default Header;
