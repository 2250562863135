import React, { useState, useEffect } from "react";
import * as R from "ramda";
import CircleLoader from "react-spinners/CircleLoader";
import { NewOrder } from "../../types/NewOrder";
import { NewOrderPost } from "../../types/NewOrderPost";
import InputText from "../../components/InputText";
import MediumButton from "../../components/MediumButton";
import SearchableDropdown from "../../components/SearchableDropdown";
import { Customer } from "../../types/Customer";
import { Product } from "../../types/Product";
import CheckBox from "../../components/CheckBox";
import "./NewOrderPage.scss";
import { Route, useHistory, useParams } from "react-router-dom";
import { NewProduct } from "../../types/NewProduct";
import { NewCustomer } from "../../types/NewCustomer";
import { Breakpoint } from "react-socks";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/Delete";
import DataTable from "../../components/DataTable";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { SubProduct } from "../../types/SubProduct";
import { NewSubProduct } from "../../types/NewSubProduct";
import { Col, Row } from "reactstrap";
import axios from "axios";
import { notify } from "common/utils/notify/notifyFunction";
import TableCellText from "common/components/TableCellText/TableCellText";
import EditableCell from "common/components/EditableCell/EditableCell";
import { ProductShort } from "types/ProductShort";
import { SubProductShort } from "types/SubProductShort";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import DiamondIcon from "@mui/icons-material/Diamond";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useStoreActions } from "store/store";
import TextArea from "common/components/TextArea/TextArea";

const NewOrderPage: React.FC = () => {
  const [waitingForAPI, setWaitingForAPI] = useState<boolean>(false);
  const [initialDataLoading, setInitialDataLoading] = useState<boolean>(true);
  const [addSubProducts, setAddSubProducts] = useState<boolean>(false);
  const [tempSubProducts, setTempSubProducts] = useState<NewSubProduct[]>([]);
  const [tempSubProductName, setTempSubProductName] = useState("");
  const [tempSubProductArticleNumber, setTempSubProductArticleNumber] =
    useState("");

  const [customer, setCustomer] = useState<Customer>();
  const [product, setProduct] = useState<ProductShort>();
  const [subProduct, setSubProduct] = useState<SubProductShort>();
  const [createdOrders, setCreatedOrders] = useState<string[]>();
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [selectedSubProduct, setSelectedSubProduct] = useState<any>();
  const [tempCustomerInput, setTempCustomerInput] = useState<string>("");
  const [tempProductInput, setTempProductInput] = useState<string>("");
  const [tempSubProductInput, setTempSubProductInput] = useState<string>("");
  const [count, setCount] = useState<number>(1);
  const [itemCount, setItemCount] = useState<number>(1);
  const [price, setPrice] = useState<number>(0);
  const [catalog, setCatalog] = useState<number>(0);
  const [delivered, setDelivered] = useState(false);
  const [paid, setPaid] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const [orderedFromSupplier, setOrderedFromSupplier] = useState(false);
  const [customers, setCustomers] = useState<any>(null);
  const [allCustomers, setAllCustomers] = useState<Customer[]>([]);
  const [products, setProducts] = useState<any>(null);
  const [sortedSubProducts, setSortedSubProducts] = useState<any>(null);
  const [productList, setProductList] = useState<NewOrderPost[]>([]);
  const [subProductList, setSubProductList] = useState<SubProductShort[]>([]);

  const [totalPrice, setTotalPrice] = React.useState<number>(0);
  const [open, setOpen] = useState(false);
  const [allProducts, setAllProducts] = useState<ProductShort[]>([]);
  const [articleNumber, setArticleNumber] = useState<string>("");
  const [openArticleDialog, setOpenArticleDialog] = useState<boolean>(false);
  const [openSubProductDialog, setOpenSubProductDialog] =
    useState<boolean>(false);
  const history = useHistory();
  const [lastProductSearch, setLastProductSearch] = useState("");
  const [preventSearch, setPreventSearch] = useState(false);
  const [finnishedLoading, setFinnishedLoading] = React.useState(false);
  const [customersLoaded, setCustomersLoaded] = React.useState(false);
  const [productsLoaded, setProductsLoaded] = React.useState(false);
  const { id } = useParams<ParamTypes>();

  interface ParamTypes {
    id: string;
  }

  const SetLoading = useStoreActions(
    (actions) => actions.status.setAccountPageLoading
  );

  useEffect(() => {
    fetchCustomers();
    SetLoading(true);
  }, []);

  useEffect(() => {
    fetchProducts();
  }, []);
  useEffect(() => {
    if (customersLoaded && productsLoaded) {
      SetLoading(false);
      setFinnishedLoading(true);
    }
  }, [customersLoaded, productsLoaded]);

  useEffect(() => {
    checkForCreatedProduct();
  }, [products]);

  useEffect(() => {
    checkForCreatedCustomer();
  }, [customers]);

  useEffect(() => {
    reCalculateTotalPrice();
  }, [productList]);

  async function fetchCustomers() {
    setInitialDataLoading(true);
    try {
      const request = await axios.get("customers");

      if (request.status === 200) {
        let allCustomers = request.data.map((customer: Customer) => {
          return {
            label: customer.firstName + " " + customer.lastName,
            value: customer.id,
            id: customer.id,
          };
        });
        setAllCustomers(request.data);
        setCustomers(allCustomers);
        setCustomersLoaded(true);
        if (!R.isNil(id)) {
          let selectedCustomer = request.data.find((p: Customer) => p.id == id);
          let dropDownItem = allCustomers.find((c: any) => c.id == id);
          setSelectedCustomer(dropDownItem);
          setCustomer(selectedCustomer);
        }
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av kunder", true, "error");
    } finally {
      setInitialDataLoading(false);
    }
  }

  function getProductDropdownText(product: ProductShort) {
    if (product.articleNumber == "" && R.isEmpty(product.subProducts)) {
      return product.name;
    }
    if (R.isEmpty(product.subProducts)) {
      return product.name + " (" + product.articleNumber + ")";
    }
    let text = product.name + " (";
    product.subProducts.forEach((subProduct, index) => {
      if (index == 0) {
        text += subProduct.articleNumber;
      } else {
        text += ", " + subProduct.articleNumber;
      }
    });
    return text + ")";
  }

  async function fetchProducts() {
    try {
      let encodedInput = encodeURIComponent(tempProductInput);
      const request = await axios.get("products" + "?query=" + encodedInput);
      setLastProductSearch(tempProductInput);

      if (request.status === 200) {
        let allProducts = request.data.products.map((product: ProductShort) => {
          return {
            label: getProductDropdownText(product),
            value: product.id,
            id: product.id,
          };
        });
        setAllProducts(request.data.products);
        setProducts(allProducts);

        setSubProductList(request.data.subProducts);
        if (product) {
          sortSubProductsFromInput(request.data.subProducts, product.id);
        }
        setProductsLoaded(true);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av produkter", true, "error");
    } finally {
    }
  }

  useEffect(() => {
    if (preventSearch) {
      setPreventSearch(false);
      return;
    }
    if (tempProductInput?.length >= 3 || lastProductSearch?.length >= 3) {
      // if (localValue != value) {
      let timer = setTimeout(() => {
        fetchProducts();
      }, 800);

      return () => clearTimeout(timer);
    }
  }, [tempProductInput]);

  function checkForCreatedCustomer() {
    if (tempCustomerInput !== "") {
      let createdCustomer = allCustomers.find(
        (p: Customer) => `${p.firstName} ${p.lastName}` == tempCustomerInput
      );
      if (createdCustomer) {
        let dropDownItem = customers.find(
          (c: any) => c.id === createdCustomer?.id
        );
        setSelectedCustomer(dropDownItem);
        setCustomer(createdCustomer);
        setTempCustomerInput("");
      }
    }
  }

  async function checkForCreatedProduct() {
    if (tempProductInput !== "") {
      let createdProduct = allProducts.find(
        (p: any) => p.name === tempProductInput
      );
      if (createdProduct) {
        let dropDownItem = products.find(
          (c: any) => c.id === createdProduct?.id
        );
        setSelectedProduct(dropDownItem);
        setProduct(createdProduct);
        setSelectedSubProduct(null);
        setSelectedSubProduct(null);
        sortSubProductsFromInput(createdProduct.subProducts, createdProduct.id);

        setTempProductInput("");
      }
    }
  }

  async function sortSubProducts(productId: string) {
    const sortedList = subProductList.filter((s) => s.productId === productId);
    if (sortedList.length > 0) {
      let allSubProducts = sortedList.map((subProduct: SubProductShort) => {
        if (subProduct.articleNumber) {
          return {
            label: subProduct.name + " (" + subProduct.articleNumber + ")",
            value: subProduct.id,
            id: subProduct.id,
          };
        } else {
          return {
            label: subProduct.name,
            value: subProduct.id,
            id: subProduct.id,
          };
        }
      });
      setSortedSubProducts(allSubProducts);
    } else {
      setSortedSubProducts(null);
    }
  }

  function createTempSubProduct() {
    if (tempSubProductName !== "" && tempSubProductArticleNumber !== "") {
      const subProduct: NewSubProduct = {
        subProductName: tempSubProductName,
        articleNumber: tempSubProductArticleNumber,
      };

      const newSubProductList = tempSubProducts.concat(subProduct);
      setTempSubProducts(newSubProductList);
      setTempSubProductName("");
      setTempSubProductArticleNumber("");
    }
  }

  async function sortSubProductsFromInput(
    subProductList: SubProductShort[],
    productId: string
  ) {
    const sortedList = subProductList.filter((s) => s.productId === productId);
    if (sortedList.length > 0) {
      let allSubProducts = sortedList.map((subProduct: SubProductShort) => {
        if (subProduct.articleNumber) {
          return {
            label: subProduct.name + " (" + subProduct.articleNumber + ")",
            value: subProduct.id,
            id: subProduct.id,
          };
        } else {
          return {
            label: subProduct.name,
            value: subProduct.id,
            id: subProduct.id,
          };
        }
      });
      setSortedSubProducts(allSubProducts);

      if (tempSubProductInput !== "") {
        let createdSubProduct = sortedList.find(
          (p: any) => p.name === tempSubProductInput
        );
        if (createdSubProduct) {
          let dropDownItem = allSubProducts.find(
            (c: any) => c.id === createdSubProduct?.id
          );
          setSelectedSubProduct(dropDownItem);
          setSubProduct(createdSubProduct);
        }
      }
    } else {
      setSortedSubProducts(null);
    }
    setTempSubProductInput("");
  }

  const columns = [
    {
      Header: "Produkt",
      accessor: "name",
      width: 220,
      Cell: ({ row }: any) => {
        var text = row.original.name;
        return (
          <div className="pe-2">
            <TableCellText text={text} width={200} />
          </div>
        );
      },
    },
    {
      Header: "Type",
      accessor: "subProductName",
      width: 120,
    },
    {
      Header: "Art.nr",
      accessor: "articleNumber",
      width: 60,
    },
    {
      Header: "Antall",
      accessor: "count",
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <div className="d-flex pb-2" style={{ maxWidth: 45 }}>
            <EditableCell
              index={row.index}
              value={row.original.count}
              onChange={setCountProduct}
            />
          </div>
        );
      },
    },
    {
      Header: "Pris",
      accessor: "price", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <div className="d-flex pb-2" style={{ maxWidth: 50 }}>
            <EditableCell
              index={row.index}
              value={row.original.price}
              onChange={setPriceProduct}
            />
          </div>
        );
      },
    },
    {
      Header: "Total",
      accessor: "total", // String-based value accessors!
      width: 70,
      Cell: ({ row }: any) => {
        return (
          <p style={{ margin: 0 }}>
            {row.original.price * row.original.count} kr
          </p>
        );
      },
    },
    {
      accessor: "system",
      width: 60,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => removeProduct(row.index)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  const columnsMobile = [
    {
      Header: "Produkt",
      accessor: "name",
      width: 150,
      Cell: ({ row }: any) => {
        var text =
          row.original.name +
          (row.original.subProductName
            ? `, ${row.original.subProductName}`
            : "");
        return <TableCellText text={text} width={130} />;
      },
    },
    {
      Header: "Antall",
      accessor: "count",
      width: 60,
      Cell: ({ row }: any) => {
        return (
          <div className="d-flex pb-2 ps-1" style={{ maxWidth: 40 }}>
            <EditableCell
              index={row.index}
              value={row.original.count}
              onChange={setCountProduct}
            />
          </div>
        );
      },
    },
    {
      Header: "Pris",
      accessor: "price", // String-based value accessors!
      width: 55,
      Cell: ({ row }: any) => {
        return (
          <div className="d-flex pb-2" style={{ maxWidth: 50 }}>
            <EditableCell
              index={row.index}
              value={row.original.price}
              onChange={setPriceProduct}
            />
          </div>
        );
      },
    },
    {
      Header: "Total",
      accessor: "total", // String-based value accessors!
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <p style={{ margin: 0 }}>
            {row.original.price * row.original.count} kr
          </p>
        );
      },
    },
    {
      accessor: "system",
      width: 30,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => removeProduct(row.index)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  const subProductColumns = [
    {
      Header: "Type",
      accessor: "subProductName",
      width: 150,
    },
    {
      Header: "Art. nr.",
      accessor: "articleNumber",
      width: 100,
    },
  ];

  const testCustomer: NewOrder = {
    customerId: "1",
    orderPosts: [],
    catalog: 1,
    delivered: false,
    paid: false,
    orderedFromSupplier: false,
    notes: "",
    notification: false,
    notificationDate: new Date(),
  };

  const [newOrder, setOrder] = useState<NewOrder>(testCustomer);

  async function createOrder() {
    if (customer) {
      const orderPosts = productList;

      if (product && count && price) {
        if (subProduct) {
          orderPosts.push({
            productId: product.id,
            subProductId: subProduct.id,
            name: product.name,
            subProductName: subProduct.name,
            count: Number(count),
            price: Number(price),
            delivered: false,
            articleNumber: subProduct.articleNumber,
          });
        } else {
          orderPosts.push({
            productId: product.id,
            name: product.name,
            count: Number(count),
            price: Number(price),
            delivered: false,
            articleNumber: product.articleNumber,
          });
        }
      }
      const order: NewOrder = {
        customerId: customer.id,
        orderPosts: orderPosts,
        catalog: Number(catalog),
        delivered: delivered,
        paid: paid,
        orderedFromSupplier: orderedFromSupplier,
        notes: notes,
        notification: false,
        notificationDate: new Date(),
      };

      try {
        const request = await axios.post("orders", order);

        if (request.status === 201) {
          notify("Et nytt salg ble opprettet", true, "success");
          setOrder(request.data);
          addNewOrderText(selectedCustomer.label);
          setCount(1);
          setCatalog(0);
          setDelivered(false);
          setPaid(false);
          setShowNotes(false);
          setNotes("");
          setOrderedFromSupplier(false);
          setSelectedCustomer(null);
          setSelectedProduct(null);
          setProductList([]);
          setTotalPrice(0);
        }
      } catch (error) {
        notify("Det oppsto en feil ved opprettelse av salg", true, "error");
      }
    }
  }

  function addProduct() {
    if (count && product) {
      const newProductList = productList.concat(
        subProduct
          ? {
              productId: product.id,
              subProductId: subProduct.id,
              name: product.name,
              subProductName: subProduct.name,
              count: Number(count),
              price: Number(price),
              delivered: false,
              articleNumber: subProduct.articleNumber,
            }
          : {
              productId: product.id,
              name: product.name,
              count: Number(count),
              price: Number(price),
              articleNumber: product.articleNumber,
              delivered: false,
            }
      );
      setProductList(newProductList);
      setSelectedProduct(null);
      setSelectedSubProduct(null);
      setSubProduct(undefined);
      setSortedSubProducts(null);
      setCount(1);
      setItemCount(itemCount + 1);
      setPrice(0);
      fetchProducts();
    }
  }

  function reCalculateTotalPrice() {
    const result = productList.reduce(
      (total, currentvalue) =>
        (total = total + currentvalue.count * currentvalue.price),
      0
    );
    setTotalPrice(result);
  }

  function removeProduct(index: number) {
    productList.splice(index, 1);
    setProductList([...productList]);
  }
  function setCountProduct(rowId: number, count: number) {
    productList[rowId].count = Number(count);
    setProductList([...productList]);
  }

  function setPriceProduct(rowId: number, price: number) {
    productList[rowId].price = Number(price);
    setProductList([...productList]);
  }

  async function createCustomer(customer: any) {
    var newCustomer: NewCustomer = {
      customerType: 1,
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      address2: "",
      postCode: "",
      postArea: "",
      otherInfo: "",
      phone: "",
    };
    var res: string[] = [];
    res = customer.split(" ");
    if (!res || res.length === 0) {
      return;
    } else if (res.length === 1) {
      newCustomer.firstName = res[0].trim();
    } else if (res.length === 2) {
      newCustomer.firstName = res[0].trim();
      newCustomer.lastName = res[1].trim();
    } else {
      let firstName = "";
      res.map((word: string, i: number) => {
        if (i + 1 < res.length) {
          firstName = firstName + " " + word;
        } else {
          newCustomer.lastName = word.trim();
        }
      });
      newCustomer.firstName = firstName.trim();
    }

    try {
      setWaitingForAPI(true);
      const request = await axios.post("customers", newCustomer);

      if (request.status === 201) {
        notify("En ny kunde ble opprettet", true, "success");
        fetchCustomers();
      }
    } catch (error) {
      notify("Det oppsto en feil ved opprettelse av kunde", true, "error");
    } finally {
      setWaitingForAPI(false);
    }
  }

  async function createProduct(product: string, articleNr: string) {
    const newProduct: NewProduct = {
      productName: product,
      articleNumber: articleNr,
      subProducts: tempSubProducts,
    };

    try {
      setWaitingForAPI(true);
      const request = await axios.post("products", newProduct);

      if (request.status === 201) {
        notify("Et nytt product ble opprettet", true, "success");
        fetchProducts();
      }
    } catch (error) {
      notify("Det oppsto en feil ved opprettelse av produkt", true, "error");
    } finally {
      setWaitingForAPI(false);
    }
  }

  async function createSubProduct(subProduct: string, articleNr: string) {
    if (product) {
      const newSubProduct: NewSubProduct = {
        subProductName: subProduct,
        articleNumber: articleNr,
        productId: product.id,
      };

      try {
        setWaitingForAPI(true);
        const request = await axios.post("subproducts", newSubProduct);

        if (request.status === 201) {
          notify("En ny product sub kategori ble opprettet", true, "success");
          fetchProducts();
        }
      } catch (error) {
        notify(
          "Det oppsto en feil ved opprettelse av sub produkt",
          true,
          "error"
        );
      } finally {
        setWaitingForAPI(false);
      }
    }
  }

  function addNewOrderText(customerName: string) {
    if (!createdOrders) {
      let orderText = "Ordre lagt inn på " + customerName;
      let orders = [];
      orders.push(orderText);
      setCreatedOrders(orders);
    } else {
      let orderText = "Ordre lagt inn på " + customerName;
      let tempOrders = createdOrders;
      tempOrders.push(orderText);
      setCreatedOrders(tempOrders);
    }
  }

  function tryingToGoBack() {
    if (productList.length > 0) {
      setOpen(true);
    } else {
      history.goBack();
    }
  }

  function closeProductModal() {
    setOpenArticleDialog(false);
    setAddSubProducts(false);
    setArticleNumber("");
    setTempSubProductArticleNumber("");
    setTempSubProductName("");
    setTempSubProducts([]);
  }

  if (!finnishedLoading) {
    return null;
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row justify-content-center pt-4">
        <div className="header-container">
          <h1 style={{ fontWeight: 100, fontSize: 24, textAlign: "left" }}>
            Legg til nytt salg
          </h1>
          <Breakpoint medium up>
            <Route
              render={() => (
                <IconButton
                  style={{
                    color: "#906088",
                    cursor: "pointer",
                    fontSize: 40,
                  }}
                  onClick={() => tryingToGoBack()}
                >
                  <ArrowBackIcon fontSize="inherit" />
                </IconButton>
              )}
            />
          </Breakpoint>
          <Breakpoint small down>
            <Route
              render={() => (
                <IconButton
                  style={{
                    color: "#906088",
                    cursor: "pointer",
                    fontSize: 30,
                  }}
                  onClick={() => tryingToGoBack()}
                >
                  <ArrowBackIcon fontSize="inherit" />
                </IconButton>
              )}
            />
          </Breakpoint>
        </div>
      </div>
      <div className="new-order-container grow-1 flex-wrap flex-column m-auto ">
        <div className="d-flex flex-row flex-grow-1">
          <div className="d-flex flex-column flex-wrap justify-content-center mt-1 w-100">
            {allCustomers?.length === 0 ? (
              <div className="new-order-info-text">
                <p>
                  Opprette kunder direkte ved å skrive inn{" "}
                  <strong>Fornavn</strong> og <strong>Etternavn</strong> i
                  søkefeltet og deretter trykke opprett kunde!
                </p>
              </div>
            ) : null}
            <Breakpoint medium up>
              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="new-order-section-icon">
                    <PermIdentityOutlinedIcon fontSize="inherit" />
                  </div>
                  <div className="new-order-section-content">
                    <div className="d-flex flex-column flex-grow-1">
                      {allCustomers?.length !== 0 ?? (
                        <p>
                          Skriv inn navnet til kunden og trykk opprett dersom
                          den er ny.
                        </p>
                      )}

                      <div className="d-flex flex-column">
                        <h3 style={{ fontWeight: 600, fontSize: 16 }}>Kunde</h3>
                        <div className="new-order-select">
                          <SearchableDropdown
                            value={selectedCustomer}
                            items={customers}
                            typeText="kunde"
                            onChange={(opt) => {
                              setCustomer(opt);
                              setSelectedCustomer(opt);
                              setTempCustomerInput(opt.firstName);
                            }}
                            onCreateOption={(opt2) => {
                              if (!waitingForAPI) {
                                setWaitingForAPI(true);
                                createCustomer(opt2);
                              } else {
                              }
                            }}
                            onInputChange={(text) => {
                              if (
                                (tempCustomerInput &&
                                  tempCustomerInput.length < 2) ||
                                text !== ""
                              ) {
                                setTempCustomerInput(text);
                              }
                            }}
                            inputValue={tempCustomerInput}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="new-order-section ms-2 w-25 ">
                  <div className="new-order-section-content flex-grow-1 pb-3">
                    <InputText
                      inputTitle="Katalog"
                      value={catalog !== 0 ? catalog : ""}
                      type="number"
                      width={80}
                      onChange={(e: any) => setCatalog(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Breakpoint>
            <Breakpoint small down>
              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="new-order-section-content flex-grow-1">
                    <div className="d-flex flex-column flex-grow-1">
                      {allCustomers?.length !== 0 ?? (
                        <p className="mx-2 text-center">
                          Skriv inn navnet til kunden og trykk opprett dersom
                          den er ny.
                        </p>
                      )}
                      <div className="d-flex flex-column w-100 justify-content-center">
                        <h3
                          style={{
                            fontWeight: 600,
                            fontSize: 16,
                            textAlign: "center",
                          }}
                        >
                          Kunde
                        </h3>
                        <div
                          className="new-order-select"
                          style={{ width: 225, alignSelf: "center" }}
                        >
                          <SearchableDropdown
                            value={selectedCustomer}
                            items={customers}
                            typeText="kunde"
                            width={225}
                            onChange={(opt) => {
                              setCustomer(opt);
                              setSelectedCustomer(opt);
                              setTempCustomerInput(opt.firstName);
                            }}
                            onCreateOption={(opt2) => {
                              if (!waitingForAPI) {
                                setWaitingForAPI(true);
                                createCustomer(opt2);
                              } else {
                              }
                            }}
                            onInputChange={(text) => {
                              if (
                                (tempCustomerInput &&
                                  tempCustomerInput.length < 2) ||
                                text !== ""
                              ) {
                                setTempCustomerInput(text);
                              }
                            }}
                            inputValue={tempCustomerInput}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="new-order-section-content flex-grow-1 pb-3">
                    <InputText
                      inputTitle="Katalog"
                      value={catalog !== 0 ? catalog : ""}
                      type="number"
                      width={80}
                      onChange={(e: any) => setCatalog(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </Breakpoint>
            {allProducts?.length === 0 ? (
              <div className="new-order-info-text">
                <p>
                  Opprette produkter direkte ved å skrive inn{" "}
                  <strong>navnet på produktet</strong> i søkefeltet og deretter
                  trykke opprett produkt!
                </p>
              </div>
            ) : null}

            <Breakpoint medium up>
              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="new-order-section-icon">
                    <DiamondIcon fontSize="inherit" />
                  </div>
                  <div className="new-order-section-content">
                    <div className="d-flex flex-column flex-grow-1">
                      <p style={{ marginBottom: -10 }}>
                        Skriv inn navnet på produktet eller art.nr.
                      </p>
                      <div className="d-flex flex-row flex-grow-1 align-items-center">
                        <div className="d-flex flex-column">
                          <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                            Produkt
                          </h3>
                          <div className="d-flex flex-row">
                            <div className="new-order-select">
                              <SearchableDropdown
                                noFilter={true}
                                value={selectedProduct}
                                items={products}
                                typeText="produkt"
                                onChange={(opt) => {
                                  setPreventSearch(true);
                                  var selectedProduct = allProducts.find(
                                    (p) => p.id === opt.id
                                  );
                                  setProduct(
                                    allProducts.find((p) => p.id === opt.id)
                                  );
                                  if (
                                    selectedProduct &&
                                    !R.isNil(selectedProduct.defaultPrice)
                                  ) {
                                    setPrice(selectedProduct?.defaultPrice);
                                  }
                                  setSelectedProduct(opt);
                                  setSelectedSubProduct(null);
                                  setTempProductInput("");
                                  sortSubProducts(opt.id);
                                }}
                                onCreateOption={(opt2) => {
                                  setOpenArticleDialog(true);
                                }}
                                onInputChange={(text) => {
                                  if (
                                    (tempProductInput &&
                                      tempProductInput.length < 2) ||
                                    text !== ""
                                  ) {
                                    setTempProductInput(text);
                                  }
                                }}
                                inputValue={tempProductInput}
                              />
                            </div>
                            {sortedSubProducts ? (
                              <div className="new-order-select">
                                <SearchableDropdown
                                  value={selectedSubProduct}
                                  items={sortedSubProducts}
                                  typeText="type"
                                  onChange={(opt) => {
                                    setSubProduct(
                                      subProductList.find(
                                        (p) => p.id === opt.id
                                      )
                                    );
                                    setSelectedSubProduct(opt);
                                    setTempSubProductInput(opt.productName);
                                  }}
                                  onCreateOption={(opt2) => {
                                    setOpenSubProductDialog(true);
                                  }}
                                  onInputChange={(text) => {
                                    if (
                                      (tempSubProductInput &&
                                        tempSubProductInput.length < 2) ||
                                      text !== ""
                                    ) {
                                      setTempSubProductInput(text);
                                    }
                                  }}
                                  inputValue={tempSubProductInput}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="d-flex flex-row mt-4">
                          <div className="">
                            <InputText
                              inputTitle="ANTALL"
                              value={count !== 0 ? count : ""}
                              type="number"
                              width={60}
                              onChange={(e: any) => setCount(e.target.value)}
                            />
                          </div>
                          <div className="px-3">
                            <InputText
                              inputTitle="PRIS"
                              value={price !== 0 ? price : ""}
                              type="number"
                              width={60}
                              onChange={(e: any) => setPrice(e.target.value)}
                            />
                          </div>
                          <div className="create-order-button-container">
                            <MediumButton
                              buttonText="Legg til"
                              onClick={() => addProduct()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Breakpoint>
            <Breakpoint small down>
              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="d-flex flex-column w-100 justify-content-center">
                    <p className="mx-2 text-center">
                      Skriv inn navnet på produktet eller art.nr.
                    </p>
                    <h3
                      style={{
                        fontWeight: 600,
                        fontSize: 16,
                        textAlign: "center",
                      }}
                    >
                      Produkt
                    </h3>
                    <div
                      className="new-order-select mb-2"
                      style={{ width: 225, alignSelf: "center" }}
                    >
                      <SearchableDropdown
                        noFilter={true}
                        value={selectedProduct}
                        items={products}
                        typeText="produkt"
                        width={225}
                        onChange={(opt) => {
                          setPreventSearch(true);
                          var selectedProduct = allProducts.find(
                            (p) => p.id === opt.id
                          );
                          setProduct(allProducts.find((p) => p.id === opt.id));
                          if (
                            selectedProduct &&
                            !R.isNil(selectedProduct.defaultPrice)
                          ) {
                            setPrice(selectedProduct.defaultPrice);
                          }
                          setSelectedProduct(opt);
                          setSelectedSubProduct(null);
                          setTempProductInput("");
                          sortSubProducts(opt.id);
                        }}
                        onCreateOption={(opt2) => {
                          setOpenArticleDialog(true);
                        }}
                        onInputChange={(text) => {
                          if (
                            (tempProductInput && tempProductInput.length < 2) ||
                            text !== ""
                          ) {
                            setTempProductInput(text);
                          }
                        }}
                        inputValue={tempProductInput}
                      />
                    </div>
                    {sortedSubProducts ? (
                      <div
                        className="new-order-select"
                        style={{ width: 225, alignSelf: "center" }}
                      >
                        <SearchableDropdown
                          value={selectedSubProduct}
                          items={sortedSubProducts}
                          typeText="type"
                          width={225}
                          onChange={(opt) => {
                            setSubProduct(
                              subProductList.find((p) => p.id === opt.id)
                            );
                            setSelectedSubProduct(opt);
                            setTempSubProductInput(opt.productName);
                          }}
                          onCreateOption={(opt2) => {
                            setOpenSubProductDialog(true);
                          }}
                          onInputChange={(text) => {
                            if (
                              (tempSubProductInput &&
                                tempSubProductInput.length < 2) ||
                              text !== ""
                            ) {
                              setTempSubProductInput(text);
                            }
                          }}
                          inputValue={tempSubProductInput}
                        />
                      </div>
                    ) : null}

                    <div className="d-flex flex-row justify-content-center">
                      <div className="">
                        <InputText
                          inputTitle="ANTALL"
                          value={count !== 0 ? count : ""}
                          type="number"
                          width={60}
                          onChange={(e: any) => setCount(e.target.value)}
                        />
                      </div>
                      <div className="ps-3">
                        <InputText
                          inputTitle="PRIS"
                          value={price !== 0 ? price : ""}
                          type="number"
                          width={60}
                          onChange={(e: any) => setPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="create-order-button-container">
                      <MediumButton
                        buttonText="Legg til"
                        onClick={() => addProduct()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Breakpoint>
            {productList.length > 0 ? (
              <div>
                <Breakpoint medium up>
                  <div className="new-order-row">
                    <div className="new-order-section flex-grow-1">
                      <div className="new-order-section-icon">
                        <ShoppingCartIcon fontSize="inherit" />
                      </div>
                      <div className="new-order-section-content">
                        <div className="d-flex flex-column">
                          <h2
                            style={{
                              flexGrow: 1,
                              fontWeight: 100,
                              fontSize: 20,
                              margin: 0,
                            }}
                          >
                            Produkter i ordren
                          </h2>
                          <DataTable columns={columns} data={productList} />
                          <div
                            style={{
                              flexGrow: 1,
                              fontSize: 16,
                              marginTop: 15,
                              paddingRight: 75,
                              textAlign: "end",
                            }}
                          >
                            <p>
                              <strong>Totalpris:</strong> {totalPrice} kr
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Breakpoint>
                <Breakpoint small down>
                  <div className="new-order-row">
                    <div className="new-order-section-content">
                      <div className="d-flex flex-column">
                        <h2
                          style={{
                            flexGrow: 1,
                            fontWeight: 100,
                            fontSize: 20,
                            margin: 0,
                          }}
                        >
                          Produkter i ordren
                        </h2>
                        <DataTable columns={columnsMobile} data={productList} />
                        <div
                          style={{
                            flexGrow: 1,
                            fontSize: 16,
                            marginTop: 15,
                            paddingRight: 35,
                            textAlign: "end",
                          }}
                        >
                          <p>
                            <strong>Totalpris:</strong> {totalPrice} kr
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Breakpoint>
              </div>
            ) : null}
            <Breakpoint medium up>
              <>
                {showNotes ? (
                  <>
                    <div className="new-order-row">
                      <div className="new-order-section flex-grow-1">
                        <div className="new-order-section-icon">
                          <LocalShippingIcon fontSize="inherit" />
                        </div>
                        <div className="new-order-section-content">
                          <div className="new-order-form-group">
                            <div className="new-order-form-input">
                              <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                                Bestilt
                              </h3>
                              <CheckBox
                                checked={orderedFromSupplier}
                                onChange={(e: any) =>
                                  setOrderedFromSupplier(e.target.checked)
                                }
                              />
                            </div>
                            <div className="new-order-form-input">
                              <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                                Levert
                              </h3>
                              <CheckBox
                                checked={delivered}
                                onChange={(e: any) =>
                                  setDelivered(e.target.checked)
                                }
                              />
                            </div>
                            <div className="new-order-form-input">
                              <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                                Betalt
                              </h3>
                              <CheckBox
                                checked={paid}
                                onChange={(e: any) => setPaid(e.target.checked)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="new-order-row">
                      <div className="new-order-section flex-grow-1 justify-content-center">
                        <TextArea
                          inputTitle="Notat"
                          value={notes}
                          type="text"
                          width={400}
                          onChange={(e: any) => setNotes(e.target.value)}
                        />
                      </div>
                      <div className="new-order-section ms-2 w-25 ">
                        <div className="new-order-section-content flex-grow-1">
                          <div className="create-order-button-container">
                            <MediumButton
                              buttonText="Opprett"
                              onClick={() => createOrder()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="new-order-row">
                    <div className="new-order-section flex-grow-1">
                      <div className="new-order-section-icon">
                        <LocalShippingIcon fontSize="inherit" />
                      </div>
                      <div className="new-order-section-content">
                        <div className="new-order-form-group">
                          <div className="new-order-form-input">
                            <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                              Bestilt
                            </h3>
                            <CheckBox
                              checked={orderedFromSupplier}
                              onChange={(e: any) =>
                                setOrderedFromSupplier(e.target.checked)
                              }
                            />
                          </div>
                          <div className="new-order-form-input">
                            <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                              Levert
                            </h3>
                            <CheckBox
                              checked={delivered}
                              onChange={(e: any) =>
                                setDelivered(e.target.checked)
                              }
                            />
                          </div>
                          <div className="new-order-form-input">
                            <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                              Betalt
                            </h3>
                            <CheckBox
                              checked={paid}
                              onChange={(e: any) => setPaid(e.target.checked)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="new-order-section ms-2 px-4">
                      <div className="d-flex flex-row align-items-center">
                        <div className="d-flex flex-column">
                          <div className="d-flex w-50 flex-row m-auto">
                            <IconButton
                              style={{
                                color: "#906088",
                                fontSize: 30,
                              }}
                              aria-label="Add notes"
                              onClick={() => {
                                setShowNotes(true);
                              }}
                            >
                              <AddIcon fontSize="inherit" />
                            </IconButton>
                          </div>
                          <h5 className="text-center">Legg til et notat:</h5>
                        </div>
                      </div>
                    </div>
                    <div className="new-order-section ms-2 w-25 ">
                      <div className="new-order-section-content flex-grow-1">
                        <div className="create-order-button-container">
                          <MediumButton
                            buttonText="Opprett"
                            onClick={() => createOrder()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </Breakpoint>
            <Breakpoint small down>
              {showNotes ? (
                <div className="new-order-row">
                  <div className="new-order-section-no-padding pb-4 flex-grow-1">
                    <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                      <TextArea
                        inputTitle="Notat"
                        value={notes}
                        type="text"
                        width={400}
                        onChange={(e: any) => setNotes(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="new-order-row">
                  <div className="new-order-section-no-padding flex-grow-1">
                    <div className="d-flex flex-grow-1 justify-content-center align-items-center">
                      <IconButton
                        style={{
                          color: "#906088",
                          fontSize: 30,
                        }}
                        aria-label="Add notes"
                        onClick={() => {
                          setShowNotes(true);
                        }}
                      >
                        <AddIcon fontSize="inherit" />
                      </IconButton>

                      <h5 className="text-center m-0">Legg til et notat:</h5>
                    </div>
                  </div>
                </div>
              )}
              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="new-order-section-content">
                    <div className="new-order-form-group">
                      <div className="new-order-form-input">
                        <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                          Bestilt
                        </h3>
                        <CheckBox
                          checked={orderedFromSupplier}
                          onChange={(e: any) =>
                            setOrderedFromSupplier(e.target.checked)
                          }
                        />
                      </div>
                      <div className="new-order-form-input">
                        <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                          Levert
                        </h3>
                        <CheckBox
                          checked={delivered}
                          onChange={(e: any) => setDelivered(e.target.checked)}
                        />
                      </div>
                      <div className="new-order-form-input">
                        <h3 style={{ fontWeight: 600, fontSize: 16 }}>
                          Betalt
                        </h3>
                        <CheckBox
                          checked={paid}
                          onChange={(e: any) => setPaid(e.target.checked)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="new-order-row">
                <div className="new-order-section flex-grow-1">
                  <div className="new-order-section-content flex-grow-1">
                    <div className="create-order-button-container">
                      <MediumButton
                        buttonText="Opprett"
                        onClick={() => createOrder()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Breakpoint>
          </div>
        </div>
        <div>
          {createdOrders && (
            <div>
              <h2>Nye ordre opprettet:</h2>
            </div>
          )}
        </div>
        <div>
          {createdOrders &&
            createdOrders.map((order, key) => <p key={key}>{order}</p>)}
        </div>
        <Dialog
          open={openArticleDialog}
          onClose={() => closeProductModal()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ backgroundColor: "#c0e9d7", color: "#3C5663" }}
          >
            {
              "Her kan du legg til et artikkelnummer eller underkategorier (eks farge eller type)?"
            }
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#c0e9d7", color: "#FFF" }}>
            <DialogContentText
              id="alert-dialog-description"
              style={{ fontWeight: 600 }}
            >
              Produktnavn: {tempProductInput}
            </DialogContentText>
            <InputText
              inputTitle="Artikkelnummer"
              value={articleNumber}
              type="text"
              onChange={(e: any) => setArticleNumber(e.target.value)}
            />
            {addSubProducts && (
              <div>
                <div style={{ color: "#3C5663" }}>
                  <h3
                    style={{
                      flexGrow: 1,
                      fontWeight: 600,
                      fontSize: 18,
                      padding: 0,
                      marginBottom: 30,
                      marginTop: 50,
                      color: "#3C5663",
                    }}
                  >
                    Underkategorier:
                  </h3>
                  {tempSubProducts.length > 0 && (
                    <DataTable
                      columns={subProductColumns}
                      data={tempSubProducts}
                    />
                  )}
                </div>
                <Row>
                  <Col lg={4} style={{ marginRight: 10 }}>
                    <InputText
                      inputTitle="Navn"
                      value={tempSubProductName}
                      onChange={(e: any) =>
                        setTempSubProductName(e.target.value)
                      }
                    />
                  </Col>
                  <Col lg={4} style={{ marginRight: 10 }}>
                    <InputText
                      inputTitle="Artikkelnummer"
                      value={tempSubProductArticleNumber}
                      onChange={(e: any) =>
                        setTempSubProductArticleNumber(e.target.value)
                      }
                    />
                  </Col>
                  <Col lg={3}>
                    <div className="create-product-button-container">
                      <MediumButton
                        buttonText="legg til"
                        onClick={() => createTempSubProduct()}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </DialogContent>
          <DialogActions
            style={{ backgroundColor: "#c0e9d7", paddingRight: 20 }}
          >
            <Button
              onClick={() => {
                setAddSubProducts((openSubPanel) => !openSubPanel);
              }}
              style={{
                backgroundColor: "#906088",
                color: "#FFF",
              }}
            >
              {addSubProducts ? "Skjul underkategorier" : "Vis underkategorier"}
            </Button>
            <Button
              onClick={() => {
                if (!waitingForAPI) {
                  setWaitingForAPI(true);
                  createProduct(tempProductInput, articleNumber);
                  closeProductModal();
                }
              }}
              style={{
                backgroundColor: "#906088",
                color: "#FFF",
              }}
            >
              Opprett
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSubProductDialog}
          onClose={() => setOpenSubProductDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Vil du legg til et artikkelnummer"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Type: {tempSubProductInput}
            </DialogContentText>
            <InputText
              inputTitle="Artikkelnummer"
              value={articleNumber}
              type="text"
              onChange={(e: any) => setArticleNumber(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setTempProductInput("");
                setOpenSubProductDialog(false);
                setArticleNumber("");
              }}
              color="primary"
            >
              Hopp over
            </Button>
            <Button
              onClick={() => {
                if (!waitingForAPI) {
                  setWaitingForAPI(true);
                  createSubProduct(tempSubProductInput, "");
                  setOpenSubProductDialog(false);
                  setArticleNumber("");
                }
              }}
              color="primary"
            >
              Nei
            </Button>
            <Button
              onClick={() => {
                if (!waitingForAPI) {
                  setWaitingForAPI(true);
                  createSubProduct(tempSubProductInput, articleNumber);
                  setOpenSubProductDialog(false);
                  setArticleNumber("");
                }
              }}
              color="primary"
              autoFocus
            >
              Ja
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Salget er ikke lagret"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Er du sikker på at vil gå tilbake uten å opprette salget
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Nei
            </Button>
            <Button
              onClick={() => history.push("/orders")}
              color="primary"
              autoFocus
            >
              Ja
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default NewOrderPage;
