import React, { useState, useEffect } from "react";
import MediumButton from "../../components/MediumButton";
import InputText from "../../components/InputText";
import "./EditCustomerPage.scss";
import { Route, useParams, useHistory } from "react-router-dom";
import LargeButton from "../../components/LargeButton";
import { Breakpoint } from "react-socks";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "axios";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions } from "store/store";

const EditCustomerPage: React.FC = () => {
  const [forename, setForename] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [otherInfo, setOtherInfo] = useState("");
  const [customerId, setcustomerId] = useState("");
  const { id } = useParams<ParamTypes>();
  const history = useHistory();

  interface ParamTypes {
    id: string;
  }

  const SetLoading = useStoreActions(
    (actions) => actions.status.setAccountPageLoading
  );

  useEffect(() => {
    SetLoading(true);
    fetchCustomer();
  }, []);

  async function fetchCustomer() {
    try {
      const request = await axios.get("customers/" + id);

      if (request.status === 200) {
        var customer = request.data;
        setcustomerId(customer.id);
        setForename(customer.firstName);
        setLastname(customer.lastName);
        setPhone("");
        setEmail(customer.email);
        setAddress(customer.address);
        setOtherInfo(customer.otherInfo);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av kunde", true, "error");
    } finally {
      SetLoading(false);
    }
  }

  const updateOptions = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };

  async function updateCustomer(customer: any, id: string) {
    try {
      const request = await axios.patch(
        `customers/${id}`,
        customer,
        updateOptions
      );

      if (request.status === 200) {
        notify("Kunden ble oppdatert", true, "success");
      }
    } catch (error) {
      notify("Det oppsto en feil ved oppdatering av kunde", true, "error");
    }
  }

  function update() {
    let updatedCustomer = {
      firstName: forename,
      lastName: lastname,
      email: email,
      address: address,
      otherInfo: otherInfo,
      phone: phone,
    };
    updateCustomer(updatedCustomer, customerId).then(() => {
      history.push("/customers");
    });
  }

  function tryingToGoBack() {
    history.goBack();
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row justify-content-center pt-4 ms-2">
        <div className="d-flex flex-row" style={{ width: 800 }}>
          <h1 style={{ flexGrow: 1, fontWeight: 100, fontSize: 24 }}>
            Oppdater kunde
          </h1>
          <Breakpoint medium up>
            <Route
              render={() => (
                <LargeButton
                  buttonText="Tilbake"
                  onClick={() => tryingToGoBack()}
                />
              )}
            />
          </Breakpoint>
          <Breakpoint medium down>
            <Route
              render={() => (
                <IconButton
                  style={{
                    color: "#906088",
                  }}
                  onClick={() => tryingToGoBack()}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
            />
          </Breakpoint>
        </div>
      </div>
      <div className="d-flex grow-1 flex-wrap flex-column m-auto new-customer-form">
        <div className="d-flex flex-row flex-wrap justify-content-center">
          <div className="new-customer-form-input">
            <InputText
              inputTitle="Fornavn"
              value={forename}
              onChange={(e: any) => setForename(e.target.value)}
            />
          </div>
          <div className="new-customer-form-input">
            <InputText
              inputTitle="Etternavn"
              value={lastname}
              onChange={(e: any) => setLastname(e.target.value)}
            />
          </div>
          <div className="new-customer-form-input">
            <InputText
              inputTitle="Telefon"
              value={phone}
              onChange={(e: any) => setPhone(e.target.value)}
            />
          </div>
          <div className="new-customer-form-input">
            <InputText
              inputTitle="Epost"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </div>
          <div className="new-customer-form-input">
            <InputText
              inputTitle="Adresse"
              value={address}
              onChange={(e: any) => setAddress(e.target.value)}
            />
          </div>
          <div className="new-customer-form-input">
            <InputText
              inputTitle="Mer info"
              value={otherInfo}
              onChange={(e: any) => setOtherInfo(e.target.value)}
            />
          </div>
        </div>
        <div className="create-customer-button-container">
          <MediumButton buttonText="Lagre" onClick={() => update()} />
        </div>
      </div>
    </div>
  );
};

export default EditCustomerPage;
