import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";

import { msalConfig } from "common/authentication/authConfig";

const authProvider = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = authProvider.getAllAccounts();
if (accounts.length > 0) {
  console.log("all accounts");
  console.log(accounts);
  if (accounts.length > 1) {
    let correctAccount = accounts.find(
      (x) => x.environment === process.env.REACT_APP_KNOW_AUTHORITY
    );
    console.log("selected account");
    console.log(correctAccount);
    if (correctAccount) {
      authProvider.setActiveAccount(correctAccount);
    }
  } else {
    authProvider.setActiveAccount(accounts[0]);
  }
}

authProvider.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    authProvider.setActiveAccount(payload.account);
  }
});

export default authProvider;
