import React, { useCallback, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import "./MobileMenu.scss";

type MenuProps = {
  open: boolean;
  setOpen: any;
  refBurger: any;
};
const MobileMenu: React.FC<MenuProps> = ({ open, setOpen, refBurger }) => {
  const ref = useRef(null);
  const history = useHistory();

  const escapeListener = useCallback((e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setOpen(!open);
    }
  }, []);

  function redirect(url: string) {
    setOpen(false);
    history.push(url);
  }

  return (
    <>
      {open ? (
        <div className="mobile-menu-container">
          <div
            className="mobile-menu-item"
            onClick={() => redirect("/my-page")}
          >
            <span
              role="img"
              aria-label="home"
              style={{ width: 60, display: "inline-block" }}
            >
              &#x1F3E0;
            </span>
            Min side
          </div>
          <div className="mobile-menu-item" onClick={() => redirect("/orders")}>
            <span
              role="img"
              aria-label="orders"
              style={{ width: 60, display: "inline-block" }}
            >
              &#x1F4B0;
            </span>
            Salg
          </div>
          <div
            className="mobile-menu-item"
            onClick={() => redirect("/customers")}
          >
            <span
              role="img"
              aria-label="customers"
              style={{ width: 60, display: "inline-block" }}
            >
              &#128587;
            </span>
            Kunder
          </div>
          <div
            className="mobile-menu-item"
            onClick={() => redirect("/products")}
          >
            <span
              role="img"
              aria-label="products"
              style={{ width: 60, display: "inline-block" }}
            >
              &#128132;
            </span>
            Produkter
          </div>
          {/* <div className="menu-item" onClick={() => redirect("/delivery")}>
            <span
              role="img"
              aria-label="delivery"
              style={{ width: 60, display: "inline-block" }}
            >
              &#128230;
            </span>
            Levering
          </div> */}
          <div
            className="mobile-menu-item"
            onClick={() => redirect("/feedback")}
          >
            <span
              role="img"
              aria-label="error"
              style={{ width: 60, display: "inline-block" }}
            >
              &#128296;
            </span>
            Feil og forbedringer
          </div>
        </div>
      ) : null}
    </>
  );
};

type StyledMenuProps = {
  open: boolean;
};

const StyledMenu = styled.nav<StyledMenuProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.primaryLight};
  height: 100vh;
  z-index: 4;
  text-align: left;
  padding: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    justify-content: start;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.primaryDark};
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1rem;
      text-align: center;
      padding: 1rem 0;
    }

    &:hover {
      color: ${({ theme }) => theme.primaryHover};
    }
  }
`;

export default MobileMenu;
