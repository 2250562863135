import InputText from "components/InputText";
import React, { useState, useEffect } from "react";

type MemoProps = {
  value: number;
  index: number;
  width?: number;
  inputTitle?: string;
  type?: string;
  onChange: (value: number, index: number) => void;
};

const EditableCell = React.memo<MemoProps>(
  ({
    value,
    index,
    width = 50,
    inputTitle = "",
    type = "number",
    onChange,
  }) => {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
      if (localValue != value) {
        let timer = setTimeout(() => {
          onChange(index, localValue);
        }, 1500);

        return () => clearTimeout(timer);
      }
    }, [localValue]);

    return (
      <InputText
        inputTitle={inputTitle}
        value={localValue}
        type={type}
        width={width}
        onChange={(e: any) => {
          setLocalValue(e.target.value);
        }}
      />
    );
  }
);

export default EditableCell;
