import { AccountInfo } from "@azure/msal-browser";
import { action, Action } from "easy-peasy";

export interface AuthModel {
  isAuthenticated: boolean;
  user: AccountInfo | null;
  localCacheChecked: boolean;
  setActiveAccount: Action<this, AccountInfo>;
  setCacheChecked: Action<this, boolean>;
  logOut: Action<this>;
}

export type AzureAccountIdTokenClaims = {
  aud: string;
  at_hash: string;
  emails: string[];
  auth_time: string;
  exp: string;
  family_name: string;
  given_name: string;
  iat: number;
  idp: string;
  iss: string;
  nbf: number;
  nonce: string;
  sub: string;
  tfp: string;
  ver: string;
};

export const authentication: AuthModel = {
  isAuthenticated: false,
  user: null,
  localCacheChecked: false,
  setActiveAccount: action((state, payload) => {
    state.user = payload;
    state.isAuthenticated = true;
    state.localCacheChecked = true;
  }),
  setCacheChecked: action((state, payload) => {
    state.localCacheChecked = payload;
  }),
  logOut: action((state) => {
    state.user = null;
    state.isAuthenticated = false;
    state.localCacheChecked = false;
  }),
};
