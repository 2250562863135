import "./LoadingMain.scss";
import React from "react";
import { Breakpoint } from "react-socks";

const LoadingMain: React.FC = () => {
  return (
    <div>
      <div className="middle">
        <div className="loading-container">
          <Breakpoint small down>
            <h1 className="loading-header-text">MITT KUNDESYSTEM</h1>
          </Breakpoint>
          <div className="d-flex justify-content-center">
            <div className="bar bar1"></div>
            <div className="bar bar2"></div>
            <div className="bar bar3"></div>
            <div className="bar bar4"></div>
            <div className="bar bar5"></div>
            <div className="bar bar6"></div>
            <div className="bar bar7"></div>
            <div className="bar bar8"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoadingMain;
