import React from "react";
import Checkbox from "@material-ui/core/Checkbox";

type CheckBoxProps = {
  checked: boolean;
  onChange: any;
};

const CheckBox: React.FC<CheckBoxProps> = ({ checked, onChange }) => {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      color="default"
      inputProps={{ "aria-label": "secondary checkbox" }}
      style={{
        color: "#906088",
        zIndex: 0
      }}
    />
  );
};

export default CheckBox;
