import React, { useEffect, useState } from "react";
import InputText from "../../components/InputText";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { notify } from "common/utils/notify/notifyFunction";
import "./create-user.scss";
import MediumButtonLong from "components/MediumButtonLong";
import { useStoreActions, useStoreState } from "store/store";
import SystemModal from "pages/create-user/components/SystemModal";
import SearchableDropdown from "components/SearchableDropdown";
import { System } from "../../types/System";
import { Breakpoint } from "react-socks";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";

const CreateUserPage: React.FC = () => {
  const [initialDataLoading, setInitialDataLoading] = useState<boolean>(true);
  const [firstName, setForename] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [postCode, setPostCode] = useState("");
  const [postArea, setPostArea] = useState("");
  const [systemModalOpen, setSystemModalOpen] = React.useState(false);
  const [systems, setSystems] = useState<any>(null);
  const [selectedSystem, setSelectedSystem] = useState<any>();
  const [system, setSystem] = useState<System>();
  const [tempSystemInput, setTempSystemInput] = useState<string>("");
  const history = useHistory();

  const getAccount = useStoreActions((state) => state.account.getAccountThunk);
  const userAccount = useStoreState((state) => state.account.user);

  const SetLoading = useStoreActions(
    (actions) => actions.status.setAccountPageLoading
  );
  const pageLoading = useStoreState((state) => state.status.pageLoading);

  useEffect(() => {
    if (userAccount?.isOnboarded) {
      history.push("/");
    }
  }, [userAccount]);

  useEffect(() => {
    fetchSystems();
  }, []);

  useEffect(() => {
    checkForCreatedSystem();
  }, [systems]);

  async function fetchSystems() {
    setInitialDataLoading(true);
    try {
      const request = await axios.get("systems");

      if (request.status === 200) {
        let allSystems = request.data.map((system: System) => {
          return {
            label: system.name,
            value: system.id,
            id: system.id,
          };
        });
        setSystems(allSystems);
      }
    } catch (error) {
      notify(
        "Det oppsto en feil ved opplasting av firma alternativer",
        true,
        "error"
      );
    } finally {
      SetLoading(false);
      setInitialDataLoading(false);
    }
  }

  function checkForCreatedSystem() {
    if (tempSystemInput !== "") {
      let createdSystem = systems.find((p: any) => p.label === tempSystemInput);
      if (createdSystem) {
        setSelectedSystem(createdSystem);
        setSystem(createdSystem);
        setTempSystemInput("");
      }
    }
  }

  async function submitUserData() {
    if (!firstName || !lastname) {
      notify("Du må minst fylle ut fornavn og etternavn", true, "warning");
      return;
    }
    try {
      const userData = {
        firstName: firstName,
        lastName: lastname,
        email: email,
        address: address,
        postCode: postCode,
        postArea: postArea,
        systemId: selectedSystem.id,
      };

      const request = await axios.post("users", userData);

      if (request.status === 201) {
        notify("Brukeren din ble opprettet", true, "success");
        getAccount();
      }
    } catch (e) {
      notify("Det oppsto en feil ved opprettelse av bruker", true, "error");
    }
  }
  const closeSystemModal = () => {
    setSystemModalOpen(false);
  };

  if (pageLoading) {
    return null;
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row justify-content-center pt-4">
        <div
          className="d-flex flex-row justify-content-center"
          style={{ maxWidth: 800 }}
        >
          <h1 className="d-flex flex-row text-center fs-3 text-center">
            Vi trenger litt informasjon for å fortsette
          </h1>
        </div>
      </div>
      <div
        className="d-flex grow-1 flex-wrap flex-column m-auto "
        style={{ maxWidth: 532 }}
      >
        <div className="d-flex flex-column flex-wrap justify-content-center">
          <Breakpoint medium up>
            <div className="new-order-row">
              <div className="new-order-section flex-grow-1">
                <div className="new-order-section-icon">
                  <PermIdentityOutlinedIcon fontSize="inherit" />
                </div>
                <div className="new-order-section-content">
                  <div className="d-flex flex-column">
                    <h3
                      style={{
                        fontWeight: 600,
                        fontSize: 16,
                        marginBottom: -10,
                      }}
                    >
                      Navn
                    </h3>
                    <div className="d-flex flex-row flex-grow-1">
                      <div className="create-user-form-input">
                        <InputText
                          inputTitle="Fornavn"
                          value={firstName}
                          onChange={(e: any) => setForename(e.target.value)}
                        />
                      </div>
                      <div className="create-user-form-input">
                        <InputText
                          inputTitle="Etternavn"
                          value={lastname}
                          onChange={(e: any) => setLastname(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Breakpoint>
          <Breakpoint small down>
            <div className="new-order-row">
              <div className="new-order-section flex-grow-1">
                <div className="new-order-section-icon">
                  <PermIdentityOutlinedIcon fontSize="inherit" />
                </div>
                <div className="new-order-section-content">
                  <div className="d-flex flex-column">
                    <h3
                      style={{
                        fontWeight: 600,
                        fontSize: 16,
                        marginBottom: -10,
                      }}
                    >
                      Navn
                    </h3>
                    <div className="create-user-form-input">
                      <InputText
                        inputTitle="Fornavn"
                        value={firstName}
                        width={150}
                        onChange={(e: any) => setForename(e.target.value)}
                      />
                    </div>
                    <div className="create-user-form-input">
                      <InputText
                        inputTitle="Etternavn"
                        value={lastname}
                        width={150}
                        onChange={(e: any) => setLastname(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Breakpoint>
          <Breakpoint medium up>
            <div className="new-order-row">
              <div className="new-order-section flex-grow-1">
                <div className="new-order-section-icon">
                  <MailOutlineIcon fontSize="inherit" />
                </div>
                <div className="new-order-section-content">
                  <div className="d-flex flex-column">
                    <h3
                      style={{
                        fontWeight: 600,
                        fontSize: 16,
                        marginBottom: -10,
                      }}
                    >
                      Epost
                    </h3>
                    <div className="create-user-form-input">
                      <InputText
                        inputTitle="Epost"
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Breakpoint>
          <Breakpoint small down>
            <div className="new-order-row">
              <div className="new-order-section flex-grow-1">
                <div className="new-order-section-icon">
                  <MailOutlineIcon fontSize="inherit" />
                </div>
                <div className="new-order-section-content">
                  <div className="d-flex flex-column">
                    <h3
                      style={{
                        fontWeight: 600,
                        fontSize: 16,
                        marginBottom: -10,
                      }}
                    >
                      Epost
                    </h3>
                    <div className="create-user-form-input">
                      <InputText
                        inputTitle="Epost"
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Breakpoint>
          <Breakpoint medium up>
            <div className="new-order-row">
              <div className="new-order-section flex-grow-1">
                <div className="new-order-section-icon">
                  <WorkOutlineIcon fontSize="inherit" />
                </div>
                <div className="new-order-section-content">
                  <div className="d-flex flex-column">
                    <h3
                      style={{
                        fontWeight: 600,
                        fontSize: 16,
                        marginBottom: -10,
                      }}
                    >
                      Firma
                    </h3>
                    <div className="d-flex flex-row flex-grow-1 mt-4">
                      <SearchableDropdown
                        value={selectedSystem}
                        width={200}
                        items={systems}
                        typeText="Salgsfirma"
                        onChange={(opt) => {
                          setSystem(opt);
                          setSelectedSystem(opt);
                          setTempSystemInput(opt.firstName);
                        }}
                        onCreateOption={() => setSystemModalOpen(true)}
                        onInputChange={(text) => {
                          if (
                            (tempSystemInput && tempSystemInput.length < 2) ||
                            text !== ""
                          ) {
                            setTempSystemInput(text);
                          }
                        }}
                        inputValue={tempSystemInput}
                      />

                      <div className="ps-5">
                        <MediumButtonLong
                          buttonText="nytt salgsfirma"
                          onClick={() => setSystemModalOpen(true)}
                          width={200}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Breakpoint>

          <Breakpoint small down>
            <div className="new-order-row">
              <div className="new-order-section flex-grow-1">
                <div className="new-order-section-icon">
                  <WorkOutlineIcon fontSize="inherit" />
                </div>
                <div className="new-order-section-content flex-grow-1 justify-content-start">
                  <div className="d-flex flex-column">
                    <h3
                      style={{
                        fontWeight: 600,
                        fontSize: 16,
                        marginBottom: -10,
                      }}
                    >
                      Firma
                    </h3>
                    <div className="mt-4">
                      <SearchableDropdown
                        value={selectedSystem}
                        width={200}
                        items={systems}
                        typeText="Salgsfirma"
                        onChange={(opt) => {
                          setSystem(opt);
                          setSelectedSystem(opt);
                          setTempSystemInput(opt.firstName);
                        }}
                        onCreateOption={() => setSystemModalOpen(true)}
                        onInputChange={(text) => {
                          if (
                            (tempSystemInput && tempSystemInput.length < 2) ||
                            text !== ""
                          ) {
                            setTempSystemInput(text);
                          }
                        }}
                        inputValue={tempSystemInput}
                      />
                    </div>

                    <div className="mt-2">
                      <MediumButtonLong
                        buttonText="nytt salgsfirma"
                        onClick={() => setSystemModalOpen(true)}
                        width={200}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Breakpoint>
        </div>
        <div className="new-order-row">
          <div className="new-order-section flex-grow-1">
            <div className="new-order-section-content flex-grow-1">
              <MediumButtonLong
                buttonText="Opprett bruker"
                onClick={() => submitUserData()}
              />
            </div>
          </div>
        </div>
      </div>
      <SystemModal
        open={systemModalOpen}
        onClose={closeSystemModal}
        tempName={tempSystemInput}
        setCreatedSystemName={setTempSystemInput}
        onCreate={() => {
          fetchSystems();
        }}
      />
    </div>
  );
};

export default CreateUserPage;
