import React, { useState, useEffect } from "react";
import LargeButton from "../../components/LargeButton";
import { Route, useHistory } from "react-router-dom";
import DataTable from "../../components/DataTable";
import "./OrdersPage.scss";
import CheckBox from "../../components/CheckBox";
import OrderModal from "./OrderModal";
import { OrderPost } from "../../types/OrderPost";
import { Breakpoint } from "react-socks";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";

import InfoIcon from "@material-ui/icons/Info";
import { Order } from "../../types/Order";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { TimeFilter, DeliveredFilter, PayedFilter } from "./Filters";
import DropDown from "../../components/DropDown";
import axios from "axios";
import { notify } from "common/utils/notify/notifyFunction";
import ReminderModal from "common/components/ReminderModal/ReminderModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import OrdersOverview from "pages/my-page/components/orders/OrdersOverview";
import { useStoreActions } from "store/store";

const OrdersPage: React.FC = () => {
  const [finnishedLoading, setFinnishedLoading] = React.useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [modalOpen, setOpenModal] = React.useState(false);
  const [reminderModalOpen, setReminderModalOpen] = React.useState(false);
  const [openOrders, setOpenOrders] = React.useState<OrderPost[]>([]);
  const [totalOrderPrice, setTotalOrderPrice] = React.useState<number>(0);
  const [selectedOrderId, setSelectedOrderId] = React.useState("");
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [filterTime, setFilterTime] = useState<Number>(0);
  const [filterPayed, setFilterPayed] = useState<Number>(0);
  const [filterDelivered, setFilterDelivered] = useState<Number>(0);
  const [hideDeliveredAndPaid, setHideDeliveredAndPaid] =
    useState<boolean>(true);
  const [selectedTimeFilter, setSelectedTimeFilter] = useState<any>();

  const SetLoading = useStoreActions(
    (actions) => actions.status.setAccountPageLoading
  );

  const history = useHistory();
  useEffect(() => {
    SetLoading(true);
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [filterTime, filterPayed, filterDelivered, hideDeliveredAndPaid]);

  async function fetchOrders() {
    try {
      let filter = createFilterString();
      const request = await axios.get("orders" + filter);

      if (request.status === 200) {
        setOrders(request.data);
        setFinnishedLoading(true);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av ordre", true, "error");
    } finally {
      SetLoading(false);
    }
  }

  function createFilterString(): string {
    let filter = "?";
    filter = filter + "FilterId=" + filterTime;
    filter = filter + "&Payed=" + filterPayed;
    filter = filter + "&Delivered=" + filterDelivered;
    filter = filter + "&HideDeliveredAndPaid=" + hideDeliveredAndPaid;

    return filter;
  }

  const columns = [
    {
      Header: "Dato",
      accessor: "createdDate", // String-based value accessors!
      Cell: ({ row }: any) => {
        let date = new Date(row.original.createdDate);
        return (
          <p onClick={(e: any) => clickedOnOpenOrder(row.original)}>
            {date.getDate()}/{date.getMonth() + 1} {date.getFullYear()}
          </p>
        );
      },
    },
    {
      Header: "Kunde",
      accessor: "customer", // String-based value accessors!
      Cell: ({ row }: any) => {
        return (
          <p>
            {row.original.customer.firstName} {row.original.customer.lastName}
          </p>
        );
      },
    },
    {
      Header: "Bestilt",
      accessor: "orderedFromSupplier", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.orderedFromSupplier}
            onChange={(e: any) => {
              clickedOnOrdered(row.original.id, e.target.checked);
              row.original.orderedFromSupplier = e.target.checked;
            }}
          />
        );
      },
    },
    {
      Header: "Betalt",
      accessor: "paid", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.paid}
            onChange={(e: any) => {
              clickedOnPaid(row.original.id, e.target.checked);
              row.original.paid = e.target.checked;
            }}
          />
        );
      },
    },
    {
      Header: "Levert",
      accessor: "delivered", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.delivered}
            onChange={(e: any) => {
              clickedOnDelivered(row.original.id, e.target.checked);
              row.original.delivered = e.target.checked;
            }}
          />
        );
      },
    },
    {
      Header: "Delvis Levert",
      accessor: "partialyDelivered", // String-based value accessors!
      width: 80,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Trykk på rediger for å velge hva som er levert
              </Tooltip>
            }
          >
            <div>
              <CheckBox
                checked={row.original.partialyDelivered}
                onChange={(e: any) => {
                  clickedOnParitialyDelivered(
                    row.original.id,
                    e.target.checked
                  );
                  row.original.delivered = e.target.checked;
                }}
              />
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "notes",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Bestillingsliste
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              onClick={() => clickedOnOpenOrder(row.original)}
            >
              <InfoIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "reminder",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Påminnelse
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              onClick={() => clickedOnOpenReminderModal(row.original)}
            >
              <NotificationsNoneIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "count",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Rediger
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              onClick={() => clickedOnChange(row.original.id)}
            >
              <EditIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
    {
      accessor: "system",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id="customer-tooltip" style={{ zIndex: 1301 }}>
                Slett
              </Tooltip>
            }
          >
            <IconButton
              style={{
                color: "#906088",
              }}
              onClick={() => clickedOnDelete(row.original)}
            >
              <DeleteIcon />
            </IconButton>
          </OverlayTrigger>
        );
      },
    },
  ];

  const columnsMobile = [
    {
      Header: "Dato",
      accessor: "createdDate", // String-based value accessors!
      width: 70,
      Cell: ({ row }: any) => {
        let date = new Date(row.original.createdDate);
        return (
          <p onClick={(e: any) => clickedOnOpenOrder(row.original.id)}>
            {date.getDate()}/{date.getMonth() + 1} {date.getFullYear()}
          </p>
        );
      },
    },
    {
      Header: "Kunde",
      accessor: "customer", // String-based value accessors!
      width: 90,
      Cell: ({ row }: any) => {
        return (
          <p>
            {row.original.customer.firstName} {row.original.customer.lastName}
          </p>
        );
      },
    },
    {
      Header: "Bestilt",
      accessor: "orderedFromSupplier", // String-based value accessors!
      width: 70,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.paid}
            onChange={(e: any) => {
              clickedOnOrdered(row.original.id, e.target.checked);
              row.original.orderedFromSupplier = e.target.checked;
            }}
          />
        );
      },
    },
    {
      Header: "Betalt",
      accessor: "paid", // String-based value accessors!
      width: 70,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.paid}
            onChange={(e: any) => {
              clickedOnPaid(row.original.id, e.target.checked);
              row.original.paid = e.target.checked;
            }}
          />
        );
      },
    },
    {
      Header: "Levert",
      accessor: "delivered", // String-based value accessors!
      width: 70,
      Cell: ({ row }: any) => {
        return (
          <CheckBox
            checked={row.original.delivered}
            onChange={(e: any) => {
              clickedOnDelivered(row.original.id, e.target.checked);
              row.original.delivered = e.target.checked;
            }}
          />
        );
      },
    },
    {
      accessor: "notes",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => clickedOnOpenOrder(row.original)}
          >
            <InfoIcon />
          </IconButton>
        );
      },
    },
    {
      accessor: "reminder",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => clickedOnOpenReminderModal(row.original)}
          >
            <NotificationsNoneIcon />
          </IconButton>
        );
      },
    },
    {
      accessor: "count",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => clickedOnChange(row.original.id)}
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
    {
      accessor: "system",
      width: 50,
      Cell: ({ row }: any) => {
        return (
          <IconButton
            style={{
              color: "#906088",
            }}
            onClick={() => clickedOnDelete(row.original)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  const timeFilter = TimeFilter;

  const updateOptions = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };

  async function updateOrder(order: any, id: string) {
    try {
      const request = await axios.patch(`orders/${id}`, order, updateOptions);

      if (request.status === 200) {
        if (request.data) {
          const changedIndex = orders.findIndex((order) => order.id == id);
          orders[changedIndex] = request.data;
          if (order.isDeleted === true) {
            orders.splice(changedIndex, 1);
          }
          setOrders([...orders]);
        }
      }
    } catch (error) {
      notify("Det oppsto en feil ved oppdatering av ordre", true, "error");
    } finally {
    }
  }

  function clickedOnPaid(orderId: string, checked: boolean) {
    updateOrder({ paid: checked }, orderId);
  }

  function clickedOnOrdered(orderId: string, checked: boolean) {
    updateOrder({ orderedFromSupplier: checked }, orderId);
  }

  function clickedOnDelete(order: Order) {
    setSelectedOrder(order);
    setOpenAlertDialog(true);
  }

  function deleteOrder() {
    setOpenAlertDialog(false);
    if (selectedOrder !== undefined) {
      updateOrder({ isDeleted: true }, selectedOrder.id);
    }
  }

  function clickedOnChange(orderId: string) {
    history.push("/edit-order/" + orderId);
  }

  function clickedOnDelivered(orderId: any, checked: boolean) {
    updateOrder({ delivered: checked }, orderId);
  }
  function clickedOnParitialyDelivered(orderId: any, checked: boolean) {
    updateOrder({ partialyDelivered: checked }, orderId);
  }
  function clickedOnOpenOrder(order: Order) {
    setSelectedOrderId(order.id);
    fetchOrder(order.id);
    openModal();
  }
  function clickedOnOpenReminderModal(order: Order) {
    setSelectedOrderId(order.id);
    fetchOrder(order.id);
    setReminderModalOpen(true);
  }
  const closeModal = () => {
    setOpenModal(false);
  };
  const closeReminderModal = () => {
    setReminderModalOpen(false);
  };
  const openModal = () => {
    setOpenModal(true);
  };

  async function fetchOrder(id: string) {
    try {
      const request = await axios.get("orders/" + id);

      if (request.status === 200) {
        const order = request.data;
        setSelectedOrder(request.data);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av ordren", true, "error");
    } finally {
    }
  }

  if (!finnishedLoading) {
    return null;
  }

  return (
    <div className="d-flex flex-column">
      <Breakpoint medium up>
        <div className="orders-header">
          <Route
            render={({ history }) => (
              <LargeButton
                buttonText="Nytt Salg"
                onClick={() => history.push("/new-order")}
              />
            )}
          />
        </div>

        <div className="d-flex flex-row justify-content-center">
          <div
            className="d-flex flex-row flex-grow-1 justify-content-end "
            style={{ maxWidth: 930 }}
          >
            <CheckBox
              checked={hideDeliveredAndPaid}
              onChange={(e: any) => setHideDeliveredAndPaid(e.target.checked)}
            />
            <p className="ms-1 my-auto">Skjul betalt og levert</p>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-center">
          <div
            className="d-flex flex-row justify-content-center"
            style={{ maxWidth: 1000 }}
          >
            <h1 className="orders-table-header-text">SALGSOVERSIKT</h1>
            <div className="order-table-filter">
              <DropDown
                items={TimeFilter}
                onChange={(opt) => {
                  setFilterTime(opt.id);
                  setHideDeliveredAndPaid(false);
                }}
                placeholder="Filter Tid"
                value={selectedTimeFilter}
              />
              <DropDown
                items={PayedFilter}
                onChange={(opt) => {
                  setFilterPayed(opt.id);
                  setHideDeliveredAndPaid(false);
                }}
                placeholder="Filter Betalt"
                value={selectedTimeFilter}
              />
              <DropDown
                items={DeliveredFilter}
                onChange={(opt) => {
                  setFilterDelivered(opt.id);
                  setHideDeliveredAndPaid(false);
                }}
                placeholder="Filter Levert"
                value={selectedTimeFilter}
              />
            </div>
          </div>
        </div>
      </Breakpoint>
      <Breakpoint small down>
        {/* <div className="orders-table-header text-center my-4">
          <h1 className="orders-table-header-text">Salgsoversikt</h1>
        </div> */}
        <div className="d-flex flex-row justify-content-center mt-4">
          <CheckBox
            checked={hideDeliveredAndPaid}
            onChange={(e: any) => setHideDeliveredAndPaid(e.target.checked)}
          />
          <p className="ms-1 my-auto">Skjul betalt og levert</p>
        </div>
        <div className="order-table-filter">
          <DropDown
            items={TimeFilter}
            onChange={(opt) => {
              setFilterTime(opt.id);
              setHideDeliveredAndPaid(false);
            }}
            placeholder="Filter Tid"
            value={selectedTimeFilter}
          />
          <DropDown
            items={PayedFilter}
            onChange={(opt) => {
              setFilterPayed(opt.id);
              setHideDeliveredAndPaid(false);
            }}
            placeholder="Filter Betalt"
            value={selectedTimeFilter}
          />
          <DropDown
            items={DeliveredFilter}
            onChange={(opt) => {
              setFilterDelivered(opt.id);
              setHideDeliveredAndPaid(false);
            }}
            placeholder="Filter Levert"
            value={selectedTimeFilter}
          />
        </div>
      </Breakpoint>
      <Breakpoint medium up>
        <div
          className="d-flex grow-1 flex-wrap flex-column m-auto "
          style={{ width: 800 }}
        >
          <DataTable columns={columns} data={orders} />
        </div>
      </Breakpoint>
      <Breakpoint small down>
        <OrdersOverview
          orders={orders}
          clickedOnPaid={clickedOnPaid}
          clickedOnOrdered={clickedOnOrdered}
          clickedOnDelivered={clickedOnDelivered}
          onOpenOrder={clickedOnOpenOrder}
          onOpenReminder={clickedOnOpenReminderModal}
          onEdit={clickedOnChange}
          onDelete={clickedOnDelete}
        />
      </Breakpoint>

      <OrderModal
        id={selectedOrderId}
        open={modalOpen}
        onClose={closeModal}
        order={selectedOrder}
        onEdit={clickedOnChange}
      />
      <ReminderModal
        id={selectedOrderId}
        open={reminderModalOpen}
        onClose={closeReminderModal}
        preSelectedOrder={selectedOrder}
      />
      <Dialog
        open={openAlertDialog}
        onClose={() => setOpenAlertDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Advarsel sletter salg"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Er du sikker på at du vil slette dette salget fra{" "}
            {selectedOrder
              ? selectedOrder.customer.firstName +
                " " +
                selectedOrder.customer.lastName
              : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAlertDialog(false)} color="primary">
            Nei
          </Button>
          <Button onClick={() => deleteOrder()} color="primary" autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrdersPage;
