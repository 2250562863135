import React from "react";
import { Button as BootstrapButton } from "react-bootstrap";
import "./Button.scss";

type ButtonProps = {
  text: string;
  isLoading?: boolean;
  width?: number;
  height?: number;
  onClick: (event: any) => void;
  variant?: "filled" | "outlined" | "link";
  color?: "primary" | "secondary" | "tertiary";
};

const Button: React.FC<ButtonProps> = ({
  text,
  isLoading,
  width = 150,
  height = 40,
  variant = "filled",
  color = "primary",
  onClick,
}) => {
  return (
    <div className="medium-button-cointainer">
      <BootstrapButton variant={variant} onClick={onClick}>
        {text}
      </BootstrapButton>
    </div>
  );
};

export default Button;
