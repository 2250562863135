import { PublicClientApplication } from "@azure/msal-browser";
import React, { useEffect } from "react";

import { loginRequest, msalConfig } from "common/authentication/authConfig";
import { useStoreState } from "store/store";

const SignInPage: React.FC = () => {
  const instanceFinnishedLoading = useStoreState(
    (state) => state.authentication.localCacheChecked
  );
  const autenticated = useStoreState(
    (state) => state.authentication.isAuthenticated
  );

  useEffect(() => {
    if (!autenticated && instanceFinnishedLoading) {
      const authProvider = new PublicClientApplication(msalConfig);
      authProvider.loginRedirect(loginRequest);
    }
  }, [autenticated, instanceFinnishedLoading]);

  return <div></div>;
};

export default SignInPage;
