import React from "react";
import Button from "@material-ui/core/Button";
import "./style.scss";

type ButtonProps = {
  buttonText: string;
  onClick: (event: any) => void;
  width?: number;
};

const MediumButtonLong: React.FC<ButtonProps> = ({
  buttonText,
  onClick,
  width = 250,
}) => {
  return (
    <Button
      variant="outlined"
      style={{ width }}
      classes={{
        label: "medium-button-long-label",
        root: "medium-botton-long-root",
      }}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};

export default MediumButtonLong;
