import React, { useRef, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useStoreState } from "store/store";
import Header from "common/layout/Header";
import MyPage from "pages/my-page/MyPage";
import CustomersPage from "pages/customers/CustomersPage";
import NewCustomerPage from "pages/newCustomer/NewCustomerPage";
import EditCustomerPage from "pages/editCustomer/EditCustomerPage";
import ProductsPage from "pages/products/ProductsPage";
import NewProductPage from "pages/newProduct/NewProductPage";
import NewProductCategoryPage from "pages/newProductCategory/NewProductCategoryPage";
import EditProductPage from "pages/editProduct/EditProductPage";
import OrdersPage from "pages/orders/OrdersPage";
import NewOrderPage from "pages/newOrder/NewOrderPage";
import EditOrderPage from "pages/editOrder/EditOrderPage";
import DeliveryPage from "pages/delivery/DeliveryPage";
import CreateUserPage from "pages/create-user/create-user";
import AutoSignInPage from "pages/auto-sign-in/auto-sign-in";
import SignInPage from "pages/sign-in/SignInPage";
import LoadingPage from "pages/loading-page/LoadingPage";
import Menu from "common/layout/Menu";
import Burger from "common/layout/Burger";
import NotificationsPage from "pages/notifications/NotificationsPage";
import LandingPage from "pages/landing/LandingPage";
import UserFeedbackPage from "pages/user-feedback/user-feedback";
import { Breakpoint } from "react-socks";
import MobileMenu from "common/layout/Header/MobileMenu";
import MobileAppMenu from "common/layout/MobileAppMenu/MobileMenu";
import CustomerDetailsPage from "pages/customer-details/CustomerDetailsPage";

const Routes: React.FC = () => {
  const isAuthenticated = useStoreState(
    (state) => state.authentication.isAuthenticated
  );
  const userAccount = useStoreState((state) => state.account.user);
  const accountRequestReceived = useStoreState(
    (state) => state.status.accountRequestReceived
  );

  const [open, setOpen] = useState(false);
  const refBurger = useRef(null);

  if (!isAuthenticated) {
    return (
      <Switch>
        {/* <Route path="/accept-company-team-membership">
      <AcceptCompanyTeamMembership />
    </Route> */}
        <Route path="/">
          <SignInPage />
        </Route>
      </Switch>
    );
  }

  if (!userAccount && !accountRequestReceived) {
    return <LoadingPage />;
  }

  if (!userAccount?.isOnboarded) {
    return (
      <>
        {/* <div ref={refBurger}>
          <Burger open={open} setOpen={setOpen} />
        </div>
        <Menu open={open} setOpen={setOpen} refBurger={refBurger} /> */}
        <div className="header">
          <Header />
        </div>
        <Switch>
          <Route path="/create-user">
            <CreateUserPage />
          </Route>
          <Route path="/">
            <Redirect to="/create-user" />
          </Route>
        </Switch>
      </>
    );
  }
  return (
    <>
      <Breakpoint medium up>
        <div ref={refBurger}>
          <Burger open={open} setOpen={setOpen} />
        </div>
        <Menu open={open} setOpen={setOpen} refBurger={refBurger} />
        <div className="header">
          <Header />
        </div>
      </Breakpoint>

      <div className="centered">
        <Switch>
          <Route path="/customers">
            <CustomersPage />
          </Route>
          <Route path="/new-customer">
            <NewCustomerPage />
          </Route>
          <Route path="/new-customer">
            <NewCustomerPage />
          </Route>
          <Route path="/edit-customer/:id">
            <EditCustomerPage />
          </Route>
          <Route path="/details-customer/:id">
            <CustomerDetailsPage />
          </Route>
          <Route path="/products">
            <ProductsPage />
          </Route>
          <Route path="/new-product">
            <NewProductPage />
          </Route>
          <Route path="/new-product-category">
            <NewProductCategoryPage />
          </Route>
          <Route path="/edit-product/:id">
            <EditProductPage />
          </Route>
          <Route path="/orders">
            <OrdersPage />
          </Route>
          <Route path="/new-order/:id">
            <NewOrderPage />
          </Route>
          <Route path="/new-order/">
            <NewOrderPage />
          </Route>
          <Route path="/edit-order/:id">
            <EditOrderPage />
          </Route>
          <Route path="/notifications">
            <NotificationsPage />
          </Route>
          <Route path="/delivery">
            <DeliveryPage />
          </Route>
          <Route path="/my-page">
            <MyPage />
          </Route>
          <Route path="/feedback">
            <UserFeedbackPage />
          </Route>
          <Route path="/sign-in">
            <SignInPage />
          </Route>
          <Route path="/">
            <Redirect to="/my-page" />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default Routes;
