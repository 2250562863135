import { account, AccountModel } from "store/AccountModel";
import { authentication, AuthModel } from "store/AuthenticationModel";
import { StatusModel, status } from "store/StatusModel";

export interface StoreModel {
  authentication: AuthModel;
  account: AccountModel;
  status: StatusModel;
}

const model: StoreModel = {
  authentication,
  account,
  status,
};

export default model;
