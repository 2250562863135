import React, { useState, useEffect } from "react";
import "./EditProductPage.scss";
import { Route, useParams, useHistory } from "react-router-dom";
import LargeButton from "../../components/LargeButton";
import InputText from "../../components/InputText";
import MediumButton from "../../components/MediumButton";
import MediumButtonLong from "../../components/MediumButtonLong";
import { Breakpoint } from "react-socks";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { ProductCategory } from "../../types/ProductCategory";
import { DropDownItem } from "../../types/DropDownItem";
import { NewProductCategory } from "../../types/NewProductCategory";
import SearchableDropdown from "../../components/SearchableDropdown";
import { SubProduct } from "../../types/SubProduct";
import DataTable from "../../components/DataTable";
import { NewSubProduct } from "../../types/NewSubProduct";
import { Row, Col } from "reactstrap";
import { notify } from "common/utils/notify/notifyFunction";
import axios from "axios";
import { NewProduct } from "types/NewProduct";
import { useStoreActions } from "store/store";

const EditProductPage: React.FC = () => {
  const [category, setCategory] = useState<ProductCategory>();
  const [subCategories, setSubCategories] = useState<SubProduct[]>();
  const [productName, setProductName] = useState("");
  const [articleNumber, setArticleNumber] = useState("");
  const [productId, setProductId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [tempCategoryInput, setTempCategoryInput] = useState<string>("");
  const [categories, setCategories] = useState<any>(null);
  const [showSubCategories, setShowSubCategories] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subArticleNumber, setSubArticleNumber] = useState("");
  const history = useHistory();

  const { id } = useParams<ParamTypes>();
  let historyRedirect = useHistory();

  interface ParamTypes {
    id: string;
  }

  const SetLoading = useStoreActions(
    (actions) => actions.status.setAccountPageLoading
  );

  useEffect(() => {
    SetLoading(true);
    fetchProduct();
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    checkForCreatedCategory();
  }, [categories]);

  async function fetchProduct() {
    try {
      const request = await axios.get("/products/" + id);

      if (request.status === 200) {
        let data = request.data;
        setProductName(data.productName);
        setArticleNumber(data.articleNumber);
        setProductId(data.id);
        if (data.productCategory) {
          setSelectedCategory({
            label: data.productCategory.categoryName,
            value: data.productCategory.id,
            id: data.productCategory.id,
          });
        }
        if (data.subProducts.length > 0) {
          setSubCategories(data.subProducts);
          setShowSubCategories(true);
        }
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av produkt", true, "error");
    } finally {
      SetLoading(false);
    }
  }

  async function fetchCategories() {
    try {
      const request = await axios.get("productCategories");

      if (request.status === 200) {
        let data = request.data;
        let allCategories = data.map((category: ProductCategory) => {
          return {
            label: category.categoryName,
            value: category.id,
            id: category.id,
          };
        });

        setCategories(allCategories);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opplasting av Kategorier", true, "error");
    } finally {
    }
  }

  async function createCategory(category: any) {
    var newCategory: NewProductCategory = {
      categoryName: category,
    };

    try {
      const request = await axios.post("productCategories", newCategory);

      if (request.status === 201) {
        notify("En ny product kategori ble opprettet", true, "success");
        fetchCategories();
      }
    } catch (error) {
      notify("Det oppsto en feil ved opprettelse av kategori", true, "error");
    }
  }

  function checkForCreatedCategory() {
    if (tempCategoryInput !== "") {
      let createdCategory = categories.find(
        (p: any) => p.label === tempCategoryInput
      );
      if (createdCategory) {
        setSelectedCategory(createdCategory);
        setCategory(createdCategory);
        setTempCategoryInput("");
      }
    }
  }

  const updateOptions = {
    headers: {
      "Content-Type": "application/merge-patch+json",
    },
  };

  async function updateProduct(product: any, id: string) {
    try {
      const request = await axios.patch(
        `products/${id}`,
        product,
        updateOptions
      );

      if (request.status === 200) {
        notify("Produktet ble oppdatert", true, "success");
      }
    } catch (error) {
      notify("Det oppsto en feil ved oppdatering av produkt", true, "error");
    } finally {
    }
  }

  function update() {
    let updatedProduct = {
      productName: productName,
      articleNumber: articleNumber,
      productCategory: "",
    };
    if (selectedCategory) {
      updatedProduct = {
        productName: productName,
        articleNumber: articleNumber,
        productCategory: selectedCategory.id,
      };
    }
    updateProduct(updatedProduct, productId).then(() => {
      historyRedirect.push("/products");
    });
  }

  async function createProduct() {
    const product: NewProduct = {
      productName: productName,
      articleNumber: articleNumber,
      subProducts: subCategories,
    };
    if (selectedCategory !== null) {
      product.productCategory = selectedCategory.id;
    }

    try {
      const request = await axios.post("products", product);

      if (request.status === 201) {
        notify("Et nytt product ble opprettet", true, "success");
        setProductName("");
        setArticleNumber("");
        setSelectedCategory(null);
        setSubCategories([]);
      }
    } catch (error) {
      notify("Det oppsto en feil ved opprettelse av produkt", true, "error");
    }
  }

  async function createSubProduct() {
    if (subCategoryName !== "" && subArticleNumber !== "") {
      const subProduct: NewSubProduct = {
        subProductName: subCategoryName,
        articleNumber: subArticleNumber,
        productId: productId,
      };

      try {
        const request = await axios.post("subproducts", subProduct);

        if (request.status === 201) {
          notify("En ny product sub kategori ble opprettet", true, "success");
          setSubCategoryName("");
          setSubArticleNumber("");
          addSubProductToList(request.data);
        }
      } catch (error) {
        notify(
          "Det oppsto en feil ved opprettelse av sub produkt",
          true,
          "error"
        );
      }
    }
  }

  function addSubProductToList(subProduct: SubProduct) {
    let newSubProductList = [];
    if (subCategories !== undefined) {
      newSubProductList = subCategories.concat(subProduct);
    } else {
      newSubProductList = [subProduct];
    }
    setSubCategories(newSubProductList);
  }

  const columns = [
    {
      Header: "Type",
      accessor: "subProductName",
      width: 200,
    },
    {
      Header: "Art. nr.",
      accessor: "articleNumber",
      width: 200,
    },
  ];

  function tryingToGoBack() {
    history.goBack();
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row justify-content-center pt-4">
        <div className="d-flex flex-row" style={{ width: 800 }}>
          <h1 style={{ flexGrow: 1, fontWeight: 100, fontSize: 24 }}>
            Rediger produkt
          </h1>
          <Breakpoint medium up>
            <Route
              render={() => (
                <LargeButton
                  buttonText="Tilbake"
                  onClick={() => tryingToGoBack()}
                />
              )}
            />
          </Breakpoint>
          <Breakpoint small down>
            <Route
              render={() => (
                <IconButton
                  style={{
                    color: "#906088",
                  }}
                  onClick={() => tryingToGoBack()}
                >
                  <ArrowBackIcon />
                </IconButton>
              )}
            />
          </Breakpoint>
        </div>
      </div>
      <div
        className="d-flex grow-1 flex-wrap flex-column m-auto "
        style={{ width: 800 }}
      >
        <div className="d-flex flex-row flex-wrap justify-content-center">
          <Row>
            <Col
              lg={3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SearchableDropdown
                value={selectedCategory}
                items={categories}
                typeText="kategori"
                onChange={(opt) => {
                  setCategory(opt);
                  setSelectedCategory(opt);
                  setTempCategoryInput(opt.categoryName);
                }}
                onCreateOption={(opt2) => createCategory(opt2)}
                onInputChange={(text) => {
                  if (
                    (tempCategoryInput && tempCategoryInput.length < 2) ||
                    text !== ""
                  ) {
                    setTempCategoryInput(text);
                  }
                }}
                inputValue={tempCategoryInput}
              />
            </Col>
            <Col lg={4}>
              <InputText
                inputTitle="Produktnavn"
                value={productName}
                onChange={(e: any) => setProductName(e.target.value)}
              />
            </Col>
            <Col lg={3}>
              <InputText
                inputTitle="Artikkelnummer"
                value={articleNumber}
                onChange={(e: any) => setArticleNumber(e.target.value)}
              />
            </Col>
            <Col lg={2}>
              <div className="create-product-button-container">
                <MediumButton buttonText="Lagre" onClick={() => update()} />
              </div>
            </Col>
          </Row>
          <div className="show-sub-prorducts-button-container">
            <MediumButtonLong
              buttonText={
                showSubCategories ? "Skjul undergrupper" : "Vis undergrupper"
              }
              onClick={() => setShowSubCategories(!showSubCategories)}
            />
          </div>
          <div>
            {showSubCategories && (
              <div className="sub-category-container">
                {subCategories && (
                  <div>
                    <h3
                      style={{
                        flexGrow: 1,
                        fontWeight: 100,
                        fontSize: 20,
                        padding: 0,
                        marginBottom: 30,
                      }}
                    >
                      UNDERGRUPPER:
                    </h3>

                    <DataTable columns={columns} data={subCategories} />
                  </div>
                )}
                <h3
                  style={{
                    flexGrow: 1,
                    fontWeight: 100,
                    fontSize: 20,
                    padding: 0,
                    marginBottom: 0,
                    marginTop: 30,
                  }}
                >
                  LEGG TIL NY GRUPPE:
                </h3>
                <Row>
                  <Col lg={4}>
                    <InputText
                      inputTitle="Navn"
                      value={subCategoryName}
                      onChange={(e: any) => setSubCategoryName(e.target.value)}
                    />
                  </Col>
                  <Col lg={4}>
                    <InputText
                      inputTitle="Artikkelnummer"
                      value={subArticleNumber}
                      onChange={(e: any) => setSubArticleNumber(e.target.value)}
                    />
                  </Col>
                  <Col lg={3}>
                    <div className="create-product-button-container">
                      <MediumButton
                        buttonText="legg til"
                        onClick={() => createSubProduct()}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProductPage;
